import PropTypes from "prop-types"

/**
 * Use this PropType when you need to assign a ref and it can be a function ref or a ref created by useRef
 */
export const functionOrRefObject = PropTypes.oneOfType([
  // Either a function
  PropTypes.func,
  // Or the instance of a DOM native element (see the note about SSR)
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
])

/**
 * Use this PropTypes when you need to access a ref that has been assigned to state with a ref function or a ref created by useRef
 */
export const elementOrRefObject = PropTypes.oneOfType([
  // Either a DOM native element
  PropTypes.instanceOf(Element),
  // Or the instance of a DOM native element (see the note about SSR)
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
])

export const mergeRefs =
  (...refs) =>
  (node) => {
    refs.forEach((ref) => {
      if (ref) {
        ref.current = node
      }
    })
  }
