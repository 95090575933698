import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfTypo } from "../../fundamentals/Typo"
import useDefaultStyle from "../../helpers/useDefaultStyle"

import AccountCell from "./AccountCell"

import InteractiveCell from "./InteractiveCell"
export const MfInteractiveCell = InteractiveCell

export const MfAccountCell = AccountCell

import EmptyCell from "./EmptyCell"
export const MfEmptyCell = EmptyCell

import { CellWrapper as Wrapper } from "./CellWrapper"
export const CellWrapper = Wrapper

const CellContainerStyles = styled.div`
  display: flex;
  flex-direction: ${({ $iconPosition }) => ($iconPosition === "left" ? "row" : "row-reverse")};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        .label {
          text-decoration: underline;
        }
      }
    `}
`

const IconContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    padding-right: 8px;
    padding-left: 8px;
  }

  ${({ theme }) => theme.media.tablet`
    &:first-child {
      padding-right: 16px;
      padding-left: 16px;
    }
  `}
`

const LabelContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  word-break: break-word;
`

const ValueStyles = (props) => (
  <MfTypo variant="bodytext1" className="label" weight="semibold" {...props} />
)

const TypoLabel = styled(MfTypo)`
  ${({ theme }) => css`
    color: ${theme.color.text.lighter};

    ${theme.media.tablet`
      color: ${theme.color.text.default};
    `}
  `}
`
const LabelStyles = (props) => <TypoLabel variant="bodytext2" className="label" {...props} />

const TypoSubValue = styled(MfTypo)`
  color: ${({ theme }) => theme.color.text.lighter};
`
const SubValueStyles = (props) => <TypoSubValue variant="bodytext2" {...props} />

const CellContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const CellCta = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
`

export function MfCell({
  label,
  value,
  cta,
  subValue,
  icon,
  iconPosition,
  onClick,
  children,
  ...props
}) {
  // The label, value and subValue styles have a default, but can be overriden. To make the interface for this
  // easy on the project developers we allow string (that get the default styles) and JSX content with custom styles
  // We handle this here in a custom hook that will wrap strings in the Default styles
  const labelContent = useDefaultStyle(label, LabelStyles)
  const valueContent = useDefaultStyle(value, ValueStyles)
  const subValueContent = useDefaultStyle(subValue, SubValueStyles, { wrapArray: true })

  const handleKeyPress = (e) => {
    if ((e.key === " " || e.key === "Enter") && onClick) {
      e.preventDefault()
      onClick()
    }
  }

  return (
    <CellContainerStyles
      tabIndex={onClick ? 0 : -1}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      $iconPosition={iconPosition}
      {...props}
    >
      {icon && <IconContainerStyles>{icon}</IconContainerStyles>}
      <CellContent>
        <LabelContainerStyles>
          {labelContent}
          {valueContent}
          {subValueContent}
        </LabelContainerStyles>
        <CellCta>
          {children}
          {cta && <div>{cta}</div>}
        </CellCta>
      </CellContent>
    </CellContainerStyles>
  )
}

MfCell.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.any,
  subValue: PropTypes.any,
  icon: PropTypes.any,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  cta: PropTypes.node,
  children: PropTypes.node,
}

MfCell.defaultProps = {
  label: undefined,
  subValue: undefined,
  icon: undefined,
  iconPosition: "left",
}
