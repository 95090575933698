import { useEffect, useState } from "react"

// source: https://usehooks.com/useDebounce/

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      let handler
      if (delay) {
        // Update debounced value after delay
        handler = setTimeout(() => {
          setDebouncedValue(value)
        }, delay)
      } else {
        // If delay doesn't exist or is 0 we just change the value immediatly
        setDebouncedValue(value)
      }
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        if (handler) clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
