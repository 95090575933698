import React from "react"
import PropTypes from "prop-types"

import { useWidget } from "../../../fundamentals/Widget"
import { StyledButton } from "./SelectableItem"
import { MenuItem } from "../MenuItem"

export const LinkItem = (props) => {
  const { setIsOpen } = useWidget()

  function handleClick(event) {
    props.onClick?.(event, setIsOpen)
  }

  return (
    <MenuItem>
      <StyledButton {...props} onClick={handleClick} linkButton />
    </MenuItem>
  )
}

LinkItem.propTypes = {
  onClick: PropTypes.func,
}

LinkItem.defaultProps = {
  onClick: undefined,
}
