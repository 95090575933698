import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

const ButtonZone = styled.div`
  border-top: ${({ theme }) => theme.color.primary.lighter};

  > button:not(:first-child) {
    margin-top: 8px;
  }

  ${({ theme }) => theme.media.tablet`
    border-top: 0;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: ${({ $align }) =>
      $align === "left" ? "flex-start" : $align === "right" ? "flex-end" : "center"};
    > button {
      margin-right: 8px;
    }

    > button:not(:first-child) {
      margin-top: 0;
    }
  `}
`

export function MfButtonZone({ children, align = "left", innerRef, ...props }) {
  return (
    <ButtonZone $align={align} {...props} ref={innerRef}>
      {children}
    </ButtonZone>
  )
}

MfButtonZone.propTypes = {
  children: PropTypes.any.isRequired,
  align: PropTypes.any,
  innerRef: PropTypes.any,
}
