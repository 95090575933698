import React, { useCallback, useState, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import moment from "moment"

import { MfFontIcon } from "../../../atoms/FontIcon"

import { ViewRouterContent, ViewRouterSelector } from "./views/ViewRouter"

const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -8px;
  margin-bottom: 8px;

  ${({ theme }) => theme.media.phablet`
    margin-bottom: 1.5rem;
  `}
`

const CalendarHeaderArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.hover.light};
  }

  ${({ $hide }) =>
    $hide &&
    `
    visibility: hidden;
  `}

  ${({ theme }) => theme.media.phablet`
      width: 35px;
      height: 35px;
  `}
`

const CalendarHeaderArrow = ({ icon, ...props }) => (
  <MfFontIcon size="16px" color={"primary:default"} icon={icon} {...props} />
)

CalendarHeaderArrow.propTypes = {
  icon: PropTypes.string,
}

const CalendarContentWrapper = styled.div`
  display: block;
`

const ViewSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  border-radius: 3px;
  transition: 0.2s ease-in;

  ${({ theme, $noHover }) =>
    !$noHover &&
    `
    cursor: pointer;
    
    &:hover {
      background: ${theme.color.hover.dark};
      transition: .1s ease-out;

      & > i {
        visibility: visible;
        width: 16px;
        margin-left: .5rem;   
        opacity: 1;
        transform: translateX(0) rotate(0);
        transition: .1s ease-out; 
      }
    }
  `}
`

const ViewSelectorIcon = styled(MfFontIcon)`
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  width: 0;
  margin-left: 0;
  opacity: 0;
  transform: translateX(-100%) rotate(-90deg);
  transition: 0.2s ease-in;
`
const viewMap = {
  day: 0,
  month: 1,
  year: 2,
}

export function Calendar({
  selected,
  start,
  offsetStart,
  weekStart,
  limits,
  limitsSelected,
  locale,
  onSelect,
  view,
  onNavigate,
  hideLeftArrow,
  hideRightArrow,
  disabledWeekDays,
  disableDates,
  monthPicker,
  monthLabel,
  togglePicker,
}) {
  const currentYearMonth = calculateCurrentMonthYear(start, offsetStart)
  const getView = () => {
    if (monthPicker) {
      return 1
    }
    return (view && viewMap[view]) || 0
  }
  const [viewState, _setViewState] = useState(getView())

  const limitDates = {
    min: limits.min && moment(limits.min),
    max: limits.max && moment(limits.max),
  }

  const disableLeftNavigation = useMemo(() => {
    if (monthPicker && viewState === 1) return true
    if (hideLeftArrow) return hideLeftArrow
    const { min } = limitDates
    if (!min) return false
    switch (viewState) {
      case 0:
        return moment(currentYearMonth).date(1).isBefore(min, "day")
      case 1:
        return moment(currentYearMonth).month(0).date(1).isBefore(min, "day")
      case 2: {
        const startYear = Math.floor(currentYearMonth.year() / 12) * 12
        return moment({ year: startYear, month: 0 }).isBefore(min, "day")
      }
    }
  }, [hideLeftArrow, currentYearMonth, viewState, limitDates])

  const disableRightNavigation = useMemo(() => {
    if (monthPicker && viewState === 1) return true
    if (hideRightArrow) return hideRightArrow
    const { max } = limitDates
    if (!max) return false
    switch (viewState) {
      case 0:
        return moment(currentYearMonth).add(1, "month").date(1).isAfter(max, "day")
      case 1:
        return moment(currentYearMonth).add(1, "year").month(0).date(1).isAfter(max, "day")
      case 2: {
        const endYear = Math.floor(currentYearMonth.year() / 12) * 12 + 12
        return moment({ year: endYear, month: 0 }).isAfter(max, "day")
      }
    }
  }, [hideRightArrow, currentYearMonth, viewState, limitDates])

  const setViewState = useCallback(
    (v) => {
      if (view) return
      _setViewState(v)
    },
    [view]
  )

  const handleViewChange = useCallback((e) => {
    if (monthPicker) return
    e.preventDefault()
    setViewState((prev) => {
      if (prev === 2 && monthPicker) return prev
      return (prev + 1) % 3
    })
  }, [])

  const handleNavigate = useCallback(
    (navigationDate) => {
      onNavigate(moment(navigationDate).format("YYYY-MM-DD"))
      if (monthPicker) {
        setViewState((prev) => {
          return Math.max(prev + 2, 0)
        })
      }
      setViewState((prev) => {
        return Math.max(prev - 1, 0)
      })
    },
    [onNavigate]
  )

  const handleHeaderArrowClick = useCallback(
    (direction) => {
      const offsetStartMonth = (offsetStart && offsetStart.month) || 0
      const offsetStartYear = (offsetStart && offsetStart.year) || 0

      switch (viewState) {
        case 0: {
          return onNavigate(
            moment(currentYearMonth)
              .add(direction - offsetStartMonth, "months")
              .format("YYYY-MM-DD")
          )
        }
        case 1: {
          return onNavigate(
            moment(currentYearMonth)
              .add(direction - offsetStartYear, "years")
              .format("YYYY-MM-DD")
          )
        }
        case 2: {
          return onNavigate(
            moment(currentYearMonth)
              .add(direction * 12 - offsetStartYear, "years")
              .format("YYYY-MM-DD")
          )
        }
      }
    },
    [viewState, currentYearMonth, onNavigate]
  )

  const selectedFiltered = useMemo(() => {
    if (monthPicker && selected.length > 0) {
      let selectedSplit = []
      if (viewState === 2) {
        selectedSplit = [selected[0].split("/")[1]]
      } else if (viewState === 1) {
        selectedSplit = [selected[0].split("/")[0]]
      }
      return selectedSplit.filter((s) => moment(s).isValid())
    }

    return selected.filter((s) => moment(s).isValid())
  }, [selected])

  return (
    <div>
      <CalendarHeader>
        <CalendarHeaderArrowWrapper
          onClick={() => !disableLeftNavigation && handleHeaderArrowClick(-1)}
          hide={disableLeftNavigation}
        >
          <CalendarHeaderArrow icon="fonticons-arrow-back-ios" />
        </CalendarHeaderArrowWrapper>
        <ViewSelector onClick={handleViewChange} $noHover={!!view}>
          <ViewRouterSelector
            view={viewState}
            displayDate={currentYearMonth}
            locale={locale}
            monthPicker={monthPicker}
            monthLabel={monthLabel}
          />
          {!monthPicker && <ViewSelectorIcon icon="fonticons-arrow-down-ios" size="10px" />}
        </ViewSelector>
        <CalendarHeaderArrowWrapper
          onClick={() => !disableRightNavigation && handleHeaderArrowClick(1)}
          $hide={disableRightNavigation}
        >
          <CalendarHeaderArrow icon="fonticons-arrow-forward-ios" />
        </CalendarHeaderArrowWrapper>
      </CalendarHeader>
      <CalendarContentWrapper>
        <ViewRouterContent
          view={viewState}
          fixedView={!!view}
          selected={selectedFiltered}
          displayDate={currentYearMonth}
          weekStart={weekStart}
          limits={limitDates}
          limitsSelected={limitsSelected}
          locale={locale}
          onSelect={onSelect}
          onNavigate={handleNavigate}
          disabledWeekDays={disabledWeekDays}
          disableDates={disableDates}
          monthPicker={monthPicker}
          togglePicker={togglePicker}
        />
      </CalendarContentWrapper>
    </div>
  )
}

Calendar.propTypes = {
  locale: PropTypes.string.isRequired,
  weekStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  start: PropTypes.string,
  offsetStart: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }),
  limits: PropTypes.objectOf(PropTypes.string),
  limitsSelected: PropTypes.objectOf(PropTypes.bool),
  disabledWeekDays: PropTypes.arrayOf(PropTypes.number),
  disableDates: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  onNavigate: PropTypes.func,
  selected: PropTypes.array,
  view: PropTypes.string,
  hideLeftArrow: PropTypes.bool,
  hideRightArrow: PropTypes.bool,
  monthPicker: PropTypes.bool,
  monthLabel: PropTypes.string,
  togglePicker: PropTypes.func,
}

Calendar.defaultProps = {
  weekStart: 1,
  start: "",
  offsetStart: undefined,
  disabledWeekDays: undefined,
  disableDates: undefined,
  limits: { min: undefined, max: undefined },
  limitsSelected: { min: undefined, max: undefined },
  selected: [],
  view: undefined,
  hideLeftArrow: false,
  hideRightArrow: false,
  onNavigate: () => {},
  onSelect: () => {},
}

function calculateCurrentMonthYear(start, offsetStart) {
  const newStart = start ? moment(start).date(1) : moment().date(1)

  if (!newStart.isValid()) return moment()
  if (!offsetStart) return newStart
  if (typeof offsetStart.year === "number") newStart.add(offsetStart.year, "years")
  if (typeof offsetStart.month === "number") newStart.add(offsetStart.month, "month")

  return newStart
}
