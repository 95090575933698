import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Page = styled.div`
  background: white;
  box-shadow: 0 0 ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(1)}`} rgb(0 0 0 / 8%);
  border-radius: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(3)} 0 0`};
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-self: flex-end;
  flex-direction: column;
  position: absolute;
  margin: 0;
  bottom: 0;
  left: 0;
  max-height: 95vh;
  overflow-x: hidden;

  ${({ theme }) => theme.media.tablet`
    position: static;
    border-radius: ${theme.spacing(3)};
    width: 500px;
    margin: 10vh ${theme.spacing(3)} 0 ${theme.spacing(3)};
    max-height: 80vh;
`}
`

export const MultistepPage = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Page {...props} ref={ref}>
      {children}
    </Page>
  )
})
MultistepPage.displayName = "MfMultistepPage"

MultistepPage.propTypes = {
  children: PropTypes.node,
}
