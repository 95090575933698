import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import moment from "moment"

import { MfTypo } from "../../../../fundamentals/Typo"

import { createLocaleMonthsArray } from "../../../../helpers/dateHelper"

const MonthsWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
`

const MonthRowWrapper = styled.div`
  display: flex;
  padding: 0 1rem;
`

const MonthWrapper = styled.div`
  margin: 0 2rem 1rem 0;
  flex: 1;
  border-radius: 3px;
  text-align: center;
  border: 2px solid transparent;
  width: 48px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $selected, theme }) =>
    $selected &&
    `
    background: ${theme.color.highlight.default};
    color: white;
  `}

  &:hover {
    ${({ theme, $disabled }) =>
      !$disabled &&
      `
      cursor: pointer;
      border: 2px solid ${theme.color.primary.lighter};
    `}
  }

  &:last-child {
    margin-right: 0;
  }

  & > p {
    ${({ theme, $disabled }) => $disabled && `color: ${theme.color.text.lighter};`}
  }
`

const MonthRow = ({
  names,
  onMonthChange,
  selected,
  rowIndex,
  displayYear,
  limits,
  monthPicker,
}) => (
  <MonthRowWrapper>
    {names.map((name, columnIndex) => {
      const selectedDate = selected[0] && moment(selected[0])
      const monthIndex = columnIndex + rowIndex * 3

      const date = moment({ year: displayYear, month: monthIndex })
      const { min, max } = limits
      const outsideOfMinMax =
        (min && date.isBefore(min, "month")) || (max && date.isAfter(max, "month"))
      const disabled = !monthPicker && outsideOfMinMax

      const isSelected = () => {
        if (monthPicker) {
          return selectedDate && selectedDate.isValid() && selectedDate.month() === monthIndex
        }

        return (
          selectedDate &&
          selectedDate.isValid() &&
          selectedDate.year() === displayYear &&
          selectedDate.month() === monthIndex
        )
      }

      return (
        <MonthWrapper
          key={name}
          onClick={() => !disabled && onMonthChange(monthIndex)}
          $selected={isSelected(selectedDate)}
          $disabled={disabled}
          data-testid="month_item"
        >
          <MfTypo variant="bodytext1" weight="semibold">
            {name.slice(0, 3)}
          </MfTypo>
        </MonthWrapper>
      )
    })}
  </MonthRowWrapper>
)

MonthRow.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.array,
  onMonthChange: PropTypes.func,
  rowIndex: PropTypes.number,
  displayYear: PropTypes.number,
  limits: PropTypes.objectOf(PropTypes.any),
  monthPicker: PropTypes.bool,
}

MonthRow.defaultProps = {
  names: [],
  selected: [],
  rowIndex: 0,
  onMonthChange: () => {},
  displayYear: undefined,
  limits: { min: undefined, max: undefined },
}

export function Months({ selected, displayDate, locale, onNavigate, limits, monthPicker }) {
  const monthNames = useMemo(() => createLocaleMonthsArray(), [locale])

  const handleNavigate = useCallback(
    (monthIndex) => {
      onNavigate(moment(displayDate).month(monthIndex).date(1))
    },
    [onNavigate, displayDate]
  )

  const limitsReset = useMemo(() => {
    const minMax = {}
    const { min, max } = limits
    if (min) {
      minMax.min = min.clone()
      minMax.min.day = 1
    }
    if (max) {
      minMax.max = max.clone()
      minMax.max.day = 1
    }
    return minMax
  }, [limits])

  const months = useMemo(
    () =>
      monthNames.map((row, index) => (
        <MonthRow
          key={row[0] + "-" + row[2]}
          rowIndex={index}
          names={row}
          onMonthChange={handleNavigate}
          selected={selected}
          displayYear={displayDate.year()}
          limits={limitsReset}
          monthPicker={monthPicker}
        />
      )),
    [monthNames, displayDate, selected, limitsReset]
  )

  return <MonthsWrapper>{months}</MonthsWrapper>
}

Months.propTypes = {
  locale: PropTypes.string.isRequired,
  displayDate: PropTypes.any,
  selected: PropTypes.array,
  onNavigate: PropTypes.func,
  limits: PropTypes.objectOf(PropTypes.any),
  monthPicker: PropTypes.bool,
}

Months.defaultProps = {
  displayDate: undefined,
  selected: [],
  onNavigate: () => {},
  limits: { min: undefined, max: undefined },
  monthPicker: false,
}
