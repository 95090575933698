import { css } from "styled-components"

export const inputText = css`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.5;
  width: 100%;

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
