import React, { useRef, useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
/* FocusLock traps the focus inside the overlay, making it impossible to focus out of the overlay */
/* Locky helps to prevent taking any other actions, like scrolling outside the overlay */
import Locky from "react-locky"
import FocusLock from "react-focus-lock"

import { MfFontIcon } from "../../atoms/FontIcon"
import { MfButtonZone } from "../ButtonZone"
import { MultistepHeader } from "../../atoms/Multistep"

import useSticky from "../../helpers/useSticky"
import { MfButton } from "../../atoms/Button"
import { bottomSheetPhone, bottomSheetPhablet } from "../../style/css/bottomSheet"

const searchForMfHeaderAsFirstChild = (node) => {
  // First we check if the React.element we are looking at is the mf--popup-header
  // If yes then we stop here and we know the first child is the popup header
  if (
    node &&
    node.type &&
    node.type.attrs &&
    node.type.attrs.length &&
    node.type.attrs[0] &&
    node.type.attrs[0]["data-mfheader"] === "mf--popup-header"
  ) {
    return true
  }

  // If it's not the popup header we look if the element has children
  // if it does then we go a level deeper in the first child
  if (node && node.props && node.props.children) {
    if (node.props.children.length) {
      return searchForMfHeaderAsFirstChild(node.props.children[0])
    }
    return searchForMfHeaderAsFirstChild(node.props.children)
  }

  // If it's not the popup header and has no children it means we reached
  // the end of the first child tree line so we can just return false
  return false
}

/*
  The Overlay takes up the entire screen and 'dims' the other content

  To get the blur effect from the CXC screen, both the popup and the overlay
  must be placed inside different React Portals, and a blur could then be
  applied on some div that parents the content. For this to work some more
  development must be done which I don't know is worth it.
*/
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  backdrop-filter: blur(4px);
  background-color: rgb(0 0 0 / 30%);
  outline: none;

  ${({ $zIndex }) => css`
    z-index: ${$zIndex};
  `};
`

/*
  The PopupStyles contain the styles for the container.
  It also has has the styling for header when it is pinned. The class mf--popup-header is
  used to make sure that the styling is correctly applied. Maybe the styling could be applied
  to the header directly, but during development there was some reason to make it like this.

  Of note is that when the Header is not pinned, it uses padding for all sides. This gets partly
  replaced by margins to prevent it's white background from covering the rounded corners, and
  part padding to make sure the other content still has a seperation from the header text
*/
const PopupStyles = css`
  position: relative;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 8px 4px rgb(0 0 0 / 8%);
  margin: 64px 16px;
  display: flex;
  flex-direction: column;

  ${({ scroll }) =>
    scroll !== "body" &&
    css`
      max-height: calc(100vh - 128px);
    `}

  ${({ fillSpace, scroll }) =>
    fillSpace &&
    scroll !== "body" &&
    css`
      height: calc(100vh - 128px);
    `}

    ${({ theme, pinHeader, scroll }) =>
    (pinHeader || scroll === "content") &&
    css`
      [data-mfheader] {
        border-bottom: ${scroll === "content" ? `1px solid #eee` : `0px`};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: ${theme.spacing(3)};
        background-color: white;

        ${({ theme }) => theme.media.tablet`
          padding-left: ${theme.spacing(6)};
          padding-right: ${theme.spacing(6)};
          };
        `}
      }
    `}

    ${({ scroll }) =>
    scroll === "content" &&
    css`
      [data-placeholder] {
        display: none;
      }
    `}

    ${({ bottomSheet }) => bottomSheet && bottomSheetPhone}

    ${({ theme, bottomSheet }) => theme.media.phablet`
    margin: 72px auto;
    width: 343px;
    ${({ scroll }) =>
      scroll !== "body" &&
      css`
        max-height: calc(100vh - 144px);
      `}

      ${({ fillSpace, scroll }) =>
        fillSpace &&
        scroll !== "body" &&
        css`
          height: calc(100vh - 144px);
        `}

        ${bottomSheet && bottomSheetPhablet}
    `}


    ${({ theme }) => theme.media.tablet`
    border-radius: 16px 16px;
    margin: 48px auto;
    position: relative;
    width: 546px;
    ${({ scroll }) =>
      scroll === "body"
        ? css`
            max-height: initial;
          `
        : css`
            max-height: calc(100vh - 160px);
          `}

    ${({ fillSpace, scroll }) =>
      fillSpace &&
      scroll !== "body" &&
      css`
        height: calc(100vh - 64px);
      `}
  `}
`

const DialogContainer = styled.div`
  ${({ $scroll }) =>
    $scroll === "body" &&
    css`
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
    `}
`

export const MfPopupHeader = styled.div.attrs({ "data-mfheader": "mf--popup-header" })`
  padding: 16px;

  > * {
    margin-top: 0;
  }
`
const PopupContent = styled.div.attrs({ className: "mf--popup-content" })`
  margin-top: ${({ $scroll, $fixedHeader, theme, $header }) =>
    $header
      ? `${theme.spacing(4)}`
      : $scroll === "popup"
      ? $fixedHeader
        ? `${theme.spacing(8)}`
        : `${theme.spacing(7)}`
      : $scroll === "content"
      ? `${theme.spacing(8)}`
      : `${theme.spacing(3)}`};
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  overflow-y: auto;

  ${({ theme }) => theme.media.tablet`
    padding: 0 ${theme.spacing(6)};
  `}
  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    border: 6px solid rgb(0 0 0 / 0%);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #d8d8d8;

    &:hover {
      background-clip: padding-box;
      background-color: #b7b7b7;
    }
  }
`

const PopupContainer = styled("div").withConfig({
  shouldForwardProp: (prop) =>
    ![
      "pinHeader",
      "scroll",
      "fixedHeader",
      "fillSpace",
      "bottomSheet",
      "maxHeight",
      "header",
    ].includes(prop),
})`
  ${PopupStyles}

  ${({ pinHeader }) =>
    pinHeader &&
    css`
      div[data-mfheader="mf--popup-header"] {
        > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    `}
`

const Close = styled.button.attrs({
  children: <MfFontIcon icon="fonticons-close-large" />,
})`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  margin: 0;
  z-index: 1;
  outline: none;

  i {
    font-size: 20px;
    padding: 2px;
    color: ${({ theme }) => theme.color.text.lighter};
  }
`

const ActionWrapper = styled.div`
  margin-top: auto;

  ${({ theme }) => theme.media.tablet`
    ${({ $noZoneBorder }) =>
      !$noZoneBorder &&
      css`
        border-top: 1px solid #ced1dd;
      `}
  `}
  .action-zone {
    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.media.tablet`
      display: flex;
      padding: ${theme.spacing(3)} ${theme.spacing(6)};
    `}
  }
`

export function MfPopup({
  show,
  onClose,
  actions,
  bottomSheet,
  children,
  header,
  scroll,
  weakClose,
  buttonsAlignment,
  noZoneBorder,
  zIndex,
  ...props
}) {
  // We store the header element reference in the headerRef variable.
  const headerRef = useRef()
  const containerRef = useRef()

  // We verify if the first child of content is the header component,
  // if so this will stick the header initially without needing to scroll.
  const isFirstElementHeader = useMemo(
    // We only do the actual node tree recursing if the scroll is set to popup,
    // as this behaviour is not needed in the other scroll types.
    () => scroll === "popup" && searchForMfHeaderAsFirstChild(children),
    [children, scroll]
  )

  // useSticky expects an element to make sticky. The PopupHeader cannot be accessed directly from here
  // because it is defined on the project level. The PopupHeader was given a data-mfheader so we can
  // query it manually here inside an effect and we store it manually in the current key for the header ref.
  const { getContainerProps } = useSticky(headerRef, {
    initialPinned: scroll === "content" || (scroll === "popup" && isFirstElementHeader),
  })

  // we use a callback ref on the container (we need a callback ref because we need to wait for the container to be
  // actually rendered) to get the reference to the header element.
  const getContainerRef = useCallback((el) => {
    if (el) {
      containerRef.current = el

      const headerElement = el.querySelector("[data-mfheader]")
      if (headerElement) {
        headerRef.current = headerElement
      }
    }
  }, [])

  const handleClickOutside = useCallback(
    (event) => {
      if (weakClose) {
        if (!containerRef.current.contains(event.target)) {
          onClose(event)
        }
      }
    },
    [weakClose]
  )

  const handleEscape = useCallback(
    (event) => {
      if (weakClose) {
        if (event.keyCode === 27) {
          onClose(event)
        }
      }
    },
    [weakClose]
  )

  const actionChildren = actions
    ? actions.type === React.Fragment
      ? actions.props.children
      : actions
    : null

  const actionItems = React.Children.map(actionChildren, (child) => {
    if (!React.isValidElement(child)) {
      return null
    }

    return <MfButton {...child.props} />
  })

  const preventBodyKeyBoardScrolling = (e) => {
    if (["ArrowUp", "ArrowDown"].indexOf(e.code) > -1) {
      e.preventDefault()
    }
  }
  useEffect(() => {
    if (show) {
      document.body.addEventListener("keydown", preventBodyKeyBoardScrolling, false)
      document.documentElement.style.overflow = "hidden"
      window.addEventListener("keydown", handleEscape)
    } else {
      document.body.removeEventListener("keydown", preventBodyKeyBoardScrolling)
      document.documentElement.style.removeProperty("overflow")
      window.removeEventListener("keydown", handleEscape)
    }
    return () => {
      if (show) {
        document.body.removeEventListener("keydown", preventBodyKeyBoardScrolling)
        document.documentElement.style.removeProperty("overflow")
        window.removeEventListener("keydown", handleEscape)
      }
    }
  }, [show])

  return show ? (
    <FocusLock>
      <Locky noDefault events={{ scroll: true, keydown: false }}>
        <Overlay onClickCapture={handleClickOutside} $zIndex={zIndex}>
          <DialogContainer $scroll={scroll}>
            <PopupContainer
              {...props}
              ref={getContainerRef}
              {...getContainerProps({ bottomSheet })}
              scroll={scroll}
              header={header}
            >
              {!header && onClose && <Close onClick={onClose} aria-label="Close Popup" />}
              {header && (
                <MultistepHeader
                  showBackButton={header.onBackButtonClick}
                  onBackButtonClick={header.onBackButtonClick}
                  backButtonLabel={header.backButtonLabel}
                  showCloseButton={onClose || false}
                  onClosePopupClick={onClose}
                >
                  {header.title}
                </MultistepHeader>
              )}
              <PopupContent $scroll={scroll} $header={header} $fixedHeader={isFirstElementHeader}>
                {children}
              </PopupContent>
              {actionItems && (
                <ActionWrapper $noZoneBorder={noZoneBorder}>
                  <MfButtonZone className="action-zone" align={buttonsAlignment}>
                    {actionItems}
                  </MfButtonZone>
                </ActionWrapper>
              )}
            </PopupContainer>
          </DialogContainer>
        </Overlay>
      </Locky>
    </FocusLock>
  ) : null
}

MfPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired,
  bottomSheet: PropTypes.bool,
  actions: PropTypes.any,
  scroll: PropTypes.oneOf(["popup", "content", "body"]),
  weakClose: PropTypes.bool,
  fillSpace: PropTypes.bool,
  buttonsAlignment: PropTypes.oneOf(["left", "center", "right"]),
  noZoneBorder: PropTypes.bool,
  zIndex: PropTypes.number,
  header: PropTypes.object,
}

MfPopup.defaultProps = {
  show: false,
  bottomSheet: false,
  actions: null,
  scroll: "popup",
  weakClose: true,
  fillSpace: false,
  buttonsAlignment: "left",
  zIndex: 205,
}
