import { useEffect, useState } from "react"

import { createRawMediaSize } from "./createRawMediaSize"

export const useMatchMedia = (query) => {
  const [isMatch, setIsMatch] = useState(() => false)

  useEffect(() => {
    const mql = matchMedia(createRawMediaSize(query))

    setIsMatch(mql.matches)

    const handleMediaChange = (event) => {
      if (event.matches) {
        setIsMatch(true)
      } else {
        setIsMatch(false)
      }
    }

    mql.addEventListener("change", handleMediaChange)

    return () => {
      mql.removeEventListener("change", handleMediaChange)
    }
  }, [query])

  return isMatch
}
