import { css } from "styled-components"

export const bottomSheetPhone = css`
  border-radius: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(3)}`} 0 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  justify-self: flex-end;
  margin: 0;
  margin-bottom: env(safe-area-inset-bottom);
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: ${({ maxHeight }) => maxHeight || "95vh"};

  ${({ fillSpace }) =>
    fillSpace &&
    css`
      height: ${({ maxHeight }) => maxHeight || "95vh"};
    `}
`

export const bottomSheetPhablet = css`
  margin: 0;
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || "95vh"};

  ${({ fillSpace }) =>
    fillSpace &&
    css`
      height: ${({ maxHeight }) => maxHeight || "95vh"};
    `}
`

export const bottomSheetStyles = css`
  ${bottomSheetPhone}

  ${({ theme }) => theme.media.phablet`
    ${bottomSheetPhablet}
  `}
`
