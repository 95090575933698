import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const SpacerStyles = styled.div`
  ${({ theme, $size, $horizontal }) =>
    $horizontal
      ? css`
          width: ${theme.spacing($size)};
        `
      : css`
          height: ${theme.spacing($size)};
        `}
`

export function MfSpacer({ size, horizontal, ...props }) {
  return <SpacerStyles $size={size} $horizontal={horizontal} {...props} />
}

MfSpacer.propTypes = {
  size: PropTypes.oneOf(["1", "2", "3", "4", "5", "6", "7", "8"]).isRequired,
  horizontal: PropTypes.bool,
}

MfSpacer.defaultProps = {
  horizontal: false,
}
