import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfTypo } from "../../fundamentals/Typo"

const Svg = styled.svg`
  width: 100%;
  position: relative;
`

const Circle = styled.circle`
  stroke-dasharray: 170;
`

const Stop = styled.stop`
  stop-color: ${({ theme }) => theme.color.primary.default};
`

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IconContainerStyles = styled.div`
  width: ${({ $small }) => ($small ? "40px" : "80px")};
  height: ${({ $small }) => ($small ? "40px" : "80px")};
  overflow: hidden;

  .spinning {
    animation: rotate 1.5s linear infinite;
  }
`
const TextContainerStyles = styled.div`
  text-align: center;
  padding: ${({ theme, $small }) => ($small ? theme.spacing(3) : theme.spacing(4))} 0;
`

const SmallTextStyle = styled(MfTypo).attrs({
  variant: "bodytext2",
})`
  color: ${({ theme }) => theme.color.text.lighter};
`

const SpinningIcon = ({ small }) => (
  <IconContainerStyles $small={small}>
    <Svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" className={"spinning"}>
      <Circle fill="transparent" strokeWidth="4" cx="40" cy="40" r="36" stroke="url(#gradient)" />
      <linearGradient id="gradient">
        <Stop offset="0%" stopOpacity="1" />
        <Stop offset="65%" stopOpacity=".5" />
        <Stop offset="100%" stopOpacity="0" />
      </linearGradient>
      <animateTransform
        attributeName="transform"
        begin="0s"
        dur="1.5s"
        type="rotate"
        from="0 0 0"
        to="360 0 0"
        repeatCount="indefinite"
      />
    </Svg>
  </IconContainerStyles>
)

SpinningIcon.propTypes = {
  small: PropTypes.bool.isRequired,
}

export function MfSpinner({ children, small = false, ...props }) {
  return (
    <ContainerStyles {...props}>
      {children && small && (
        <TextContainerStyles $small={small}>
          <SmallTextStyle>{children}</SmallTextStyle>
        </TextContainerStyles>
      )}
      <SpinningIcon small={small} />
      {children && !small && (
        <TextContainerStyles>
          <MfTypo variant="bodytext1">{children}</MfTypo>
        </TextContainerStyles>
      )}
    </ContainerStyles>
  )
}

MfSpinner.propTypes = {
  small: PropTypes.bool,
  children: PropTypes.any,
}
