import React, { useState, useCallback, useEffect, useMemo, useRef, useLayoutEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { MfInputField } from "../../../atoms/InputField"
import { MfShow } from "../../../atoms/ShowContent"
import { MfFontIcon } from "../../../atoms/FontIcon"
import concatClassNames from "../../../helpers/concatClassNames"

import { DateNativeInputField } from "./DateNativeInputField"
import {
  calculateAddedCharacters,
  toDisplayFormat,
  toRawFormat,
  usePrevious,
  validateNewInput,
} from "./changeFunctions"
import { localeToDatePlaceholder, localeToMonthPlaceholder } from "../../../helpers/dateHelper"

const StyledIconContainer = styled.div`
  pointer-events: all;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
  `}
`

const StyledInputField = styled(MfInputField)`
  ${({ theme, active }) =>
    active &&
    `
    & ~ fieldset {
      border-color: ${theme.color.accent.default};
      border-width: 2px;
    }
    
    & + label {
      color: ${theme.color.secondary.default};
    }
  `}
`

const shouldShowError = ({ errorOnTouched, touched, error, validationError }) =>
  (error || validationError) && (errorOnTouched ? touched : true)

export function DateInputField({
  label,
  name,
  value,
  additionalInfo,
  validation,
  limits,
  error,
  errorOnTouched,
  active,
  disabled,
  onChange,
  onIconClick,
  onFocusChange,
  className,
  monthPicker,
  displayFor,
  locale,
  ...props
}) {
  const [inputSectionCount, setInputSectionCount] = useState([0, 0, 0])
  const [inputCursorSection, _setInputCursorSection] = useState(0)
  const [isWriting, setIsWriting] = useState(false)
  const [validationError, setValidationError] = useState()
  const [dirty, setDirty] = useState(false)
  const [touched, setTouched] = useState(false)

  const inputRef = useRef()
  const previousValue = usePrevious(value)
  const previousActive = usePrevious(active)
  let dateDisplaySectionLenghts, dateSectionPositions, dateFormat

  if (monthPicker) {
    dateFormat = "mm/yyyy"
    dateDisplaySectionLenghts = dateFormat.split("/").map((s) => s.length)
    dateSectionPositions = [
      [0, 2], // month section, starts at index 0
      [3, 7], // year section, starts at index 3, don't forget to count the "/" charachter
      [7, 7],
    ]
  } else {
    dateFormat = "dd/mm/yyyy"
    dateDisplaySectionLenghts = dateFormat.split("/").map((s) => s.length)
    dateSectionPositions = [
      [0, 2], // day section, starts at index 0
      [3, 5], // month section, starts at index 3, don't forget to count the "/" charachter
      [6, 10], // year section, starts at index 6, , don't forget to count the "/" charachter, it is 4 long
    ]
  }

  const setInputCursorSection = useCallback(
    (newValue) => {
      setInputSectionCount([0, 0, 0])
      const newValueCalculated = newValue < 0 ? newValue + 3 : newValue % 3
      _setInputCursorSection(newValueCalculated)
    },
    [_setInputCursorSection, setInputSectionCount]
  )

  const handleInputClick = useCallback((e) => {
    e.preventDefault()
    // eslint-disable-next-line no-unused-vars
    const currentCursorSectionIndex = dateSectionPositions.findIndex(([_, end]) => {
      return inputRef.current.selectionStart <= end
    })
    setIsWriting(false)
    setInputCursorSection(currentCursorSectionIndex === -1 ? 0 : currentCursorSectionIndex)
  }, [])

  const handleIconClick = useCallback(
    (e) => {
      e.preventDefault()
      if (disabled) return
      onIconClick()
    },
    [onIconClick]
  )

  const handleFocusChange = useCallback(
    (status) => (e) => {
      e.preventDefault()
      if (!touched && !status) setTouched(true)

      if (status) {
        // eslint-disable-next-line no-unused-vars
        const currentCursorSectionIndex = dateSectionPositions.findIndex(([_, end]) => {
          return inputRef.current.selectionStart <= end
        })
        setIsWriting(false)
        setInputCursorSection(currentCursorSectionIndex === -1 ? 0 : currentCursorSectionIndex)
      }

      onFocusChange(status)
    },
    []
  )

  const handleInputKeyPress = useCallback(
    (e) => {
      if (e.keyCode !== 39 && e.keyCode !== 37) return

      e.preventDefault()
      setIsWriting(false)
      if (e.keyCode === 39) {
        const newSectionPosition = (inputCursorSection + 1) % 3
        setInputCursorSection(newSectionPosition)
        return
      }

      if (e.keyCode === 37) {
        const newSectionPosition = inputCursorSection === 0 ? 2 : inputCursorSection - 1
        setInputCursorSection(newSectionPosition)
        return
      }
    },
    [inputCursorSection]
  )

  const updateStates = useCallback(
    ({ date, count, position, writing, updateDirty = true }) => {
      if (updateDirty && !dirty) setDirty(true)
      if (count) setInputSectionCount(count)
      if (position || position === 0) setInputCursorSection(position)
      if (typeof writing === "boolean" || writing) setIsWriting(writing)
      if (date || date === "") onChange(date)
    },
    [dirty, setDirty, setInputSectionCount, setInputCursorSection, setIsWriting, onChange]
  )

  const handleInputChange = useCallback(
    ({ target }) => {
      if (!validateNewInput(target.value)) return

      const previousDate = value
      const currentDate = toRawFormat(value, target.value, monthPicker)
      const addedCharacters = calculateAddedCharacters(value, target.value)
      const currentCount = [...inputSectionCount]

      // If field was empty and we just inputed characters (typing: 1, copy/paster: 1+)
      if (!previousDate && addedCharacters > 0) {
        currentCount[0] = 1
        return updateStates({
          date: currentDate,
          count: currentCount,
          writing: true,
        })
      }

      // If all sections have been put back to 00/00/0000, we just return an empty string (kinda like a reset)
      if (/^[0-]*$/.test(currentDate)) {
        return updateStates({
          date: "",
          writing: false,
          position: 0,
          updateDirty: false,
        })
      }

      const defaultSectionLength = dateDisplaySectionLenghts[inputCursorSection]
      const newSectionCount = currentCount[inputCursorSection] + addedCharacters

      // If we were in "selection" mode (section highlighted) and we just typed something
      if (!isWriting) {
        currentCount[inputCursorSection] = defaultSectionLength + addedCharacters
        return updateStates({
          date: currentDate,
          count: currentCount,
          writing: true,
        })
      }

      // If we deleted/filled a section we save and go to the next section
      if (newSectionCount <= 0 || newSectionCount >= defaultSectionLength) {
        return updateStates({
          date: currentDate,
          position: inputCursorSection + 1,
          writing: false,
        })
      }

      // Default behaviour if we just typed something in any other situation
      currentCount[inputCursorSection] = Math.max(newSectionCount, 0)
      updateStates({
        date: currentDate,
        count: currentCount,
        writing: true,
      })
    },
    [updateStates, dirty, inputSectionCount, inputCursorSection]
  )

  useEffect(() => {
    if (!dirty && previousValue.current !== value) {
      setDirty(true)
      previousValue.current = value
    }
    setValidationError(validation(value))
  }, [value])

  useEffect(() => {
    if (touched) return
    if (previousActive.current && !active) {
      setTouched(true)
    }
    previousActive.current = active
  }, [touched, active])

  useLayoutEffect(() => {
    if (!inputRef.current) return
    const positionStart = dateSectionPositions[inputCursorSection][0]
    const positionEnd = dateSectionPositions[inputCursorSection][1]
    if (isWriting) {
      inputRef.current.setSelectionRange(positionEnd, positionEnd)
    } else {
      inputRef.current.setSelectionRange(positionStart, positionEnd)
    }
  }, [value, inputCursorSection, isWriting])

  const inputClassName = useMemo(() => {
    const hasError = shouldShowError({ error, validationError, errorOnTouched, touched })

    return concatClassNames(
      className || "",
      hasError ? (!active ? "error" : "") : "valid",
      touched ? "touched" : "untouched",
      dirty ? "dirty" : "pristine"
    )
  }, [className, error, validationError, errorOnTouched, touched, dirty, active])

  const errorMessage = useMemo(
    () =>
      shouldShowError({ error, validationError, errorOnTouched, touched })
        ? error || validationError
        : "",
    [errorOnTouched, touched, error, validationError]
  )

  const displayValue = useMemo(() => toDisplayFormat(value, isWriting, monthPicker), [value])

  const getPlaceHolderValue = () => {
    if (monthPicker) return localeToMonthPlaceholder(locale)
    return localeToDatePlaceholder(locale)
  }

  const getType = () => {
    if (monthPicker) return "month"
    return "date"
  }

  return (
    <>
      <MfShow from={displayFor}>
        <StyledInputField
          {...props}
          id={name}
          name={name}
          onChange={handleInputChange}
          value={displayValue}
          className={inputClassName}
          type="text"
          label={label}
          placeholder={getPlaceHolderValue()}
          icon={
            <StyledIconContainer
              data-testid={`${name}_dateIcon`}
              onClick={handleIconClick}
              $disabled={disabled}
            >
              <MfFontIcon icon="fonticons-calendar " color="inherit" size="24px" />
            </StyledIconContainer>
          }
          innerRef={inputRef}
          onClick={handleInputClick}
          onKeyDown={handleInputKeyPress}
          onFocus={handleFocusChange(true)}
          onBlur={handleFocusChange(false)}
          active={active}
          disabled={disabled}
          error={errorMessage}
          additionalInfo={additionalInfo}
          shrinkLabel={false}
        />
      </MfShow>
      <MfShow until={displayFor}>
        <DateNativeInputField
          {...props}
          type={getType()}
          name={name}
          value={value}
          label={label}
          className={inputClassName}
          onChange={(data) => {
            onChange(data)
            !dirty && setDirty(true)
          }}
          onFocusChange={(status) => !touched && !status && setTouched(true)}
          disabled={disabled}
          error={errorMessage}
          additionalInfo={additionalInfo}
          limits={limits}
          shrinkLabel={true}
        />
      </MfShow>
    </>
  )
}

DateInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  additionalInfo: PropTypes.string,
  validation: PropTypes.func,
  limits: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.string,
  errorOnTouched: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  onFocusChange: PropTypes.func,
  monthPicker: PropTypes.bool,
  displayFor: PropTypes.string,
  locale: PropTypes.string,
}

DateInputField.defaultProps = {
  value: "",
  additionalInfo: undefined,
  icon: undefined,
  error: undefined,
  errorOnTouched: false,
  active: undefined,
  className: "",
  disabled: false,
  limits: { min: undefined, max: undefined },
  validation: () => {},
  onChange: () => {},
  onIconClick: () => {},
  onFocusChange: () => {},
  displayFor: "phablet",
}
