import { css } from "styled-components"

export const title5 = css`
  font-size: ${({ theme }) => theme.font.size.title5};
  line-height: 1.56;
  font-weight: normal;
`

export const title5Bold = css`
  ${title5}
  font-weight: ${({ theme }) => theme.font.weight.bolder};
  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
