import { css } from "styled-components"

export const title4 = css`
  font-size: ${({ theme }) => theme.font.size.title4};
  line-height: 1.4;
  font-weight: normal;
`

export const title4Bold = css`
  ${title4}
  font-weight: ${({ theme }) => theme.font.weight.bolder};
  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
