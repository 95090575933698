import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import moment from "moment"

import { MfShow } from "../../atoms/ShowContent"

import { Calendar } from "./Calendar/Calendar"
import { DateInputField } from "./Input/DateInputField"
import { Dropdown } from "./Dropdown"
import { MfButtonZone } from "../ButtonZone"
import { MfButton } from "../../atoms/Button"

const ComponentStyle = styled.div`
  position: relative;
`

const CallendarWrapper = styled.div`
  ${({ theme }) => theme.media.phablet`
    padding: 2rem;
  `}
`

const CloseButtonForMobile = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.media.phablet`
      display: none;
  `}
`

const todayString = moment().format("YYYY-MM-DD")

export function MfDatePicker({
  label,
  name,
  value,
  locale,
  validation,
  error,
  additionalInfo,
  min,
  max,
  icon,
  innerRef,
  onChange,
  onPickerDisplay,
  disabled,
  monthPicker,
  monthLabel,
  errorOnTouched,
  disabledWeekDays,
  disableDates,
  displayFor,
  actions,
  ...props
}) {
  const componentRef = useRef()

  const [openPicker, setOpenPicker] = useState(false)
  const [startDate, setStartDate] = useState(undefined)
  const [inputDate, onInputChange] = useUpdatableState(value, onChange)

  const handlePickerDisplay = useCallback(
    (data) => {
      if (!onPickerDisplay) return setOpenPicker(data)

      if (typeof data === "function")
        return setOpenPicker((prev) => {
          const res = data(prev)
          onPickerDisplay(res)
          return res
        })

      setOpenPicker(data)
      onPickerDisplay(data)
    },
    [onPickerDisplay]
  )

  const togglePicker = useCallback(() => {
    handlePickerDisplay((prev) => !prev)
  }, [])

  const handleInputFocus = useCallback((state) => {
    state && handlePickerDisplay(false)
  })

  const actionChildren = actions
    ? actions.type === React.Fragment
      ? actions.props.children
      : actions
    : null

  const actionItems = React.Children.map(actionChildren, (child) => {
    if (!React.isValidElement(child)) {
      return null
    }

    return <MfButton onClick={() => togglePicker()} {...child.props} />
  })

  useEffect(() => {
    const eventHandler = (e) => {
      if (!componentRef.current.contains(e.target)) {
        handlePickerDisplay(false)
      }
    }

    window.addEventListener("click", eventHandler)
    window.addEventListener("focusin", eventHandler)
    return () => {
      window.removeEventListener("click", eventHandler)
      window.removeEventListener("focusin", eventHandler)
    }
  }, [])

  useEffect(() => {
    if (value) {
      setStartDate(value)
    } else {
      let todayDate = new Date(todayString)
      if (min && new Date(min) > todayDate) {
        setStartDate(min)
      } else if (max && new Date(max) < todayDate) {
        setStartDate(max)
      } else {
        setStartDate(todayString)
      }
    }
  }, [value, min, max])

  return (
    <ComponentStyle ref={componentRef}>
      <DateInputField
        {...props}
        label={label}
        name={name}
        value={inputDate}
        validation={validation}
        error={error}
        additionalInfo={additionalInfo}
        icon={icon}
        active={openPicker}
        disabled={disabled}
        innerRef={innerRef}
        onChange={(date) => {
          onInputChange(date)
          setStartDate(date)
        }}
        monthPicker={monthPicker}
        monthLabel={monthLabel}
        onIconClick={togglePicker}
        onFocusChange={handleInputFocus}
        errorOnTouched={errorOnTouched}
        limits={{ min, max }}
        displayFor={displayFor}
        locale={locale}
      />
      <MfShow from={displayFor}>
        <Dropdown open={openPicker} placement="right" marginBottom="13px">
          <CallendarWrapper>
            <Calendar
              locale={locale}
              start={startDate}
              weekStart={1}
              selected={(inputDate && [inputDate]) || undefined}
              onNavigate={setStartDate}
              limits={{ min, max }}
              onSelect={onInputChange}
              disabledWeekDays={disabledWeekDays}
              disableDates={disableDates}
              monthPicker={monthPicker}
              monthLabel={monthLabel}
              displayFor={displayFor}
              togglePicker={togglePicker}
            />
          </CallendarWrapper>
          {actionItems && (
            <CloseButtonForMobile data-testid="mobile_close_button">
              <MfButtonZone align={"center"}>{actionItems}</MfButtonZone>
            </CloseButtonForMobile>
          )}
        </Dropdown>
      </MfShow>
    </ComponentStyle>
  )
}

MfDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  additionalInfo: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  icon: PropTypes.any,
  innerRef: PropTypes.any,
  disabled: PropTypes.bool,
  errorOnTouched: PropTypes.bool,
  onChange: PropTypes.func,
  onPickerDisplay: PropTypes.func,
  validation: PropTypes.func,
  disabledWeekDays: PropTypes.arrayOf(PropTypes.number),
  disableDates: PropTypes.arrayOf(PropTypes.string),
  monthPicker: PropTypes.bool,
  monthLabel: PropTypes.string,
  displayFor: PropTypes.string,
  actions: PropTypes.any,
}

MfDatePicker.defaultProps = {
  locale: "fr-BE",
  error: undefined,
  additionalInfo: undefined,
  min: undefined,
  max: undefined,
  icon: undefined,
  innerRef: undefined,
  disabled: false,
  errorOnTouched: undefined,
  onChange: undefined,
  onPickerDisplay: undefined,
  disabledWeekDays: undefined,
  disableDates: undefined,
  validation: () => {},
  displayFor: "phablet",
  actions: null,
}

function useUpdatableState(prop, changeProp) {
  const [internalState, _setInternalState] = useState(prop)

  const setInternalState = (data) => {
    _setInternalState(data)
    changeProp && changeProp(data)
  }

  useEffect(() => {
    _setInternalState(prop)
  }, [prop])
  return [internalState, setInternalState]
}
