import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { useWidget } from "../../../fundamentals/Widget"
import { MfButton } from "../../Button"
import { MenuItem, StyledIconsContainer } from "../MenuItem"

export const StyledButtonContainer = styled.div`
  background: none;
  border-left: 4px solid transparent;
  border-radius: 0;
  color: ${({ theme }) => theme.color.text.darker};
  display: flex;
  line-height: unset;
  text-decoration: none;
  width: 100%;

  ${({ $selected }) =>
    $selected &&
    css`
      background: #f7fcfa;
    `}

  ${({ theme }) => theme.media.tablet`
    &:hover, &:active {
      background: #f7fcfa;
      border-left: 4px solid ${({ theme }) => theme.color.primary.default};
    }

    &:focus {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
      outline: solid 4px rgba(0, 150, 94, 0.2);
      background: #f7fcfa;
    }
  `}
`

export const StyledButton = styled(MfButton)`
  background: none;
  border-radius: 0;
  color: ${({ theme }) => theme.color.text.darker};
  justify-content: flex-start;
  line-height: unset;
  padding: 0;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:active {
    background: none;
    text-decoration: none;
  }

  &:focus {
    background: none;
  }

  ${({ theme }) => theme.media.tablet`
    padding: ${theme.spacing(3)} ${theme.spacing(4)};
  `}
`

export const ActionsItem = ({ selected, onSelect, actions, ...props }) => {
  const { setIsOpen } = useWidget()

  function handleSelect(event) {
    onSelect(event, setIsOpen)
  }

  return (
    <MenuItem checkSelected={false} selected={selected}>
      <StyledButton {...props} onClick={handleSelect} />
      {actions !== undefined && <StyledIconsContainer>{actions}</StyledIconsContainer>}
    </MenuItem>
  )
}

ActionsItem.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  actions: PropTypes.node,
}

ActionsItem.defaultProps = {
  selected: false,
  actions: undefined,
}
