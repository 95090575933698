import theme_fb from "../style/theme_fb"
import theme_hb from "../style/theme_hb"
import theme_kn from "../style/theme_kn"

export default function getThemeForBrand(brand) {
  switch (brand) {
    case "fb":
      return theme_fb
    case "kn":
      return theme_kn
    case "hb":
      return theme_hb
    default:
      return theme_fb
  }
}
