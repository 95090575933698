import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};

  ${({ $action }) =>
    $action &&
    css`
      cursor: pointer;

      &:hover {
        .label {
          text-decoration: underline;
        }
      }
    `}

  &:focus,
  &:focus-visible {
    outline: 2px solid rgb(0 150 94 / 20%);
  }
`

function Cell({ onClick, children }) {
  const handleKeyPress = (e) => {
    if ((e.key === " " || e.key === "Enter") && onClick) {
      e.preventDefault()
      onClick()
    }
  }

  return (
    <Container
      tabIndex={onClick ? 0 : -1}
      $action={onClick}
      onClick={onClick}
      onKeyPress={handleKeyPress}
    >
      {children}
    </Container>
  )
}

Cell.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export const CellWrapper = React.forwardRef((props, ref) => <Cell innerRef={ref} {...props} />)
CellWrapper.displayName = "CellWrapper"
