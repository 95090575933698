import React, { forwardRef, useMemo } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfTypo } from "../../fundamentals/Typo"
import { MfSpacer } from "../Spacer"
import { CtaZone } from "./MenuAction"

const lgDeviceMaxHeightDropdownMenu = 476
const mdDeviceMaxHeightDropdownMenu = 275
const cmClassName = "my-contextual-menu"

const Container = styled.div`
  background: ${({ theme }) => theme.color.background.light};
  overflow: hidden;
  padding: 4px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet`
    border-radius: 16px;
    box-shadow: 0 0 8px 4px rgb(0 0 0 / 8%);
    max-height: ${mdDeviceMaxHeightDropdownMenu}px;
    min-width: 300px;
    padding: 0;
  `}

  ${({ theme }) => theme.media.lgscreen`
    max-height: ${lgDeviceMaxHeightDropdownMenu}px;
`}
`
const ScrollContainer = styled.div`
  padding: 0;
  margin: 0;
  overflow: auto;
  flex: 0 1;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Title = styled.div`
  ${({ theme }) => css`
    ${theme.media.tablet`
      padding: 16px 16px 0 16px;
    `}
  `}
`

const Description = styled.div`
  margin-bottom: 16px;
  ${({ theme }) => css`
    ${theme.media.tablet`
      padding: 0 16px;
    `}
  `}
`

const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.color.border.disabled};
  border-bottom: 0;
  ${({ theme }) => theme.media.tablet`
    margin: 0 ${theme.spacing(3)};
  `}
`

export const Menu = forwardRef(
  ({ children, title, description, cta, className, ...props }, ref) => {
    // Allow Menu to be custom styled by styled components by adding the
    // possible className from the props to the classNames of the container
    const classNames = useMemo(
      () => (typeof className === "string" ? cmClassName + " " + className : cmClassName),
      [className]
    )

    return (
      <Container ref={ref} className={classNames} {...props}>
        <ScrollContainer>
          {title && (
            <Title>
              <MfTypo variant="title4" weight="bold">
                {title}
              </MfTypo>
            </Title>
          )}
          {title && description && <MfSpacer size="2" />}
          {description && (
            <Description>
              <MfTypo variant="bodytext1">{description}</MfTypo>
            </Description>
          )}
          <List>
            {React.Children.map(children, (child, i) => {
              return (
                <>
                  {child}
                  {(i < React.Children.count(children) - 1 || cta) && <Hr />}
                </>
              )
            })}
          </List>
        </ScrollContainer>
        <CtaZone cta={cta} />
      </Container>
    )
  }
)

Menu.displayName = "Menu"

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.any,
  description: PropTypes.any,
  cta: PropTypes.node,
}

Menu.defaultProps = {
  className: undefined,
  title: undefined,
  description: undefined,
  cta: null,
}
