import { useMemo } from "react"
import {
  arrow,
  autoUpdate,
  autoPlacement,
  flip,
  shift,
  offset,
  useFloating,
  useClick,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
} from "@floating-ui/react"

export const OverflowStrategies = {
  Flip: "Flip",
  Move: "Move",
  All: "All",
  None: "None",
}

export const Placements = {
  Top: "top",
  Right: "right",
  Bottom: "bottom",
  Left: "left",
}

export const Alignments = {
  Center: "",
  Start: "start",
  End: "end",
}

export function useFloatingWidget({
  arrowRef,
  onOpenChange,
  open,
  overflowStrategy,
  placement,
  alignment,
  offset: _offset = 0,
  weakClose = true,
  role = "tooltip",
  interact: { canFocus = true, canHover = false, canClick = true } = {},
  showBottomSheet,
} = {}) {
  // Define the middlewares for controlling the Widget
  const middleware = useMemo(() => {
    const middlewareFns = [offset(_offset)]

    if (placement) {
      if (
        overflowStrategy === OverflowStrategies.Flip ||
        overflowStrategy === OverflowStrategies.All
      ) {
        middlewareFns.push(flip())
      }

      if (
        overflowStrategy === OverflowStrategies.Move ||
        overflowStrategy === OverflowStrategies.All
      ) {
        middlewareFns.push(shift())
      }
    } else {
      middlewareFns.push(autoPlacement())
    }

    if (arrowRef) {
      middlewareFns.push(
        arrow({
          element: arrowRef,
        })
      )
    }

    return middlewareFns
  }, [_offset, overflowStrategy, placement, arrowRef])

  // Create the floating placement string based on the placement and alignment
  const floatingPlacement = useMemo(() => {
    if (placement === undefined) return undefined

    const _placement = placement

    if (alignment) {
      return placement + "-" + alignment
    }

    return _placement
  }, [placement, alignment])

  /**
   *
   * Create the Floating UI Logic
   *
   */
  const data = useFloating({
    placement: floatingPlacement,
    open,
    onOpenChange,
    whileElementsMounted: autoUpdate,
    middleware,
  })

  const context = data.context

  // Define the possible interactions for handling the Widget behaviour
  const clickInteraction = useClick(context, {
    enabled: canClick,
  })

  const hoverInteraction = useHover(context, {
    move: false,
    enabled: canHover,
  })

  const focusInteraction = useFocus(context, {
    enabled: canFocus,
  })

  const dismissInteraction = useDismiss(context, {
    outsidePress: weakClose && !showBottomSheet,
    escapeKey: weakClose,
  })
  const roleInteraction = useRole(context, { role })

  const interactions = useInteractions([
    clickInteraction,
    hoverInteraction,
    focusInteraction,
    dismissInteraction,
    roleInteraction,
  ])

  return useMemo(
    () => ({
      ...data,
      interactions,
      isOpen: open,
      setIsOpen: onOpenChange,
    }),
    [interactions, data]
  )
}
