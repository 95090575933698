import React from "react"
import { MfInputField } from "./index"
import styled from "styled-components"

const Adornment = styled.div`
  color: ${({ theme }) => theme.color.text.disabled2};
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(3)} 12px;
  font-family: ${({ theme }) => theme.font.family.mobile.normal};
  font-weight: normal;
  letter-spacing: -2px;
`

const StructuredInput = (props) => {
  return (
    <MfInputField
      prefix={<Adornment>+ + +</Adornment>}
      suffix={<Adornment>+ + +</Adornment>}
      centerText
      {...props}
    />
  )
}

const MfStructuredInput = React.forwardRef((props, ref) => (
  <StructuredInput innerRef={ref} {...props} />
))

export { MfStructuredInput }
