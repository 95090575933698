import { css } from "styled-components"

export default (sizes) =>
  Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `

    return acc
  }, {})
