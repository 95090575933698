import React from "react"
import PropTypes from "prop-types"

import { MfInputField } from "../../../atoms/InputField"

export function DateNativeInputField({
  label,
  name,
  value,
  additionalInfo,
  limits,
  error,
  disabled,
  onChange,
  className,
  onFocusChange,
  type,
  ...props
}) {
  return (
    <MfInputField
      {...props}
      id={name}
      name={name}
      className={className}
      type={type}
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => onFocusChange(false)}
      onFocus={() => onFocusChange(true)}
      disabled={disabled}
      error={error}
      additionalInfo={additionalInfo}
      min={limits.min}
      max={limits.max}
    />
  )
}

DateNativeInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  additionalInfo: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  limits: PropTypes.objectOf(PropTypes.string),
  onFocusChange: PropTypes.func,
  type: PropTypes.string,
}

DateNativeInputField.defaultProps = {
  value: "",
  additionalInfo: undefined,
  error: undefined,
  className: "",
  disabled: false,
  limits: { min: undefined, max: undefined },
  onFocusChange: () => {},
}
