import React, { useMemo, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useCombobox } from "downshift"

import { bodyText1 } from "../../style/css"

import { MfInputField } from "../InputField"
import { MfFontIcon } from "../FontIcon"
import { MfPopup } from "../../organisms/Popup"
import { MfTypo } from "../../fundamentals/Typo"
import { MfSpacer } from "../Spacer"
import { MfSpinner } from "../Spinner"
import { useDebounce } from "../../helpers/useDebounce"

const ContainerStyles = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing(7)};
`

const ScrollableContentStyles = styled.div`
  overflow-y: auto;
  flex: 1;
`

const ListContainerStyles = styled.div`
  position: relative;

  /* height: 200px; */
`

const ItemContainerStyles = styled.div`
  ${({ $highlighted, theme }) =>
    $highlighted &&
    css`
      background: ${theme.color.secondary.lighter4};
    `}
`

const ItemButtonStyles = styled.button`
  ${bodyText1}

  display: block;
  line-height: 45px;
  background: transparent;
  border: 0;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.lighter};
  text-align: left;
  outline: none;
  cursor: pointer;
`

const EmptyTextStyles = styled.p`
  ${bodyText1}
  font-style: italic;
`

const MaxItemsStyle = styled(MfTypo).attrs({
  variant: "bodytext1",
})`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)} 0;
  color: ${({ theme }) => theme.color.text.lighter};
`

// Set up some defaults for the complex picker

// The RenderItem default component to render the items in the list
// This will just render the children inside a div
export function RenderItem(props) {
  return <ItemButtonStyles type="button" {...props} />
}

// The default search behaviour just searches for a string match in the array
function createSearchForList(list) {
  return function searchTermInList(term) {
    return list.filter((item) => {
      return typeof item === "string"
        ? item.toLowerCase().indexOf(term.toLowerCase()) !== -1
        : JSON.stringify(item).toLowerCase().indexOf(term.toLowerCase()) !== -1
    })
  }
}

export function MfSearch({
  onChange,
  list,
  hasSearch,
  search,
  searchLabel,
  RenderItem,
  emptyText,
  title,
  maxItems,
  maxItemsWarningText,
  debounce,
}) {
  const [term, setTerm] = useState("")

  const getItems = useCallback(search(list), [list])
  const debouncedTerm = useDebounce(term, debounce)
  const items = useMemo(() => getItems(debouncedTerm), [debouncedTerm, getItems])

  const listRef = useRef()

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items,
    inputValue: term,
    onInputValueChange: ({ inputValue }) => {
      setTerm(inputValue)
    },
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
  })

  // Then map the remaining items to the RenderItem display
  const displayList = useMemo(() => {
    if (items.length === 0) {
      if (typeof emptyText === "string") {
        return <EmptyTextStyles variant="bodytext1">{emptyText}</EmptyTextStyles>
      }

      return emptyText
    }

    return items.slice(0, maxItems).map((item, index) => {
      if (typeof item === "string") {
        return (
          <ItemContainerStyles
            key={item}
            $highlighted={index === highlightedIndex}
            {...getItemProps({
              item,
              index,
            })}
          >
            <RenderItem onClick={() => onChange(item)}>{item}</RenderItem>
          </ItemContainerStyles>
        )
      } else {
        return (
          <ItemContainerStyles
            key={JSON.stringify(item)}
            $highlighted={index === highlightedIndex}
            {...getItemProps({
              item,
              index,
            })}
          >
            <RenderItem {...item} onClick={() => onChange(item)} />
          </ItemContainerStyles>
        )
      }
    })
  }, [items, highlightedIndex])

  return (
    <ContainerStyles>
      <div>
        <MfTypo variant="title4" weight="bold">
          {title}
        </MfTypo>
        <div style={!hasSearch ? { opacity: 0, position: "absolute" } : {}}>
          <MfSpacer size="4" />
          <MfInputField
            aria-label="search"
            label={searchLabel}
            name="complexpicker-search"
            {...getInputProps()}
            icon={<MfFontIcon icon="fonticons-search " color="inherit" size="24px" />}
          />
        </div>
        <ScrollableContentStyles
          {...getMenuProps({
            ref: listRef,
          })}
        >
          <ListContainerStyles>{displayList}</ListContainerStyles>
          {displayList.length < items.length && maxItemsWarningText}
        </ScrollableContentStyles>
      </div>
    </ContainerStyles>
  )
}

MfSearch.propTypes = {
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  hasSearch: PropTypes.bool,
  search: PropTypes.func,
  searchLabel: PropTypes.string,
  RenderItem: PropTypes.func,
  emptyText: PropTypes.string,
  title: PropTypes.string,
  recommendations: PropTypes.array,
  maxItems: PropTypes.number,
  maxItemsWarningText: PropTypes.any,
  debounce: PropTypes.number,
}

MfSearch.defaultProps = {
  RenderItem: RenderItem,
  hasSearch: false,
  search: createSearchForList,
  searchLabel: `Search an item`,
  emptyText: `No matches found`,
  title: `Choose your item`,
  recommendations: [],
  maxItems: undefined,
  maxItemsWarningText: <MaxItemsStyle>Use the search for more results</MaxItemsStyle>,
  debounce: 0,
}

/*
  mergeLists will check if there is a recommendedList and insert it at the beginning of the list
  This will make sure there is one functional list to work with inside the MfSearch Component
  The listtitles will be added inside the MfSearch to the first elements of the 2 lists based on
  the length of the recommended list.
 */
function mergeLists(list, recommendedList) {
  if (!recommendedList) {
    return list
  }

  // Create a new set with first the recommended list items followed by the regular list
  // then a new set is created which ensures the recommended items are not duplicated by
  // also being in the regular set. Finally the set is spread back into an array to be returned
  return [...new Set([...recommendedList, ...list])]
}

export function MfComplexPicker({ show, onClose, list, recommendations, fillSpace, ...props }) {
  const mergedList = useMemo(() => mergeLists(list, recommendations), [list, recommendations])

  return (
    <MfPopup show={show} onClose={onClose} fillSpace={fillSpace}>
      {list && list.length > 0 ? (
        <MfSearch list={mergedList} recommendations={recommendations} {...props} />
      ) : (
        <MfSpinner>Loading items</MfSpinner>
      )}
    </MfPopup>
  )
}

MfComplexPicker.propTypes = {
  list: PropTypes.array.isRequired,
  recommendations: PropTypes.array,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fillSpace: PropTypes.bool,
}

MfComplexPicker.defaultProps = {
  recommendations: undefined,
  fillSpace: false,
}
