import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfFontIcon } from "../../FontIcon"
import { MfCheckbox } from "../../Checkbox"

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border.lighter};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px 16px;

  /* margin-bottom: 8px; */
  cursor: pointer;
  min-height: 54px;
  transition: box-shadow 0.5s;

  ${({ theme, checkbox }) =>
    checkbox &&
    css`
      padding-left: ${theme.spaceValues[7]};
    `}

  ${({ theme, $selected, checkbox }) =>
    $selected &&
    css`
      /* border: 2px solid ${theme.color.primary.default}; */

      /* top: -2px; */
      background-color: ${!checkbox ? theme.color.secondary.lighter4 : "unset"};
      position: relative;
      border-color: ${theme.color.accent.default};
      box-shadow: 1px 1px 0 inset ${theme.color.accent.default},
        -1px -1px 0 inset ${theme.color.accent.default},
        1px -1px 0 inset ${theme.color.accent.default},
        -1px 1px 0 inset ${theme.color.accent.default};
    `}
  
  ${({ theme, $highlighted, checkbox }) =>
    $highlighted &&
    css`
      outline: solid 4px ${theme.color.outline.focus};
      border-color: ${theme.color.accent.default};
      background-color: ${!checkbox ? theme.color.secondary.lighter4 : "unset"};
    `}
  

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      border: 1px solid ${theme.color.border.disabled};
      box-shadow: none;
      background-color: white;
      cursor: not-allowed;

      * {
        color: ${theme.color.text.disabled2} !important;
      }

      img {
        filter: grayscale(1);
      }
    `}

  ${({ theme, $error }) =>
    typeof $error === "object" &&
    $error.length > 0 &&
    css`
      border: 2px solid ${theme.color.status.error};
      box-shadow: none;
      background-color: white;
    `}

  &:focus, &:active {
    ${({ $disabled, theme, checkbox }) =>
      !$disabled &&
      css`
        outline: solid 4px ${theme.color.outline.focus};
        border-color: ${theme.color.accent.default};
        background-color: ${!checkbox ? theme.color.secondary.lighter4 : "unset"};
      `}
  }
`

const ErrorWrapper = styled.div`
  ${({ theme }) =>
    css`
      font-size: 14px;
      line-height: 20px;
      color: ${theme.color.status.error};
      margin: 4px 16px;
    `}
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 22px;
`

const Arrow = styled(MfFontIcon).attrs({ icon: "fonticons-chevron-right" })`
  padding-left: 0;
  color: ${({ theme }) => theme.color.accent.default};
  margin-right: -5px;
  margin-top: -1px;
  align-self: top;
`

const Checkmark = styled(MfFontIcon).attrs({ icon: "fonticons-check" })`
  padding-left: 8px;
  color: ${({ theme }) => theme.color.accent.default};
  align-self: top;
  font-size: 22px;
`

const Empty = styled.div`
  box-sizing: content-box;
  padding-left: 8px;
  width: 24px;
  align-self: top;
`

const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  top: 18px;
  left: 16px;
`

function WrappedBaseOption({
  label,
  children,
  innerRef,
  multi,
  selected,
  highlighted,
  disabled,
  error,
  checkbox,
  ...props
}) {
  return (
    <>
      {checkbox && (
        <CheckboxContainer checkbox={checkbox}>
          <MfCheckbox
            label=""
            name={selected ? "selected" : "default"}
            value={selected ? true : false}
            onChange={() => {}}
          />
        </CheckboxContainer>
      )}
      <Container
        ref={innerRef}
        $selected={selected}
        $highlighted={highlighted}
        $disabled={disabled}
        $error={error}
        checkbox={checkbox}
        {...props}
      >
        <LabelContainer>
          {label}
          {multi ? (
            selected && !checkbox ? (
              <Checkmark />
            ) : (
              <Empty />
            )
          ) : error.length !== 0 || checkbox ? (
            <Empty />
          ) : (
            <Arrow />
          )}
        </LabelContainer>
        {children}
      </Container>
      {error &&
        error.map((item, index) => {
          return <ErrorWrapper key={"error" + index}>{item}</ErrorWrapper>
        })}
    </>
  )
}

// We need to wrap the BaseOption in a forwardRef call to make sure the ref is put on the Container
export const BaseOption = React.forwardRef((props, ref) => (
  <WrappedBaseOption innerRef={ref} {...props} />
))
BaseOption.displayName = "BaseOption"

WrappedBaseOption.propTypes = {
  label: PropTypes.any.isRequired,
  children: PropTypes.any,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  innerRef: PropTypes.any,
  error: PropTypes.arrayOf(PropTypes.string),
  checkbox: PropTypes.bool,
}

WrappedBaseOption.defaultProps = {
  children: null,
  selected: false,
  highlighted: false,
  disabled: false,
  multi: false,
  innerRef: undefined,
  error: [],
  checkbox: false,
}
