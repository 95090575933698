import { css } from "styled-components"

export const title2 = css`
  font-size: ${({ theme }) => theme.font.size.title2};
  line-height: 1.25;
  font-weight: normal;
`

export const title2Bold = css`
  ${title2}
  font-weight: ${({ theme }) => theme.font.weight.bold};
  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
