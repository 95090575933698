import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfButtonZone } from "../../organisms/ButtonZone"

const ActionZone = styled.div`
  padding: 24px 16px 16px;

  ${({ theme }) => theme.media.tablet`
    border-top: 1px solid #c3c3c3;
    padding: 16px 40px;
  `}
`

const Actions = styled(MfButtonZone)`
  display: block;

  ${({ theme }) => theme.media.tablet`
    display: flex;
  `}
`

export function MultistepActions({ children, align = "left", ...props }) {
  return (
    <ActionZone {...props}>
      <Actions align={align}>{children}</Actions>
    </ActionZone>
  )
}

MultistepActions.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node,
}
