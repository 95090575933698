import React, { forwardRef, useCallback } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfTypo } from "../../fundamentals/Typo"

const LabelContainer = styled.label`
  cursor: pointer;
  position: relative;
  display: block;

  &.inline {
    display: inline-block;
    margin-right: 40px;
  }

  &.block {
    display: block;
    margin-bottom: 0.813em;
  }
`

const CheckboxStyled = styled.span`
  &::before {
    color: ${({ theme, $error, $value }) =>
      $error && !$value ? theme.color.status.error : theme.color.accent.default};
    content: ${({ $checked }) => ($checked ? "'\\f1fd'" : "'\\f12f'")};
    font-family: font-icons, sans-serif;
    font-size: 1.5rem;
    left: 0;
    line-height: 0.95;
    max-width: 23px;
    height: 22px;
    position: absolute;
    top: 0;
    user-select: none;
  }

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      &::before {
        background: ${theme.color.background.light};
        color: ${theme.color.text.disabled2};
      }
    `}
`

const Input = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  height: 10px;
  width: 10px;
  margin: -1px;
  padding: 0;
  border: 0;
  vertical-align: middle;
  margin-top: 0;
  opacity: 0;

  &:focus + span::before,
  &:active + span::before {
    outline: ${({ theme }) => `solid 4px ${theme.color.outline.focus}`};
    color: ${({ theme }) => theme.color.accent.default};
    outline-offset: -3px;
  }
`

const LabelTypo = styled(MfTypo)`
  padding-left: 32px;
  line-height: ${({ $consent }) => ($consent ? "1.5" : "1.31")};

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      color: ${theme.color.text.disabled2};
      cursor: default;
    `}

  & p {
    font-size: 15px;
  }

  & a {
    font-size: 15px;
  }
`

export const MfCheckbox = forwardRef(
  (
    {
      name,
      label,
      onChange,
      value = false,
      disabled = false,
      consent = false,
      error = false,
      ...props
    },
    ref
  ) => {
    // We create a function that handles the default checking and unchecking of a boolean value
    // based on the previous value. This only happens when the value is a boolean. If another kind
    // of value is provided we return the event unchanged.
    const handleChange = useCallback(
      (changeEvent) => {
        if (value === true) {
          // We create a new object from the event object as to not modify the event object itself which is not
          // meant to be mutated. The only thing we change is the event.target.value to be what we need
          // With this approach we can handle checkboxes with the same reasoning as text fields
          onChange({
            ...changeEvent,
            target: {
              ...changeEvent.target,
              value: false,
            },
          })
        } else if (value === false) {
          onChange({
            ...changeEvent,
            target: {
              ...changeEvent.target,
              value: true,
            },
          })
        } else {
          onChange(changeEvent)
        }
      },
      [onChange, value]
    )

    return (
      <LabelContainer htmlFor={name}>
        <Input
          {...props}
          name={name}
          id={name}
          value={value}
          onChange={handleChange}
          type="checkbox"
          disabled={disabled}
          ref={ref}
        />
        <CheckboxStyled
          $checked={value === true}
          $disabled={disabled}
          $error={error}
          $value={value}
        />
        <LabelTypo
          variant={consent ? "bodytext2" : "bodytext1"}
          weight={consent ? "normal" : "semibold"}
          $consent={consent}
          $disabled={disabled}
        >
          {label}
        </LabelTypo>
      </LabelContainer>
    )
  }
)

MfCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  consent: PropTypes.bool,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

MfCheckbox.displayName = "MfCheckbox"
