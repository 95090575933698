import { useState, useCallback, useEffect, useMemo } from "react"
import concatClassNames from "./concatClassNames"

import createFormatterRaw from "./stringFormatter"
import * as numberFunctions from "./numberFormatter"

export const createNumberFormatter = numberFunctions.createNumberFormatter
export const createNumberMask = numberFunctions.createNumberMask
export const createFormatter = createFormatterRaw

// useInput is a hook that takes care of the common logic that all input types need
// it handles validation, value, dirty and touched state and gives you the props necessary for the actual input component
export default function useInput(
  name,
  {
    // The initial value for the input
    initial = "",
    // validation returns an object with values of an error message if the input is invalid, false otherwise
    validation = () => undefined,
    // A mask function takes the next value and returns a value
    mask = () => true,
    // A text format to be applied to the value
    format = undefined,
    // A formatter object. This object should contain a format and unformat function.
    // The stringFormatter helper function can create such objects based on a formatting string,
    // but it would also be possible to create a custom formatter object
    formatter = undefined,
    // saveRaw allows to save the unformatted value. Only works when using formatting functionality
    saveRaw = false,
    // A function that takes an initial value and returns a value and a update function (like useState)
    updater = useState,
    // If errorOnTouched is true, the error will be suppressed until the field is touched
    errorOnTouched = false,
    // Add custom behavior for the onBlur handler
    onBlur = undefined,
  } = {}
) {
  // Setup the state variables we need
  const [value, setValue] = updater(initial)
  const [touched, setTouched] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(validation(value))
  }, [])

  // A check to make sure the formatter is defined correct
  useEffect(() => {
    if (
      formatter &&
      (typeof formatter.format !== "function" || typeof formatter.unformat !== "function")
    ) {
      throw new Error(
        `useInput hook: formatter object is not defined correctly. Object with format and unformat functions expected.`
      )
    }
  }, [formatter])

  const formatterObject = useMemo(
    () =>
      formatter ? formatter : typeof format === "string" ? createFormatterRaw(format) : undefined,
    [format, formatter]
  )

  // This is the event handler we create for the change event of the element input field.
  const handleChange = useCallback(
    (changeEvent) => {
      let newValue = changeEvent.target.value
      let newValueRaw

      // If there is a formatter defined we unformat the value before running the mask
      // The format could contain characters that are not allowed by the mask
      if (formatterObject) {
        newValueRaw = formatterObject.unformat(newValue)
      }

      // If the value didn't change or
      // the mask doesn't pass, we don't do anything
      if (newValue === value || !mask(newValueRaw || newValue)) return

      // Once the client enters anything we set the dirty state to true
      if (!dirty) {
        setDirty(true)
      }

      // If there is a formatter defined we format the value after running the mask
      if (formatterObject) {
        if (saveRaw) {
          newValue = newValueRaw
        } else {
          newValue = formatterObject.format(newValueRaw, { eager: newValue.length > value.length })
        }
      }

      // We update the valid state
      setError(validation(newValue))

      // And we update the value state
      setValue(newValue)
    },
    [dirty, mask, validation, value, formatterObject]
  )

  // This the the event handler we create for the blur event of the element input field.
  const handleBlur = useCallback(
    (event) => {
      setTouched(true)

      if (typeof onBlur === "function") {
        onBlur(event)
      }
    },
    [onBlur, setTouched]
  )

  // The reset method will clear the input field and put the states back to their default value
  const reset = useCallback(() => {
    setValue(undefined)
    setTouched(false)
    setDirty(false)
    setError(validation(""))
  }, [validation])

  // The trigger method will mark the input field as touched and dirty. This will trigger any possible
  // errors to be shown
  const trigger = useCallback(() => {
    setTouched(true)
    setDirty(true)
  }, [setTouched, setDirty])

  const getClassName = useCallback(
    (className) => {
      if (validation) {
        return concatClassNames(
          className,
          errorOnTouched ? (error && touched ? "error" : "valid") : error ? "error" : "valid",
          touched ? "touched" : "untouched",
          dirty ? "dirty" : "pristine"
        )
      }

      return className
    },
    [error, touched, dirty, validation]
  )

  // The return value is the value which is returned from the hook
  // If the saveRaw option is used, the value is formatted before returning,
  // otherwise the return value is just the value
  const returnValue = useMemo(
    () => (saveRaw ? formatterObject.format(value, { eager: false }) : value),
    [value, formatterObject]
  )

  const inputProps = useCallback(
    (userProps = {}) => {
      const props = {
        ...userProps,
        name,
        id: name,
        value: returnValue,
        className: getClassName(userProps && userProps.className),
        onChange: handleChange,
        onBlur: handleBlur,
      }

      return props
    },
    [value, name, handleChange, handleBlur, getClassName]
  )

  return {
    inputProps,
    value: returnValue,
    error: errorOnTouched ? (error && touched ? error : undefined) : error,
    touched,
    dirty,
    reset,
    trigger,
  }
}
