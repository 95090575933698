import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const RowWrapper = styled.div`
  width: auto;
  flex: 1;
  margin: 0 16px;
  align-self: auto;

  ${({ theme }) => theme.media.phablet`
    margin: 0 auto;
    width: 343px;
  `}

  ${({ theme, amountOfColumns }) => theme.media.tablet`
    width: ${amountOfColumns === 2 ? "672px" : "498px"};
  `}

  ${({ theme, amountOfColumns }) => theme.media.desktop`
    align-self: center;
    width: ${amountOfColumns === 2 || amountOfColumns === 3 ? "672px" : "498px"};
    margin: 0 ${amountOfColumns === 2 || amountOfColumns === 3 ? "64px" : "87px"};
  `}
`

const RowStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: auto;
  margin: 0 -12px;

  ${({ theme, amountOfColumns }) => theme.media.desktop`
    ${amountOfColumns === 3 ? "margin: 0 -8px;" : ""}
  `}

  & > * {
    flex: 1;
    min-width: calc(100% - 24px);
    margin-right: 12px;
    margin-left: 12px;
  }

  ${({ theme, amountOfColumns }) => theme.media.tablet`
    & > * {
      ${amountOfColumns === 2 ? "min-width: 324px;" : ""}
    }
  `}

  ${({ theme, amountOfColumns }) => theme.media.desktop`
    & > * {
      ${amountOfColumns === 3 ? "min-width: 208px;" : ""}
      ${amountOfColumns === 3 ? "margin-right: 8px;" : ""}
      ${amountOfColumns === 3 ? "margin-left: 8px;" : ""}
    }
  `}
`

export default function MfRow({ children, ...extraProps }) {
  const amountOfColumns = useMemo(() => children.length || 1, [children])

  return (
    <RowWrapper amountOfColumns={amountOfColumns} {...extraProps}>
      <RowStyles amountOfColumns={amountOfColumns}>{children}</RowStyles>
    </RowWrapper>
  )
}

MfRow.propTypes = {
  children: PropTypes.any,
}
