import moment from "moment"

export function createMonthDaysArray(date, weekDayStart) {
  const monthDate = moment(date).date(1)

  const amountOfDaysInMonth = monthDate.daysInMonth()
  const firstWeekDayIndex = monthDate.day()
  const weekStart = firstWeekDayIndex - weekDayStart < 0 ? weekDayStart - 7 : weekDayStart

  const previousMonthDate = moment(monthDate).subtract(1, "month")
  const amountOfDaysInPreviousMonth = previousMonthDate.daysInMonth()
  const previousMonthDays = Array.from({ length: firstWeekDayIndex - weekStart }, (_, i) =>
    moment(previousMonthDate).date(
      amountOfDaysInPreviousMonth - (firstWeekDayIndex - weekStart) + i + 1
    )
  )

  const nextMonthDate = moment(monthDate).add(1, "month")

  return Array.apply(0, { length: amountOfDaysInMonth }).reduce((acc, _, index) => {
    const week = Math.floor((firstWeekDayIndex - weekStart + index) / 7)
    const data = moment(monthDate).date(index + 1)

    if (index === 0) acc[week] = previousMonthDays
    acc[week] = (acc[week] || []).concat([data])

    if (index + 1 === amountOfDaysInMonth) {
      const nextMonthDays = Array.from(
        { length: 6 - ((firstWeekDayIndex - weekStart + index) % 7) },
        (_, i) => moment(nextMonthDate).date(i + 1)
      )
      acc[week] = acc[week].concat(nextMonthDays)
    }

    return acc
  }, [])
}

export function createLocaleWeekDaysArray(locale, weekDayStart) {
  if (!locale || typeof locale !== "string")
    return new Error(
      "[DateHelper.createLocaleWeekDaysArray]: Please provide a locale string to createLocaleWeekDaysArray second argument"
    )

  moment.locale(locale)
  const weekDays = moment.weekdaysShort()
  if (weekDayStart === 0) return weekDays
  return [...weekDays.slice(weekDayStart), ...weekDays.slice(0, weekDayStart)]
}

export function createLocaleMonthsArray(locale) {
  moment.locale(locale)
  const monthNames = moment.months()
  return Array.apply(0, { length: 4 }).map((_, index) => monthNames.slice(index * 3, index * 3 + 3))
}

export function dateStringFromTo(date, fromFormat, toFormat = "YYYY-MM-DD", noPadding) {
  const nativeDateString = parseToNativeDateString(date, fromFormat)
  if (toFormat === "YYYY-MM-DD") return nativeDateString

  const nativeDateSections = nativeDateString.split("-")

  let formattedDate = ""

  toFormat.split(/([^YMD]+)/).forEach((section) => {
    const resultSection = section.match(/^[YMD]/)
    if (!resultSection) {
      formattedDate += section
      return
    }

    let valueSection = ""
    switch (resultSection[0]) {
      case "Y":
        valueSection = nativeDateSections[0]
        break
      case "M":
        valueSection = nativeDateSections[1]
        break
      case "D":
        valueSection = nativeDateSections[2]
        break
    }

    valueSection = noPadding
      ? String(valueSection)
      : String(valueSection).padStart(section.length, "0")
    formattedDate += valueSection
  })

  return formattedDate
}

function parseToNativeDateString(value, fromFormat) {
  let parsedDate = ["", "", ""]

  let startIndexOffset = 0

  fromFormat.split(/([^YMD]+)/).forEach((section) => {
    if (!section.match(/[YMD]+/)) return

    const startIndex = fromFormat.indexOf(section)
    const foundValue = value.substring(startIndex + startIndexOffset).match(/[0-9]*/)[0]

    switch (section.match(/[YMD]/)[0]) {
      case "Y": {
        startIndexOffset += foundValue.length - 4
        const indexTruncateZeros = foundValue.length > 4 ? foundValue.length - 4 : 0
        parsedDate[0] = foundValue.substring(indexTruncateZeros).padStart(4, "0")
        break
      }
      case "M": {
        startIndexOffset += foundValue.length - 2
        const indexTruncateZeros = foundValue.length > 2 ? foundValue.length - 2 : 0
        parsedDate[1] = foundValue.substring(indexTruncateZeros).padStart(2, "0")
        break
      }
      case "D": {
        startIndexOffset += foundValue.length - 2
        const indexTruncateZeros = foundValue.length > 2 ? foundValue.length - 2 : 0
        parsedDate[2] = foundValue.substring(indexTruncateZeros).padStart(2, "0")
        break
      }
    }
  })

  if (fromFormat === "MM/YYYY") {
    return parsedDate[1] + "/" + parsedDate[0]
  } else if (fromFormat === "YYYY") {
    return parsedDate[0]
  }
  return parsedDate.join("-")
}

export function localeToDatePlaceholder(locale) {
  switch (locale) {
    case "fr-BE":
      return "jj/mm/aaaa"
    case "nl-BE":
      return "dd/mm/jjjj"
    default:
      return "dd/mm/yyyy"
  }
}
export function localeToMonthPlaceholder(locale) {
  switch (locale) {
    case "fr-BE":
      return "mm/aaaa"
    case "nl-BE":
      return "mm/jjjj"
    default:
      return "mm/yyyy"
  }
}
