import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfButtonZone } from "../../organisms/ButtonZone"

import Row from "./Row"
export const MfRow = Row

const ContentStyles = styled.div`
  width: auto;
  min-height: 100vh;

  ${({ theme }) => theme.media.desktop`
    display: table;
    margin: 0 auto;
    overflow: hidden;
    min-height: 428px;
  `}
`

const FlexWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: ${({ $offset }) => ($offset ? $offset + "px" : "0")};

  ${({ theme }) => theme.media.phablet`
    padding-bottom: 0;
  `}
`

const ButtonZoneStyles = styled(MfButtonZone)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  width: 100%;
  background: white;
  border-top: 1px solid ${({ theme }) => theme.color.border.lighter};
  z-index: 10;

  ${({ theme }) => theme.media.phablet`
    border-top: none;
    position: static;
    padding: 0;
    margin: 40px auto;
    width: 343px;
  `}

  ${({ theme }) => theme.media.tablet`
    width: 498px;
  `}

  ${({ theme }) => theme.media.desktop`
    margin: 40px auto 80px;
  `}
`

export function MfContent({ children, actions, header, ...extraProps }) {
  const actionsRef = useRef()
  const offsetRef = useRef()
  const [offset, setOffset] = useState("")

  // This is used to set the margin-bottom on the content when the actions are floating
  const onResize = useCallback(() => {
    const currentHeight = actionsRef.current?.offsetHeight.toString()
    if (window.innerWidth < 450 && offsetRef.current != currentHeight) {
      setOffset(currentHeight)
      offsetRef.current = currentHeight
    }
  }, [offset])

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)

    return () => window.removeEventListener("resize", onResize)
  }, [])
  return (
    <ContentStyles {...extraProps}>
      {header}
      <FlexWrapper $offset={offset}>{children}</FlexWrapper>
      {!!actions && <ButtonZoneStyles innerRef={actionsRef}>{actions}</ButtonZoneStyles>}
    </ContentStyles>
  )
}

MfContent.propTypes = {
  children: PropTypes.any.isRequired,
  actions: PropTypes.any,
  header: PropTypes.any,
}

MfContent.defaultProps = {
  actions: undefined,
  header: undefined,
}
