import React, { useState, useCallback, useRef, useEffect, useMemo } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import moment from "moment"

import { MfShow } from "../../atoms/ShowContent"
import { MfSpacer } from "../../atoms/Spacer"
import { MfButtonZone } from "../ButtonZone"
import { MfButton } from "../../atoms/Button"

import { Calendar } from "./Calendar/Calendar"
import { DateInputField } from "./Input/DateInputField"
import { Dropdown } from "./Dropdown"

const ComponentStyle = styled.div`
  position: relative;
`

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

const InputFullSize = styled.div`
  min-width: 160px;
  flex: 1;
`

const CalendarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 2rem;
`

const CalendarActions = styled(MfButtonZone)`
  border-top: 1px solid ${({ theme }) => theme.color.border.lighter};
  padding: 1rem 1.5rem;
`

const today = moment()
const todayString = today.format("YYYY-MM-DD")
const offsetStartSecondCalendar = { month: 1 }
const calculatePickerPlacement = (size) => (size > 570 ? "center" : "left")

export function MfRangePicker({
  label,
  name,
  value,
  locale,
  validation,
  onApply,
  onReset,
  min,
  max,
  minRange,
  maxRange,
  error,
  additionalInfo,
  icon,
  onChange,
  onPickerDisplay,
  disabled,
  resetLabel,
  applyLabel,
  disabledWeekDays,
  disableDates,
  ...props
}) {
  const componentRef = useRef()

  const [openPicker, setOpenPicker] = useState(false)
  const [startDate, setStartDate] = useState(value[0] || value[1] || todayString)
  const [selectionIndex, setSelectionIndex] = useState(0)
  const [pickerPlacement, setPickerPlacement] = useState("center")
  const [validationError, setValidationError] = useState(error)

  const [inputDate, _setInputDate] = useState(value)

  useEffect(() => {
    if (error && error.length) setValidationError((prev) => error.map((e, i) => e || prev[i]))
  }, [error])

  useEffect(() => {
    _setInputDate(value)
  }, [value])

  useEffect(() => {
    if (onPickerDisplay) onPickerDisplay(openPicker)
  }, [openPicker])

  const setInputDate = useCallback(
    (data, index) => {
      let newData = [...data]
      if (typeof index === "number") {
        newData = [...inputDate]
        newData[index] = data
      }
      _setInputDate(newData)
      onChange && onChange(newData)
    },
    [onChange, inputDate, _setInputDate]
  )

  const handleInputChangeFrom = useCallback((data) => setInputDate(data, 0), [setInputDate])
  const handleInputChangeTo = useCallback((data) => setInputDate(data, 1), [setInputDate])

  const handleDayPicked = useCallback(
    (data) => {
      setInputDate(data, selectionIndex)

      const pickerErrors = handleValidation(selectionIndex)(data)
      setValidationError((prev) => {
        const newErrors = [...prev]
        newErrors[selectionIndex] = pickerErrors
        return newErrors
      })

      if (selectionIndex === 0 && !pickerErrors) setSelectionIndex(1)
    },
    [selectionIndex, setInputDate, handleValidation]
  )

  const handleValidation = useCallback((index) => (data) => validation(data, index), [validation])

  const handleInputFocus = useCallback((state) => {
    state && setOpenPicker(false)
  }, [])

  const [minDynamic, showMinSelected] = useMemo(() => {
    if (!inputDate) return [min]
    switch (selectionIndex) {
      case 1: {
        if (!inputDate[0]) return [min]
        if (typeof minRange !== "number" || minRange <= 2) return [inputDate[0]]

        const momentDate = moment(inputDate[0]).add(minRange - 1, "days")
        return [momentDate.format("YYYY-MM-DD"), true]
      }
      case 0: {
        if (!inputDate[1] || typeof maxRange !== "number") return [min]
        const momentDate = moment(inputDate[1]).subtract(Math.max(maxRange - 1, 1), "days")
        if (min && momentDate.isBefore(min, "day")) return [min]
        return [momentDate.format("YYYY-MM-DD")]
      }
    }
    return [min]
  }, [min, minRange, maxRange, selectionIndex, inputDate])

  const [maxDynamic, showMaxSelected] = useMemo(() => {
    if (!inputDate) return [max]
    switch (selectionIndex) {
      case 0: {
        if (!inputDate[1]) return [max]
        if (typeof minRange !== "number" || minRange <= 2) return [inputDate[1]]

        const momentDate = moment(inputDate[1]).subtract(minRange - 1, "days")
        return [momentDate.format("YYYY-MM-DD"), !validationError[0]]
      }
      case 1: {
        if (!inputDate[0] || typeof maxRange !== "number") return [max]
        const momentDate = moment(inputDate[0]).add(Math.max(maxRange - 1, 1), "days")
        if (max && momentDate.isAfter(max, "day")) return [max]
        return [momentDate.format("YYYY-MM-DD")]
      }
    }
    return [max]
  }, [max, minRange, maxRange, selectionIndex, inputDate])

  const handleOpenPicker = (index) => () => {
    setOpenPicker((prev) => !prev)
    setSelectionIndex(index)

    const inputDateMoment = moment(inputDate[index])
    if (inputDateMoment.isValid()) return setStartDate(inputDate[index])
    if ((index === 0 && minDynamic) || (index === 1 && maxDynamic))
      return setStartDate(index ? maxDynamic : minDynamic)
    setStartDate(todayString)
  }

  useEffect(() => {
    const eventHandler = (e) => {
      if (!componentRef.current.contains(e.target) || !document.hasFocus()) {
        setOpenPicker(false)
      }
    }

    window.addEventListener("click", eventHandler)
    window.addEventListener("focusin", eventHandler)
    return () => {
      window.removeEventListener("click", eventHandler)
      window.removeEventListener("focusin", eventHandler)
    }
  }, [])

  useEffect(() => {
    if (!componentRef.current) return

    // trigger it on first render
    setPickerPlacement(calculatePickerPlacement(componentRef.current.clientWidth))

    const eventHandler = () => {
      setPickerPlacement(calculatePickerPlacement(componentRef.current.clientWidth))
    }

    window.addEventListener("resize", eventHandler)
    return () => {
      window.removeEventListener("resize", eventHandler)
    }
  }, [])

  return (
    <ComponentStyle ref={componentRef}>
      <InputWrapper>
        <InputFullSize>
          <DateInputField
            {...props}
            label={label[0]}
            name={name[0]}
            value={inputDate[0]}
            error={validationError[0]}
            additionalInfo={additionalInfo[0]}
            active={openPicker && selectionIndex === 0 && !validationError[0]}
            disabled={disabled}
            icon={icon}
            limits={{ min, max }}
            onFocusChange={handleInputFocus}
            onChange={handleInputChangeFrom}
            validation={handleValidation(0)}
            onIconClick={handleOpenPicker(0)}
            locale={locale}
          />
        </InputFullSize>
        <InputFullSize>
          <DateInputField
            {...props}
            label={label[1]}
            name={name[1]}
            value={inputDate[1]}
            error={validationError[1]}
            additionalInfo={additionalInfo[1]}
            active={openPicker && selectionIndex === 1 && !validationError[1]}
            disabled={disabled}
            icon={icon}
            limits={{ min, max }}
            onFocusChange={handleInputFocus}
            onChange={handleInputChangeTo}
            validation={handleValidation(1)}
            onIconClick={handleOpenPicker(1)}
            locale={locale}
          />
        </InputFullSize>
      </InputWrapper>
      <MfShow from="tablet">
        <Dropdown open={openPicker} placement={pickerPlacement} marginBottom="13px">
          <CalendarsWrapper>
            <Calendar
              locale={locale}
              start={startDate}
              weekStart={1}
              selected={inputDate}
              limits={{ min: minDynamic, max: maxDynamic }}
              limitsSelected={{ min: showMinSelected, max: showMaxSelected }}
              onSelect={handleDayPicked}
              onNavigate={setStartDate}
              view="day"
              hideRightArrow
              disabledWeekDays={disabledWeekDays}
              disableDates={disableDates}
            />
            <MfSpacer size="3" horizontal />
            <Calendar
              locale={locale}
              start={startDate}
              offsetStart={offsetStartSecondCalendar}
              weekStart={1}
              selected={inputDate}
              limits={{ min: minDynamic, max: maxDynamic }}
              limitsSelected={{ min: showMinSelected, max: showMaxSelected }}
              onSelect={handleDayPicked}
              onNavigate={setStartDate}
              view="day"
              hideLeftArrow
              disabledWeekDays={disabledWeekDays}
              disableDates={disableDates}
            />
          </CalendarsWrapper>
          <CalendarActions>
            <MfButton
              secondary
              onClick={() => {
                onReset ? onReset() : setInputDate([])
              }}
            >
              {resetLabel}
            </MfButton>
            <MfButton
              onClick={() => {
                onApply()
                setOpenPicker(false)
              }}
            >
              {applyLabel}
            </MfButton>
          </CalendarActions>
        </Dropdown>
      </MfShow>
    </ComponentStyle>
  )
}

MfRangePicker.propTypes = {
  label: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.arrayOf(PropTypes.string),
  additionalInfo: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  errorOnTouched: PropTypes.bool,
  onChange: PropTypes.func,
  onPickerDisplay: PropTypes.func,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  validation: PropTypes.func,
  min: PropTypes.string,
  max: PropTypes.string,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  resetLabel: PropTypes.string,
  applyLabel: PropTypes.string,
  locale: PropTypes.string.isRequired,
  disabledWeekDays: PropTypes.arrayOf(PropTypes.number),
  disableDates: PropTypes.arrayOf(PropTypes.string),
}

MfRangePicker.defaultProps = {
  value: ["", ""],
  error: [],
  additionalInfo: [],
  icon: undefined,
  disabled: false,
  errorOnTouched: undefined,
  onChange: undefined,
  onPickerDisplay: undefined,
  onApply: () => {},
  onReset: undefined,
  validation: () => {},
  min: undefined,
  max: undefined,
  minRange: undefined,
  maxRange: undefined,
  resetLabel: "Reset",
  applyLabel: "Apply",
  locale: "fr-BE",
  disabledWeekDays: undefined,
  disableDates: undefined,
}
