import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfFontIcon } from "../FontIcon"

import {
  bodyText1SemiBold,
  bodyText2SemiBold,
  bodyText1,
  bodyText2,
  bodyText3,
} from "../../style/css"

const ListStyles = styled.ul`
  ${({ $size, $type, $labelStyle }) =>
    $labelStyle !== undefined
      ? $labelStyle
      : $type === "bullet"
      ? $size === "small"
        ? bodyText2
        : bodyText1
      : $type === "bullet2"
      ? $size === "small"
        ? bodyText3
        : bodyText2
      : $type === "number"
      ? $size === "small"
        ? bodyText2SemiBold
        : bodyText1SemiBold
      : $size === "small"
      ? bodyText2
      : bodyText1SemiBold}

  padding-left: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin: ${({ $size }) => ($size === "large" ? "4px 0" : "2px 0")};
  }
`

const ItemContent = styled.span`
  padding-left: 8px;
  color: ${({ $type, theme }) =>
    $type === "cross" ? theme.color.text.lighter : theme.color.text.darker};
`

const ItemStylePosition = styled.div`
  align-self: flex-start;
`

const BulletStyles = styled.div`
  width: 24px;
  height: 24px;
  padding: 8px;

  > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.accent.default};
  }
`

const BulletStyles2 = styled.div`
  width: 18px;
  height: 18px;
  padding: 7px;

  > div {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.text.darker};
  }
`

const NumberStyles = styled.div`
  ${({ $size }) => ($size === "large" ? bodyText1SemiBold : bodyText2SemiBold)}

  line-height: 1.3;
  width: 22px;
  height: 22px;
  margin: 3px 2px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: regular, sans-serif;
  border: solid 2px ${({ theme }) => theme.color.accent.default};
  color: ${({ theme }) => theme.color.accent.default};
`

const Check = () => <MfFontIcon icon="fonticons-check" color="accent:default" />
const Cross = () => <MfFontIcon icon="fonticons-cross" color="status:error" />
const ArrowUp = () => <MfFontIcon icon="fonticons-arrow-up" color="accent:default" />
const ArrowDown = () => <MfFontIcon icon="fonticons-arrow-down" color="#f2a122" />
const Plus = () => <MfFontIcon icon="fonticons-plus" color="accent:default" />
const Bullet = () => (
  <BulletStyles>
    <div />
  </BulletStyles>
)
const Bullet2 = () => (
  <BulletStyles2>
    <div />
  </BulletStyles2>
)
// eslint-disable-next-line react/prop-types
const Number = ({ index }) => <NumberStyles>{index}</NumberStyles>

const ListStyleTypes = {
  cross: Cross,
  check: Check,
  bullet: Bullet,
  bullet2: Bullet2,
  number: Number,
  arrowup: ArrowUp,
  arrowdown: ArrowDown,
  plus: Plus,
}

export function MfList({ size = "large", type, labelStyle, children, ...props }) {
  if (type === "number") {
    props.as = "ol"
  }

  return (
    <ListStyles $size={size} $type={type} $labelStyle={labelStyle} {...props}>
      {React.Children.map(children, (child, i) => {
        const ItemStyle = ListStyleTypes[child.props.type || type] || null

        return (
          <li>
            <ItemStylePosition>
              <ItemStyle index={i + 1} />
            </ItemStylePosition>
            <ItemContent $type={type} {...child.props}>
              {child.props.children}
            </ItemContent>
          </li>
        )
      })}
    </ListStyles>
  )
}

MfList.propTypes = {
  type: PropTypes.oneOf([
    "bullet",
    "bullet2",
    "check",
    "cross",
    "number",
    "arrowup",
    "arrowdown",
    "plus",
  ]).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(["small", "large"])]),
  children: PropTypes.any.isRequired,
  labelStyle: PropTypes.any,
}
