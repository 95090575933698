import { css } from "styled-components"

export const bodyText2 = css`
  font-size: ${({ theme }) => theme.font.size.body2};
  line-height: 1.36;
`

export const bodyText2SemiBold = css`
  ${bodyText2}

  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`

export const bodyText2Bold = css`
  ${bodyText2}

  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
