import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MfShow } from "../../ShowContent"

import { bodyText1, bodyText2 } from "../../../style/css"

import { BaseOption } from "../BaseOption"

const CenteredContainer = styled.div`
  ${({ $showImageInSmall }) =>
    $showImageInSmall &&
    `
    text-align: center;
    flex: 1;`}
  ${({ theme }) => theme.media.tablet`
    text-align: center;
    flex: 1;
  `}
  img {
    margin: 4px 0 8px;
  }
`

const NameStyles = styled.div`
  ${bodyText1}

  font-weight: 500;

  ${({ theme }) => theme.media.tablet`
    font-family: regular;
    font-weight: 300;
  `}
`

const SubLabel = styled.div`
  ${bodyText2}

  line-height: 1.1;
  font-weight: normal;
  color: ${({ theme }) => theme.color.text.lighter};
`

function WrappedImageOption({ children, label, subLabel, innerRef, showImageInSmall, ...props }) {
  return (
    <BaseOption
      {...props}
      ref={innerRef}
      label={
        <CenteredContainer $showImageInSmall={showImageInSmall}>
          {showImageInSmall ? children : <MfShow from="tablet">{children}</MfShow>}

          <NameStyles>{label}</NameStyles>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
        </CenteredContainer>
      }
    />
  )
}

// We need to wrap the Option in a forwardRef call to make sure the BaseOption can put it on the Container
export const ImageOption = React.forwardRef((props, ref) => (
  <WrappedImageOption innerRef={ref} {...props} />
))
ImageOption.displayName = "ImageOption"

WrappedImageOption.propTypes = {
  children: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  innerRef: PropTypes.any,
  showImageInSmall: PropTypes.bool,
}

WrappedImageOption.defaultProps = {
  subLabel: undefined,
  innerRef: undefined,
  showImageInSmall: false,
}
