import { useRef, useEffect } from "react"
import { dateStringFromTo } from "../../../helpers/dateHelper"

export function toDisplayFormat(date, forceKeep, monthPicker) {
  if (!date || date === "" || (date === "0000-00-00" && !forceKeep)) return ""
  if (monthPicker) return dateStringFromTo(date, "MM/YYYY")
  return dateStringFromTo(date, "YYYY-MM-DD", "DD/MM/YYYY")
}

export function toRawFormat(previousDate, currentDate, monthPicker) {
  if (!currentDate || currentDate === "") return ""
  if (monthPicker) return dateStringFromTo(currentDate, "MM/YYYY")
  return dateStringFromTo(currentDate, "DD/MM/YYYY")
}

export function calculateAddedCharacters(previousDate, currentDate) {
  const previousValueLength = previousDate ? previousDate.length : 0
  const currentValueLength = currentDate ? currentDate.length : 0

  return currentValueLength - previousValueLength
}

export function validateNewInput(currentDate) {
  return /^([0-9/]*|[0-9])$/.test(currentDate.replaceAll("/", ""))
}

export function usePrevious(initalState) {
  const ref = useRef()

  useEffect(() => {
    ref.current = initalState
  }, [])
  return ref
}
