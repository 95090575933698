import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfButton } from "../../atoms/Button"
import { MfFontIcon } from "../../atoms/FontIcon"
import { MfShow } from "../../atoms/ShowContent"

const BackButtonSmall = styled(MfButton).attrs({
  text: true,
  color: "white",
})`
  padding: ${({ theme }) => theme.spacing(3)};
`

export function BackButton({ children, ...props }) {
  return (
    <>
      <MfShow from="desktop">
        <MfButton text {...props}>
          <MfFontIcon icon="fonticons-chevron-left" />
          {children}
        </MfButton>
      </MfShow>
      <MfShow until="desktop">
        <BackButtonSmall {...props}>
          <MfFontIcon icon="fonticons-arrow-back" />
        </BackButtonSmall>
      </MfShow>
    </>
  )
}

BackButton.propTypes = {
  children: PropTypes.any,
}

BackButton.defaultProps = {
  children: "Back",
}
