import React, { useMemo, isValidElement } from "react"
import PropTypes from "prop-types"
import { useMergeRefs, FloatingPortal, FloatingFocusManager } from "@floating-ui/react"

import { elementOrRefObject } from "../../helpers/refType"
import { MfPopup } from "../../organisms/Popup"
import { useWidget } from "./context"

export const WidgetContent = React.forwardRef(
  ({ children, trapFocus, targetNodeId, width }, propRef) => {
    const widget = useWidget()
    const ref = useMergeRefs([widget.refs.setFloating, propRef])

    const focusOptions = useMemo(
      () => (trapFocus && typeof trapFocus !== "boolean" ? trapFocus : {}),
      [trapFocus]
    )

    const {
      interactions: { getFloatingProps },
      floatingStyles,
      isOpen,
      setIsOpen,
      showBottomSheet,
    } = widget

    const content = useMemo(() => {
      if (isValidElement(children)) {
        if (showBottomSheet) {
          return (
            <MfPopup bottomSheet={true} show={true} onClose={() => setIsOpen(false)}>
              {children}
            </MfPopup>
          )
        } else {
          return (
            <FloatingPortal id={targetNodeId} style={{ position: "relative" }}>
              <FloatingFocusManager
                context={widget.context}
                enabled={!!trapFocus}
                {...focusOptions}
              >
                <div style={{ position: "relative" }}>
                  <div
                    ref={ref}
                    {...getFloatingProps()}
                    style={{ ...floatingStyles, zIndex: 10, width: width }}
                  >
                    {children}
                  </div>
                </div>
              </FloatingFocusManager>
            </FloatingPortal>
          )
        }
      }
    }, [children, getFloatingProps, floatingStyles, showBottomSheet])

    return isOpen && content ? content : null
  }
)

WidgetContent.displayName = "WidgetContent"

WidgetContent.propTypes = {
  children: PropTypes.node,
  targetNodeId: PropTypes.string,
  trapFocus: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.exact({
      order: PropTypes.arrayOf(PropTypes.oneOf(["reference", "floating", "content"])),
      initialFocus: PropTypes.oneOfType([PropTypes.number, elementOrRefObject]),
      returnFocus: PropTypes.bool,
      guards: PropTypes.bool,
      modal: PropTypes.bool,
      visuallyHiddenDismiss: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      closeOnFocusOut: PropTypes.bool,
    }),
  ]),
  width: PropTypes.string,
}

WidgetContent.defaultProps = {
  trapFocus: false,
  targetNodeId: "mfModalRoot",
  width: "auto",
}
