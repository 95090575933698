import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const ShowStyles = styled.div`
  ${({ theme, $from, $until }) =>
    $from
      ? css`
          display: none;

          ${theme.media[$from]`
      display: block;
    `}
        `
      : css`
          display: block;

          ${theme.media[$until]`
        display: none;
      `}
        `}
`

export function MfShow({ from, until, ...props }) {
  return <ShowStyles $from={from} $until={until} {...props} />
}

MfShow.propTypes = {
  from: PropTypes.oneOf(["phone", "phablet", "tablet", "desktop"]),
  until: PropTypes.oneOf(["phone", "phablet", "tablet", "desktop"]),
}
