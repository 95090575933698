import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useSelect } from "downshift"

import { Item, layoutConfig, SelectStyles } from "../SingleSelect"

import { isMobile } from "react-device-detect"
import styled from "styled-components"

const ToggleWrapper = styled.span`
  outline: none;
`

export function MultiSelect({ values, onChange, children, className, layout, ...props }) {
  // selectItem will check if the item is already selected and deselect it if it is the case
  const getSelectItems = useCallback(
    (newValue) => {
      if (values.indexOf(newValue) !== -1) {
        // Remove the item
        return values.filter((item) => item !== newValue)
      }
      // Add the item
      return [...values, newValue]
    },
    [values]
  )

  function stateReducer(state, actionAndChanges) {
    const { type, changes } = actionAndChanges

    // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
    switch (type) {
      case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
      case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ToggleButtonClick: {
        return {
          ...changes, // default Downshift new state changes on item selection.
          selectedItem: getSelectItems(changes.selectedItem),
          isOpen: state.isOpen, // but keep menu open.
          highlightedIndex: state.highlightedIndex, // with the item highlighted.
        }
      }
      case useSelect.stateChangeTypes.MenuMouseLeave:
      case useSelect.stateChangeTypes.ToggleButtonBlur: {
        return { ...state, highlightedIndex: -1 }
      }
      default: {
        return changes // otherwise business as usual.
      }
    }
  }

  const { getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    initialSelectedItem: values,
    items: React.Children.map(children, (child) => child.props.value),
    onSelectedItemChange: ({ selectedItem: newSelectItem }) => onChange(newSelectItem),
    initialIsOpen: false,
    stateReducer,
  })

  const customLayout = {
    ...layoutConfig.default,
    ...layout,
  }

  const isHighlighted = (index, currentValue) => {
    if (isMobile) {
      return values.indexOf(currentValue) !== -1
    }
    return highlightedIndex === index
  }

  return (
    <ToggleWrapper {...getToggleButtonProps({}, { suppressRefError: true })}>
      <SelectStyles className={className} {...props} {...getMenuProps()} layout={customLayout}>
        {React.Children.map(children, (child, index) => (
          <Item layout={customLayout} aria-selected={values.indexOf(child.props.value) !== -1}>
            {React.createElement(child.type, {
              ...getItemProps({
                ...child.props,
                item: child.props.value,
                index,
                highlighted: isHighlighted(index, child.props.value),
                selected: values.indexOf(child.props.value) !== -1,
                multi: true,
                "aria-label": child.props.value,
              }),
            })}
          </Item>
        ))}
      </SelectStyles>
    </ToggleWrapper>
  )
}

MultiSelect.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  layout: layoutConfig.propTypes,
}

MultiSelect.defaultProps = {
  className: undefined,
  layout: layoutConfig.default,
}
