import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider, createGlobalStyle } from "styled-components"

import getAxesInfo from "../helpers/axes"
import getTheme from "../helpers/theme"

import fonts from "./fonts"
import fontIcons from "./fontIcons"

const axes = getAxesInfo()
const theme = getTheme(axes.audience)

/* Inspired by CSS reset from: https://www.joshwcomeau.com/css/custom-css-reset/ */

const GlobalStyles = createGlobalStyle`
  ${fonts}
  ${fontIcons}

  /* TODO - Remove the scrollbar code */
  /* Make adjustments to the scrollbar in webkit browsers to be smaller and more like mobile */
  html {
    /* width */
    *::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    *::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    /* Handle */
    *::-webkit-scrollbar-thumb {
      background: #888; 
    }

    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }


  body {
    color: ${({ theme }) => theme.color.text.darker};
    font-family: ${({ theme }) => theme.font.family.mobile.normal};
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;

    ${({ theme }) => theme.media.tablet`
      font-family: ${({ theme }) => theme.font.family.desktop.normal};
    `}
  }

  /* Improve media defaults */
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  /* Remove built-in form typography styles */
  input, button, textarea, select {
    font: inherit;
    outline: 0;
  }
  
  .no-scroll {
    overflow: hidden;
  }
`

export const MfGlobalProvider = ({ children, targetNodeId }) => {
  return (
    <ThemeProvider theme={theme}>
      <div id={targetNodeId} />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

MfGlobalProvider.propTypes = {
  children: PropTypes.node,
  targetNodeId: PropTypes.string,
}

MfGlobalProvider.defaultProps = {
  targetNodeId: "mfModalRoot",
}
