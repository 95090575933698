import Cookies from "universal-cookie"

// Set some constants and also export them for if they would be needed
export const LANGUAGES = ["en", "fr", "nl", "de"]
export const BRANDS = ["fb", "kn", "hb"]
export const DEFAULT_LANGUAGE = "en"
export const DEFAULT_BRAND = "fb"

// Function to get the info from the axes
export default function getAxesInfo({
  defaultLanguage = DEFAULT_LANGUAGE,
  defaultBrand = DEFAULT_BRAND,
} = {}) {
  const cookies = new Cookies()
  const axes = cookies.get("axes")
  const axesArray = axes ? axes.split("|") : ["en", "PC", "fb", "priv", ""]
  const axesObj = {
    language: axesArray[0],
    territory: axesArray[1],
    audience: axesArray[2],
    distributionChannel: axesArray[3],
    extraParameters: axesArray[4],
  }

  // Handle not supported languages
  if (LANGUAGES.indexOf(axesArray[0]) === -1) {
    axesObj.language = defaultLanguage
  }
  // Handle not supported brands
  if (BRANDS.indexOf(axesArray[2]) === -1) {
    axesObj.audience = defaultBrand
  }

  return axesObj
}
