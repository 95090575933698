import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfCollapsableButton } from "./CollapsableButton"
import { buttonLabel } from "../../style/css"

const ButtonStyles = styled.button`
  ${buttonLabel}

  outline: none;
  border-radius: 8px;
  width: ${({ $force, $text }) => ($force === "large" ? "auto" : !$text ? "100%" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: ${({ $text }) => ($text ? "initial" : "120px")};

  i {
    text-decoration: none;
  }

  ${({ theme, $secondary, $text, $small, $color, $cta, $icon }) => css`
    padding: ${$small ? `${theme.spacing(2)} ${theme.spacing(3)}` : `12px ${theme.spacing(3)}`};
    background-color: ${$color
      ? theme.color[$color]
        ? theme.color[$color].default
        : $color
      : theme.color.primary.default};
    color: ${theme.color.text.white};
    border: none;

    &:hover,
    &:active,
    &:focus {
      background-color: ${$color
        ? theme.color[$color]
          ? theme.color[$color].darker
          : $color
        : theme.color.primary.darker};
      cursor: pointer;
    }

    &:active,
    &:focus {
      outline: solid 4px ${theme.color.outline.focus};
    }

    &:disabled {
      background-color: ${theme.color.background.disabled};
      color: ${theme.color.text.disabled2};
      cursor: auto;
      outline: 0;
    }

    ${$text &&
    css`
      background-color: transparent;
      border-radius: 0;
      color: ${$color
        ? theme.color[$color]
          ? theme.color[$color].default
          : $color
        : theme.color.secondary.default};
      font-weight: ${theme.font.weight.bold};
      padding: 8px 8.5px;
      text-align: inherit;

      &:hover,
      &:active,
      &:focus {
        outline: 0;
        color: ${$color
          ? theme.color[$color]
            ? theme.color[$color].darker
            : $color
          : theme.color.secondary.darker};
        background-color: transparent;
        cursor: pointer;
        ${theme.media.tablet`
            text-decoration: none;
          `}
      }

      &:disabled {
        background-color: transparent;
        color: ${theme.color.text.disabled};
        text-decoration: none;
        cursor: auto;
      }
    `}

    ${$secondary &&
    css`
      background-color: ${theme.color.background.light};
      border: 1px solid;
      border-color: ${$color
        ? theme.color[$color]
          ? theme.color[$color].default
          : $color
        : theme.color.secondary.default};
      color: ${$color
        ? theme.color[$color]
          ? theme.color[$color].default
          : $color
        : theme.color.secondary.default};
      font-weight: ${theme.font.weight.bold};

      &:hover,
      &:active,
      &:focus {
        background-color: ${$color ? theme.color.background.light : theme.color.secondary.lighter4};
        cursor: pointer;
      }

      &:disabled {
        background-color: ${theme.color.background.light};
        color: ${theme.color.text.disabled};
        border-color: ${theme.color.border.disabled};
        cursor: auto;
      }
    `}
    ${$cta &&
    css`
      padding: ${theme.spacing(2)} ${theme.spacing(3)};
      color: ${theme.color.text.darker};
      border: 1px solid ${theme.color.text.darker};
      border-radius: ${theme.spacing(2)};
      font-weight: ${theme.font.weight.bold};
      background-color: rgb(0 0 0 / 0%);
      width: auto;

      &:hover,
      &:active,
      &:focus {
        background-color: rgb(0 0 0 / 8%);
      }
    `}
    ${$icon &&
    css`
      background: transparent;
      min-width: auto;
      padding: unset;
      width: auto;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    `}
    ${theme.media.tablet`
      width: ${({ $force }) => ($force === "small" ? "100%" : "auto")};
      font-weight: normal;
    `}
  `}
`
const StartIconStyled = styled.span`
  margin: ${({ theme }) =>
    `-${theme.spacing(1)} ${theme.spacing(1)} -${theme.spacing(1)} -${theme.spacing(1)}`};
`

const EndIconStyled = styled.span`
  margin: ${({ theme }) =>
    `-${theme.spacing(1)} -${theme.spacing(1)} -${theme.spacing(1)} ${theme.spacing(1)}`};
`

function Button({
  color,
  type,
  secondary,
  text,
  small,
  force,
  cta,
  startIcon,
  endIcon,
  children,
  innerRef,
  icon,
  linkButton,
  ...props
}) {
  return (
    <ButtonStyles
      type={type}
      $color={color}
      $secondary={secondary}
      $text={text}
      $small={small}
      $cta={cta}
      $force={force}
      ref={innerRef}
      $icon={icon}
      as={linkButton ? "a" : "button"}
      {...props}
    >
      {startIcon && <StartIconStyled>{startIcon}</StartIconStyled>}
      {children}
      {endIcon && <EndIconStyled>{endIcon}</EndIconStyled>}
    </ButtonStyles>
  )
}

Button.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  secondary: PropTypes.bool,
  cta: PropTypes.bool,
  text: PropTypes.bool,
  small: PropTypes.bool,
  force: PropTypes.oneOf(["small", "large"]),
  linkButton: PropTypes.bool,
  innerRef: PropTypes.any,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  icon: PropTypes.bool,
}

Button.defaultProps = {
  type: "button",
  color: undefined,
  force: undefined,
  cta: false,
  linkButton: false,
  secondary: false,
  text: false,
  small: false,
}

Button.defaultProps = {
  type: "button",
  color: undefined,
  force: undefined,
  linkButton: false,
  secondary: false,
  text: false,
  small: false,
}

const MfButton = React.forwardRef((props, ref) => <Button innerRef={ref} {...props} />)
MfButton.displayName = "MfButton"

export { Button, MfButton, MfCollapsableButton }
