import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
/* FocusLock traps the focus inside the overlay, making it impossible to focus out of the overlay */
/* Locky helps to prevent taking any other actions, like scrolling outside the overlay */
import FocusLock from "react-focus-lock"
import Locky from "react-locky"

import { MfSpinner } from "../Spinner"

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  backdrop-filter: blur(4px);
  background-color: rgb(0 0 0 / 30%);
  outline: none;

  > div {
    margin: 120px ${({ theme }) => theme.spacing(3)} 0 ${({ theme }) => theme.spacing(3)};
    width: 500px;
  }
`
const Content = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)}
    ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(6)};
`

function Loader({ text, innerRef, overlay = true, ...props }) {
  useEffect(() => {
    if (overlay) {
      window.document.body.classList.add("noScroll")
    }

    return () => window.document.body.classList.remove("noScroll")
  }, [overlay])

  const renderContent = () => (
    <Content>
      <MfSpinner small>{text}</MfSpinner>
    </Content>
  )

  return overlay ? (
    <FocusLock>
      <Locky noDefault events={{ scroll: true, keydown: false }}>
        <Overlay tabIndex="-1" ref={innerRef} {...props}>
          {renderContent()}
        </Overlay>
      </Locky>
    </FocusLock>
  ) : (
    renderContent()
  )
}

Loader.propTypes = {
  text: PropTypes.string.isRequired,
  overlay: PropTypes.bool,
  innerRef: PropTypes.any,
}

Loader.defaultProps = {
  text: "",
}

export const MfLoader = React.forwardRef((props, ref) => <Loader innerRef={ref} {...props} />)
MfLoader.displayName = "MfLoader"
