import { forwardRef, isValidElement, cloneElement } from "react"
import PropTypes from "prop-types"
import { useMergeRefs } from "@floating-ui/react"

import { useWidget } from "./context"

export const WidgetAnchor = forwardRef(({ children, ...props }, propRef) => {
  const widget = useWidget()
  const childrenRef = children.ref
  const ref = useMergeRefs([widget.refs.setReference, propRef, childrenRef])

  if (isValidElement(children)) {
    const elProps = widget.interactions.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      onClick: (e) => e.stopPropagation(),
      "data-state": widget.isOpen ? "open" : "closed",
    })

    return cloneElement(children, elProps)
  }

  return null
})

WidgetAnchor.displayName = "WidgetAnchor"

WidgetAnchor.propTypes = {
  children: PropTypes.node,
}
