import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const FontIconStyles = styled.i`
  display: flex;
  text-decoration: none !important;

  ${({ theme, $color, $size }) => css`
    color: ${theme.color[$color[0]] ? theme.color[$color[0]][$color[1]] || "inherit" : $color[0]};
    font-size: ${$size || "24px"};
    height: 1em;
  `}
`

export function MfFontIcon({ icon = "", color, size, className, ...props }) {
  return (
    <FontIconStyles
      className={`${icon} ${className}`}
      $color={color.split(":")}
      $size={size}
      {...props}
    />
  )
}

MfFontIcon.displayName = "MfFontIcon"

MfFontIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
}

MfFontIcon.defaultProps = {
  color: "inherit",
  className: "",
}
