import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

export const SeparatorStyle = styled.hr`
  align-self: stretch;
  ${({ theme }) => css`
    ${({ $direction }) =>
      $direction === "horizontal" &&
      css`
        border: 1px solid ${theme.color.border.lighter};
        border-bottom: 0;
      `}

    ${({ $direction }) =>
      $direction === "vertical" &&
      css`
        border: 1px solid ${theme.color.border.lighter};
        border-right: 0;
      `}
  `}
`

export const MfSeparator = ({ direction }) => {
  return <SeparatorStyle $direction={direction} />
}

MfSeparator.displayName = "MfSeparator"

MfSeparator.propTypes = {
  direction: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,
}
