import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MfFontIcon } from "../FontIcon"

import { StyledButton } from "./item/SelectableItem"
import { StyledItemContainer } from "./MenuItem"

const CtaContainer = styled(StyledItemContainer)`
  align-items: center;
  border: 0;
  flex-direction: column-reverse;
  gap: 8px;
  justify-content: flex-start;
  padding: 12px 0;

  button {
    &:active,
    &:focus {
      outline: none;
    }
  }

  ${({ theme, $multipleActions }) =>
    $multipleActions &&
    theme.media.tablet`
    &:hover, &:active {
      background: none;
    }

    &:focus-within {
      box-shadow:none;
      background: none;
    }
  `}

  ${({ theme }) => theme.media.tablet`
    flex-direction: row;
    padding: 12px;
  `}
`

export const CtaZone = ({ cta }) => {
  const multipleActions = Array.isArray(cta) && cta.length > 1

  return cta ? (
    <CtaContainer as="div" $multipleActions={multipleActions}>
      {cta}
    </CtaContainer>
  ) : null
}

CtaZone.propTypes = {
  cta: PropTypes.node,
}

/**
 * MenuAction is the component to be used when there is a single Cta action in the ContextualMenu
 */
const CtaButton = styled(StyledButton)`
  color: ${({ theme }) => theme.color.primary.default};
  padding: 16px 12px;
`

export const MenuAction = (props) => {
  return <CtaButton startIcon={<MfFontIcon icon="fonticons-plus" />} {...props} />
}
