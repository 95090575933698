import { useCallback, useState } from "react"
import { createNumberFormatter, createNumberMask } from "./numberFormatter"
import useInput from "./useInput"

export default function useNumberInput(name, options) {
  const { decimals = 0, updater = useState, restOptions, initial, onBlur } = options
  const [value, setValue] = updater(initial || "")

  const handleBlur = useCallback(
    (event) => {
      if (typeof value === "string" && value.length > 0) {
        if (decimals > 0) {
          const [whole, decimal] = value.split(".")

          if (decimal === undefined) {
            setValue(`${whole}.${"".padEnd(decimals, "0")}`)
          } else if (decimal.length < decimals) {
            setValue(`${whole}.${decimal.padEnd(decimals, "0")}`)
          }
        } else {
          const newValue = value.replace(".", "")

          if (newValue !== value) {
            setValue(newValue)
          }
        }
      }

      if (typeof onBlur === "function") {
        onBlur(event)
      }
    },
    [onBlur, value, setValue]
  )

  return useInput(name, {
    ...restOptions,
    saveRaw: true,
    mask: createNumberMask({ decimals }),
    formatter: createNumberFormatter(),
    updater: () => [value, setValue],
    onBlur: handleBlur,
  })
}
