import React, { useCallback } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfTypo } from "../../fundamentals/Typo"
import useDefaultStyle from "../../helpers/useDefaultStyle"

import { MfFontIcon } from "../FontIcon"

const CellContainerStyles = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    .label {
      text-decoration: underline;
    }
  }
`

const IconContainerStyles = styled.div`
  &:first-child {
    padding-right: 8px;
  }

  ${({ theme }) => theme.media.tablet`
    &:first-child {
      padding-right: 16px;
    }
  `}
`

const ActionLabelIconContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.primary.default};
`

const LabelContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;

  > img {
    margin-right: 8px;
  }
`

const TypoLabel = styled(MfTypo)`
  ${({ theme }) => css`
    color: ${theme.color.text.lighter};

    ${theme.media.tablet`
      color: ${theme.color.text.default};
    `}
  `}
`

const DescriptionStyles = (props) => <TypoLabel variant="bodytext2" {...props} />

const ActionLabel = styled(MfTypo)`
  color: ${({ theme }) => theme.color.primary.default};
`
const getSize = (size) => (size === "medium" ? "bodytext1" : "bodytext2")
const getWeight = (weight) => (weight === "medium" ? "semibold" : "normal")

export default function MfInteractiveCell({
  label,
  description,
  icon,
  action,
  size = "medium",
  weight = "medium",
  ...props
}) {
  const LabelStyles = useCallback(
    (props) => <MfTypo variant={getSize(size)} weight={getWeight(weight)} {...props} />,
    [size]
  )
  const labelContent = useDefaultStyle(label, LabelStyles)
  const descriptionContent = useDefaultStyle(description?.content, DescriptionStyles)

  const descriptionBlock =
    description && description.icon ? (
      <DescriptionContainer>
        {description.icon}
        {descriptionContent}
      </DescriptionContainer>
    ) : (
      descriptionContent
    )

  const actionContent =
    action?.label && action.icon ? (
      <ActionLabelIconContainer>
        <IconContainerStyles>{action.icon}</IconContainerStyles>
        <ActionLabel variant={getSize(size)} weight={getWeight(weight)}>
          {action.label}
        </ActionLabel>
      </ActionLabelIconContainer>
    ) : action?.label ? (
      <ActionLabel variant={getSize(size)} weight={getWeight(weight)}>
        {action.label}
      </ActionLabel>
    ) : (
      <MfFontIcon icon="fonticons-chevron-right" color="#00965e" />
    )

  const handleKeyPress = (e) => {
    if ((e.key === " " || e.key === "Enter") && action?.onClick) {
      action.onClick()
    }
  }

  return (
    <CellContainerStyles
      tabIndex={0}
      onClick={action?.onClick}
      onKeyPress={handleKeyPress}
      {...props}
    >
      {icon && <IconContainerStyles>{icon}</IconContainerStyles>}
      <LabelContainer>
        <LabelContainerStyles>
          <span className="label">{labelContent}</span>
          {description && descriptionBlock}
        </LabelContainerStyles>
        {actionContent}
      </LabelContainer>
    </CellContainerStyles>
  )
}

MfInteractiveCell.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.shape({
    content: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      src: PropTypes.string.isRequired,
      altText: PropTypes.string,
    }),
  }),
  action: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    icon: PropTypes.node,
  }).isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
  weight: PropTypes.oneOf(["light", "medium"]),
  icon: PropTypes.node,
}
