import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.color.border.lighter};
  border-radius: 8px;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spaceValues[3]};
  cursor: pointer;
  min-height: 54px;
  transition: box-shadow 0.5s;

  ${({ theme, $selected, $highlighted }) =>
    ($selected || $highlighted) &&
    css`
      background-color: ${theme.color.secondary.lighter4};
      position: relative;
      border-color: ${theme.color.accent.default};
      box-shadow: 1px 1px 0 inset ${theme.color.accent.default},
        -1px -1px 0 inset ${theme.color.accent.default},
        1px -1px 0 inset ${theme.color.accent.default},
        -1px 1px 0 inset ${theme.color.accent.default};
    `}

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      border: 1px solid ${theme.color.border.disabled};
      box-shadow: none;
      background-color: white;
      cursor: not-allowed;

      * {
        color: ${theme.color.text.disabled2} !important;
      }

      img {
        filter: grayscale(1);
      }
    `}

  ${({ theme, $error }) =>
    typeof $error === "object" &&
    $error.length > 0 &&
    css`
      border: 2px solid ${theme.color.status.error};
      box-shadow: none;
      background-color: white;
    `}
`

const ErrorWrapper = styled.div`
  ${({ theme }) =>
    css`
      font-size: 14px;
      line-height: 20px;
      color: ${theme.color.status.error};
      margin: ${theme.spaceValues[1]} ${theme.spaceValues[3]};
    `}
`

function WrappedGenericOption({
  children,
  innerRef,
  selected,
  highlighted,
  disabled,
  error,
  ...props
}) {
  return (
    <>
      <Container
        ref={innerRef}
        $selected={selected}
        $highlighted={highlighted}
        $disabled={disabled}
        $error={error}
        {...props}
      >
        {children}
      </Container>
      {error &&
        error.map((item, index) => {
          return <ErrorWrapper key={`error${index}`}>{item}</ErrorWrapper>
        })}
    </>
  )
}

// We need to wrap the MfGenericOption in a forwardRef call to make sure the ref is put on the Container
export const MfGenericOption = React.forwardRef((props, ref) => (
  <WrappedGenericOption innerRef={ref} {...props} />
))
MfGenericOption.displayName = "MfGenericOption"

WrappedGenericOption.propTypes = {
  children: PropTypes.any,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  innerRef: PropTypes.any,
  error: PropTypes.arrayOf(PropTypes.string),
}

WrappedGenericOption.defaultProps = {
  children: null,
  selected: false,
  highlighted: false,
  disabled: false,
  innerRef: undefined,
  error: [],
}
