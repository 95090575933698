import React, { useState } from "react"
import styled, { css, keyframes } from "styled-components"
import PropTypes from "prop-types"

import { MfCell } from "../../atoms/Cell"
import { MfFontIcon } from "../../atoms/FontIcon"
import { MfButton } from "../../atoms/Button"
import { MfTypo } from "../../fundamentals/Typo"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const RotatingFontIcon = styled(MfFontIcon)`
  animation: ${rotate} 2s linear infinite;
`

const ContainerStyle = styled.div`
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.color.border.lighter};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  overflow: hidden;
  display: block;
  ${({ $single }) => $single && `border: none;`}
`

/*
  Container styles to create an ellipsis on filenames that are too long for the container.
  The extra container is needed to be able to ellipsis inside a flex child. The FileName
  component composes the necessary container styles to handle the ellipsis.
*/
const NameContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-right: 30px;
`

const Ellipsis = styled(MfTypo).attrs({ variant: "bodytext1" })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const UploadedFile = styled.div`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(3)} 0 `};
  cursor: pointer;

  & + div {
    border-top: solid 1px ${({ theme }) => theme.color.border.lighter};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.hover.light};

    ${NameContainer} {
      text-decoration: underline;
    }
  }

  ${({ $single }) =>
    $single &&
    css`
      border: solid 1px ${({ theme }) => theme.color.border.lighter};
      border-radius: 8px;
      overflow: hidden;

      &:hover {
        border-radius: 8px;
        background-color: ${({ theme }) => theme.color.hover.light};
        border-color: ${({ theme }) => theme.color.accent.default};
        box-shadow: 1px 1px 0 inset ${({ theme }) => theme.color.accent.default},
          -1px -1px 0 inset ${({ theme }) => theme.color.accent.default},
          1px -1px 0 inset ${({ theme }) => theme.color.accent.default},
          -1px 1px 0 inset ${({ theme }) => theme.color.accent.default};

        ${NameContainer} {
          text-decoration: underline;
        }
      }
    `}
`
const DeleteButtonPositioning = styled.div`
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
`

const DeleteStyles = styled(MfButton)`
  color: ${({ theme }) => theme.color.text.lighter};
  padding: 0 1rem;

  &:hover {
    color: ${({ theme }) => theme.color.status.error};
  }
`

export function FileList({ files, onDelete, deletePopup: DeletePopup, ...props }) {
  const [showPopup, setShowPopup] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)

  const deleteFile = (event, file) => {
    event.stopPropagation()
    if (!DeletePopup) return deleteFileConfirm(file)
    setShowPopup(true)
    setFileToDelete(file)
  }

  const deleteFileConfirm = (file) => {
    setShowPopup(false)
    onDelete(file)
  }

  const handleFileDownload = (e, file) => {
    e.stopPropagation()
    const url = URL.createObjectURL(file)

    if (file.type.startsWith("image/") || file.type === "application/pdf") {
      window.open(url)
    } else {
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = file.name
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    }
  }

  return (
    <>
      <>
        {files && !!files.length && (
          <ContainerStyle {...props} $single={files.length === 1}>
            {files.map((file) => (
              <UploadedFile
                $single={files.length === 1}
                key={file.name}
                onClick={(e) => handleFileDownload(e, file)}
              >
                {!file.loading && file && file.name ? (
                  <MfCell
                    icon={<MfFontIcon icon="fonticons-check" color="accent:default" />}
                    value={
                      <NameContainer>
                        <Ellipsis>{file.name}</Ellipsis>
                      </NameContainer>
                    }
                    subValue={
                      file.size < 1000
                        ? `${file.size} B`
                        : file.size / 1024 < 1000
                        ? `${(file.size / 1024).toFixed(2)} KB`
                        : `${(file.size / 1024 / 1024).toFixed(2)} MB`
                    }
                  />
                ) : (
                  <MfCell
                    icon={<RotatingFontIcon icon="fonticons-sync" />}
                    value="Uploading file..."
                  />
                )}
                {!!onDelete && file && (
                  <DeleteButtonPositioning>
                    <DeleteStyles title="delete file" onClick={(e) => deleteFile(e, file)} text>
                      <MfFontIcon icon="fonticons-trashcan" color="text:default" />
                    </DeleteStyles>
                  </DeleteButtonPositioning>
                )}
              </UploadedFile>
            ))}
          </ContainerStyle>
        )}
        {DeletePopup && (
          <DeletePopup
            fileToDelete={fileToDelete}
            onConfirm={() => deleteFileConfirm(fileToDelete)}
            onClose={() => setShowPopup(false)}
            popupProps={{ show: showPopup, bottomSheet: true, onClose: () => setShowPopup(false) }}
          />
        )}
      </>
    </>
  )
}

FileList.propTypes = {
  files: PropTypes.array,
  onDelete: PropTypes.func,
  deletePopup: PropTypes.func,
}

FileList.defaultProps = {
  files: undefined,
  onDelete: undefined,
  deletePopup: undefined,
}
