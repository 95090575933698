import { css } from "styled-components"

export const title1 = css`
  font-size: ${({ theme }) => theme.font.size.title1};
  line-height: 1.2;
  font-weight: normal;
`

export const title1Bold = css`
  ${title1}
  font-weight: ${({ theme }) => theme.font.weight.bolder};
  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
