import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import moment from "moment"

import { MfTypo } from "../../../../fundamentals/Typo"

const YearsWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
`

const YearRowWrapper = styled.div`
  display: flex;
  padding: 0 1rem;
`

const YearWrapper = styled.div`
  margin: 0 2rem 1rem 0;
  flex: 1;
  border-radius: 3px;
  text-align: center;
  border: 2px solid transparent;
  width: 48px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $selected, theme }) =>
    $selected &&
    `
  background: ${theme.color.highlight.default};
  color: white;
  `}

  &:hover {
    ${({ theme, $disabled }) =>
      !$disabled &&
      `
      border: 2px solid ${theme.color.primary.lighter};
      cursor: pointer;
    `}
  }

  &:last-child {
    margin-right: 0;
  }

  & > p {
    ${({ theme, $disabled }) => $disabled && `color: ${theme.color.text.lighter};`}
  }
`

const isDateOutsideMaxRange = (date, max, monthPicker) => {
  if (!monthPicker) {
    return date.isAfter(max, "year")
  }

  return date.isAfter(max, "year") || date.isAfter(moment().set(max), "month")
}

const YearRow = ({ names, onYearChange, selected, limits, displayDate, monthPicker }) => (
  <YearRowWrapper>
    {names.map((name) => {
      const selectedDate = selected[0] && moment(selected[0])

      const date = moment({ year: name, month: parseInt(displayDate.format("M")) - 1 })
      const { min, max } = limits
      const outsideOfMinMax =
        (min && date.isBefore(min, "year")) ||
        (max && isDateOutsideMaxRange(date, max, monthPicker))
      const disabled = outsideOfMinMax

      return (
        <YearWrapper
          key={name}
          onClick={() => {
            !disabled && onYearChange(name)
          }}
          $selected={selectedDate && selectedDate.isValid() && selectedDate.year() === name}
          $disabled={disabled}
          data-testid="year_item"
        >
          <MfTypo variant="bodytext1" weight="semibold">
            {name}
          </MfTypo>
        </YearWrapper>
      )
    })}
  </YearRowWrapper>
)

YearRow.propTypes = {
  names: PropTypes.arrayOf(PropTypes.number),
  selected: PropTypes.array,
  onYearChange: PropTypes.func,
  rowIndex: PropTypes.number,
  displayDate: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  limits: PropTypes.objectOf(PropTypes.any),
  monthPicker: PropTypes.bool,
}

YearRow.defaultProps = {
  names: [],
  selected: [],
  rowIndex: 0,
  onYearChange: () => {},
  displayDate: {},
  limits: { min: undefined, max: undefined },
}

export function Years({
  selected,
  displayDate,
  locale,
  onNavigate,
  limits,
  onSelect,
  monthPicker,
}) {
  const yearNames = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => i + 1).reduce((acc, _, index) => {
        const rowIndex = Math.floor(index / 3)
        const startYear = Math.floor(displayDate.year() / 12) * 12
        acc[rowIndex] = (acc[rowIndex] || []).concat(startYear + index)
        return acc
      }, []),
    [locale, displayDate]
  )

  const handleNavigate = useCallback(
    (year) => {
      // onNavigate({month: displayDate.month, year})
      if (monthPicker) {
        const newDate = moment(displayDate).year(year)
        onSelect(moment(newDate).format("MM/YYYY"))
      } else {
        onNavigate(moment(displayDate).year(year))
      }
    },
    [onNavigate, displayDate]
  )

  const limitsReset = useMemo(() => {
    const minMax = {}
    const { min, max } = limits
    if (min) minMax.min = moment(min).month(0).date(1)
    if (max) minMax.max = moment(max).month(0).date(1)
    return minMax
  }, [limits])

  const years = useMemo(
    () =>
      yearNames.map((row, index) => (
        <YearRow
          key={row[0] + "-" + row[2]}
          rowIndex={index}
          names={row}
          onYearChange={handleNavigate}
          selected={selected}
          displayDate={displayDate}
          limits={limitsReset}
          monthPicker={monthPicker}
        />
      )),
    [yearNames, handleNavigate, selected]
  )

  return <YearsWrapper>{years}</YearsWrapper>
}

Years.propTypes = {
  locale: PropTypes.string.isRequired,
  displayDate: PropTypes.any,
  selected: PropTypes.array,
  limits: PropTypes.objectOf(PropTypes.any),
  onNavigate: PropTypes.func,
  onSelect: PropTypes.func,
  monthPicker: PropTypes.bool,
}

Years.defaultProps = {
  displayDate: undefined,
  selected: [],
  limits: { min: undefined, max: undefined },
  onNavigate: () => {},
}
