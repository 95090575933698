import React from "react"
import PropTypes from "prop-types"
import { FloatingArrow } from "@floating-ui/react"

import { useWidget } from "./context"
import { useTheme } from "styled-components"

export function WidgetArrow({ variant }) {
  const context = useWidget()
  const theme = useTheme()

  return (
    <FloatingArrow
      ref={context.arrowRef}
      context={context}
      fill={
        variant === "light"
          ? `${theme.color.background.light}`
          : `${theme.color.snackbar.background.default}`
      }
    />
  )
}

WidgetArrow.propTypes = {
  variant: PropTypes.oneOf(["light", "dark"]).isRequired,
}
