import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const DropdownWrapper = styled.div`
  background: ${({ theme }) => theme.color.background.light};
  width: 100%;
  padding: 16px;
  box-shadow: 0 0 8px 4px rgb(0 0 0 / 8%);
  border-radius: 8px 8px 0 0;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  overflow-y: auto;
  max-height: 99vh;
  user-select: none;

  /*
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox

  &::-webkit-scrollbar {
    display: none;
  }

  */

  display: none;
  ${({ $open }) =>
    $open &&
    css`
      display: block;
    `}

  ${({ theme, $marginBottom, $fullWidth }) => css`
    ${theme.media.phablet`
      position: absolute;
      top: calc(100% + ${$marginBottom});
      bottom: auto;
      max-height: 476px;
      width: ${$fullWidth ? "-webkit-fill-available" : "auto"};
      padding: 0;
      border-radius: 8px;
    `}
  `}

  ${({ $placement }) =>
    $placement === "left" &&
    css`
      left: 0;
    `}
  ${({ $placement }) =>
    $placement === "center" &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `}
  ${({ $placement }) =>
    $placement === "right" &&
    css`
      right: 0;
    `}
`

export function Dropdown({ children, open, marginBottom, placement = "center", ...props }) {
  return (
    <DropdownWrapper
      $fullWidth={props.fullWidth}
      $open={open}
      $marginBottom={marginBottom}
      $placement={placement}
      {...props}
    >
      {open && children}
    </DropdownWrapper>
  )
}

Dropdown.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  marginBottom: PropTypes.string,
  placement: PropTypes.oneOf(["left", "center", "right"]),
  fullWidth: PropTypes.bool,
}

Dropdown.defaultProps = {
  fullWidth: false,
}
