import { css } from "styled-components"

export const bodyText3 = css`
  font-size: ${({ theme }) => theme.font.size.body3};
  line-height: 1.31;
`

export const bodyText3SemiBold = css`
  ${bodyText3}

  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`

export const bodyText3Bold = css`
  ${bodyText3}

  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
