import getAxesInfo from "./axes"

const axes = getAxesInfo()

// Define the possible characters for numbers in supported languages
const defaultSeperator = {
  fr: " ",
  nl: ".",
  en: ",",
  de: " ",
}

const defaultCommaCharacter = {
  fr: ",",
  nl: ",",
  en: ".",
  de: ",",
}

// Define the current used characters for numbers based on the axes object
const seperator = defaultSeperator[axes.language]
const decimalSeperator = defaultCommaCharacter[axes.language]

export function createNumberMask({ decimals = 2 } = {}) {
  return function (value) {
    if (value === "") return true

    if (decimals > 0) {
      return new RegExp(`^\\d+(\\.\\d{0,${decimals}})*$`).test(value)
    }
    return !/[^\d]/.test(value)
  }
}

export function createNumberFormatter() {
  return {
    format(value) {
      if (value === "") {
        return value
      }

      const [wholeNumber, decimals] = value.split(".")

      // Format the whole number section of the number
      let numberFormatted = ""

      for (let i = 1; i <= wholeNumber.length; i++) {
        const current = wholeNumber[wholeNumber.length - i]
        if (i % 3 === 0 && i !== wholeNumber.length) {
          numberFormatted = `${seperator}${current}` + numberFormatted
        } else {
          numberFormatted = current + numberFormatted
        }
      }

      // Format the decimal section of the number
      if (decimals) {
        numberFormatted += `${decimalSeperator}${decimals}`
      } else if (value.slice(-1) === ".") {
        numberFormatted += `${decimalSeperator}`
      }

      return numberFormatted
    },
    unformat(value = "") {
      // Remove the thousand seperator of the current language
      value = value.replace(new RegExp(`\\${seperator}`, "g"), "")

      // Replace the decimal seperator with the .
      value = value.replace(new RegExp(`\\${decimalSeperator}`, "g"), ".")

      return value
    },
  }
}
