import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { bodyText1, bodyText2 } from "../../../style/css"

import { BaseOption } from "../BaseOption"

export const LabelSplit = styled.div`
  flex: 1;
  text-align: left;
  display: flex;
  margin-bottom: ${({ $hasSubLabel }) => ($hasSubLabel ? "4px" : "0")};

  > span {
    ${bodyText1}
    text-align: left;
    font-weight: 500;

    ${({ theme }) => theme.media.tablet`
      font-family: ${({ theme }) => theme.font.family.desktop.bold};
      font-weight: 300;
    `}
  }

  > span + span {
    flex: 1;
    text-align: right;
    ${bodyText1}
    color: ${({ theme }) => theme.color.text.lighter};
    font-weight: normal;
  }
`

const SubLabel = styled.div`
  ${bodyText2}

  line-height: 1.1;
  font-weight: normal;
  color: ${({ theme }) => theme.color.text.lighter};
`

const AmountContainer = styled.div`
  line-height: 1.1;
  font-weight: 300;
  display: flex;
  justify-content: end;
  margin-top: ${({ theme }) => theme.spaceValues[3]};
  font-family: ${({ theme }) => theme.font.family.desktop.bold};
`

const AnnualAmountContainer = styled.div`
  line-height: 1.1;
  font-weight: normal;
  color: ${({ theme }) => theme.color.text.lighter};
  display: flex;
  justify-content: end;
`

function WrappedTextOption({
  label,
  secondaryLabel,
  subLabel,
  checkbox,
  amount,
  annualAmount,
  innerRef,
  ...props
}) {
  return (
    <BaseOption
      {...props}
      ref={innerRef}
      label={
        <LabelSplit $hasSubLabel={!!subLabel}>
          <span>{label}</span>
          {secondaryLabel && <span>{secondaryLabel}</span>}
        </LabelSplit>
      }
      checkbox={checkbox}
    >
      {subLabel && <SubLabel>{subLabel}</SubLabel>}
      {amount && <AmountContainer>{amount}</AmountContainer>}
      {annualAmount && <AnnualAmountContainer>{annualAmount}</AnnualAmountContainer>}
    </BaseOption>
  )
}

// We need to wrap the Option in a forwardRef call to make sure the BaseOption can put it on the Container
export const TextOption = React.forwardRef((props, ref) => (
  <WrappedTextOption innerRef={ref} {...props} />
))
TextOption.displayName = "TextOption"

WrappedTextOption.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  value: PropTypes.any,
  innerRef: PropTypes.any,
  checkbox: PropTypes.bool,
  amount: PropTypes.string,
  annualAmount: PropTypes.string,
}

WrappedTextOption.defaultProps = {
  subLabel: undefined,
  secondaryLabel: undefined,
  value: undefined,
  innerRef: undefined,
  checkbox: false,
}
