import React, { useRef, useState, useCallback, useEffect } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

import { MfCell } from "../../atoms/Cell"
import { MfFontIcon } from "../../atoms/FontIcon"
import { MfTypo } from "../../fundamentals/Typo"

const UploadWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

const UploadButtonStyle = styled.button.attrs({
  type: "button",
})`
  ${({ theme }) => css`
    box-sizing: border-box;
    padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} 0;
    border: 1px solid ${theme.color.border.lighter};
    border-radius: 8px;
    background-color: transparent;
    text-align: left;
    display: block;
    width: 100%;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-family: inherit;

    ${({ disabled }) =>
      !disabled
        ? css`
            &:focus,
            &:hover {
              border-color: ${theme.color.accent.default};
              background-color: ${theme.color.hover.light};
              outline: solid 4px ${theme.color.outline.focus};
              box-shadow: 1px 1px 0 inset ${theme.color.accent.default},
                -1px -1px 0 inset ${theme.color.accent.default},
                1px -1px 0 inset ${theme.color.accent.default},
                -1px 1px 0 inset ${theme.color.accent.default};
            }
          `
        : css`
            cursor: initial;
            background-color: ${theme.color.background.disabled};
            color: ${theme.color.text.disabled};

            & i,
            & p {
              color: ${theme.color.text.disabled};
            }
          `}
    ${({ isDragging }) =>
      isDragging &&
      css`
        & {
          border-color: ${theme.color.accent.default};
          background-color: ${theme.color.hover.light};
          box-shadow: 1px 1px 0 inset ${theme.color.accent.default},
            -1px -1px 0 inset ${theme.color.accent.default},
            1px -1px 0 inset ${theme.color.accent.default},
            -1px 1px 0 inset ${theme.color.accent.default};
        }
      `}
  `}
`
const NameContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-right: 30px;
`

const EllipsisLabel = styled(MfTypo).attrs({ variant: "bodytext1" })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
`

const EllipsisSubLabel = styled(MfTypo).attrs({ variant: "bodytext1" })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const InputStyles = styled.input`
  display: none;
`

const AdditionalInfoContainer = styled.div`
  flex: 1;
`

const AdditionalInfoStyles = styled(MfTypo)`
  color: ${({ theme }) => theme.color.text.lighter};
  padding: 6px 0 6px 16px;
  margin: 0;
`

const ErrorContainer = styled.div`
  flex: 1;
`

const ErrorMessage = styled(MfTypo)`
  color: ${({ theme }) => theme.color.status.error};
  padding: 6px 20px;
  margin: 0;
`

/**
 * Upload Component
 */
export function UploadInput({
  onChange,
  label,
  subLabel,
  fileSize,
  fileSizeText,
  disabled,
  maxFiles,
  maxFilesText,
  multiple,
  error,
  additionalInfo,
  currentFileCount,
  innerRef,
  ...props
}) {
  const [maxFilesReached, setMaxFilesReached] = useState(false)

  const [dragCounter, setDragCounter] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  // inputRef is used to manipulate the input element to trigger events and update the value
  const inputRef = useRef()

  // When a new file is dropped in the upload input, send it to the onChange handler. The parent of the
  // Upload Component can handle the upload behavior depending on their use case. Also trigger the loading
  // state to show the spinner animation.
  const handleChange = useCallback(
    (event) => {
      if (maxFiles && event.target.files.length + currentFileCount > maxFiles) {
        event.preventDefault()
        return setMaxFilesReached(true)
      }

      const files = []
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i])
      }

      if (files && files.length) {
        onChange(event, files)
      }

      if (inputRef.current) {
        inputRef.current.value = ""
      }

      setMaxFilesReached(false)
    },
    [onChange, maxFiles]
  )

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault()

      if (maxFiles && event.dataTransfer.items.length + currentFileCount > maxFiles) {
        setDragCounter(0)
        setIsDragging(false)
        return setMaxFilesReached(true)
      }

      const files = []
      let item
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        item = event.dataTransfer.items[i]
        if (item.kind === "file") {
          files.push(item.getAsFile())
        }
      }

      if (files && files.length) {
        onChange(event, files)
      }

      if (inputRef.current) {
        inputRef.current.value = ""
      }

      setMaxFilesReached(false)
      setDragCounter(0)
      setIsDragging(false)
    },
    [onChange, maxFiles]
  )

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDragEnter = (event) => {
    event.preventDefault()
    setDragCounter(dragCounter + 1)
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
    setDragCounter(dragCounter - 1)
  }

  useEffect(() => {
    if (dragCounter > 0 && !isDragging) setIsDragging(true)
    else setIsDragging(false)
  }, [dragCounter])

  return (
    <>
      <UploadWrapper>
        <UploadButtonStyle
          title="select file"
          disabled={disabled}
          isDragging={isDragging}
          onClick={() => !disabled && inputRef.current.click()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          ref={innerRef}
        >
          <InputStyles
            {...props}
            data-testid="fileinput"
            type="file"
            multiple={multiple}
            disabled={disabled}
            onChange={handleChange}
            ref={inputRef}
          />
          <MfCell
            icon={<MfFontIcon icon="fonticons-file" color="accent:default" />}
            value={
              <NameContainer>
                <EllipsisLabel>{label}</EllipsisLabel>
                {subLabel && <EllipsisSubLabel>{subLabel}</EllipsisSubLabel>}
              </NameContainer>
            }
            subValue={fileSize ? `${fileSizeText} ${fileSize}Mb` : undefined}
          />
        </UploadButtonStyle>
        {(error && (
          <ErrorContainer>
            <ErrorMessage variant="bodytext3">{error}</ErrorMessage>
          </ErrorContainer>
        )) ||
          (maxFilesReached && maxFilesText && (
            <ErrorContainer>
              <ErrorMessage variant="bodytext3">{maxFilesText}</ErrorMessage>
            </ErrorContainer>
          )) ||
          (additionalInfo && (
            <AdditionalInfoContainer>
              <AdditionalInfoStyles variant="bodytext3">{additionalInfo}</AdditionalInfoStyles>
            </AdditionalInfoContainer>
          ))}
      </UploadWrapper>
    </>
  )
}

UploadInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
  fileSizeText: PropTypes.string,
  fileSize: PropTypes.number,
  maxFiles: PropTypes.number,
  maxFilesText: PropTypes.string,
  multiple: PropTypes.bool,
  error: PropTypes.string,
  additionalInfo: PropTypes.string,
  currentFileCount: PropTypes.number,
  innerRef: PropTypes.any,
}

UploadInput.defaultProps = {
  disabled: false,
  fileSizeText: `Max. file size:`,
  fileSize: undefined,
  maxFiles: undefined,
  maxFilesText: undefined,
  multiple: false,
  error: undefined,
  additionalInfo: undefined,
  currentFileCount: 0,
  innerRef: undefined,
}
