import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfFontIcon } from "../FontIcon"
import { MfButton } from "../Button"

const Header = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  font-weight: bold;
  border-bottom: 1px solid #cecece;
  padding: ${({ theme }) => `${theme.spacing(3)}`};

  .title {
    grid-column: 2;
    text-align: center;
    align-self: center;
    font-family: regular, serif;
    font-size: 1.25rem;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .back {
    margin-left: 0.313rem;
  }
`
const FlexEnd = styled.div`
  display: flex;
  place-self: flex-end;
  margin-bottom: auto;
  margin-top: auto;
`

const BackButton = styled(MfButton)`
  padding: 0;
  line-height: normal;
  place-self: flex-start;
  margin-bottom: auto;
  margin-top: auto;
`

const Close = styled.button.attrs({
  children: <MfFontIcon icon="fonticons-close-large" />,
})`
  background: white;
  cursor: pointer;
  border: 0;
  margin: 0;
  z-index: 1;
  outline: none;

  i {
    font-size: 20px;
    color: ${({ theme }) => theme.color.text.lighter};
  }
`

export function MultistepHeader({
  showBackButton,
  onBackButtonClick,
  backButtonLabel,
  showCloseButton,
  onClosePopupClick,
  children,
  ...props
}) {
  return (
    <Header {...props}>
      {showBackButton && (
        <BackButton text onClick={onBackButtonClick}>
          <MfFontIcon icon="fonticons-arrow-back-ios" />
          <span className="back">{backButtonLabel}</span>
        </BackButton>
      )}
      <h1 className="title">{children}</h1>
      <FlexEnd>{showCloseButton && <Close onClick={onClosePopupClick} />}</FlexEnd>
    </Header>
  )
}

MultistepHeader.propTypes = {
  showBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  backButtonLabel: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onClosePopupClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

MultistepHeader.defaultProps = {
  showBackButton: false,
  backButtonLabel: "Back",
  showCloseButton: true,
}
