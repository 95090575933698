import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSelect } from "downshift"

export const SelectStyles = styled.div`
  outline: none;
  display: flex;
  flex-wrap: wrap;
`

const ToggleWrapper = styled.span`
  outline: none;
`

// Define the default layout and layout proptypes for selects
export const layoutConfig = {
  default: {
    columns: 1,
    gap: 2,
  },
  propTypes: PropTypes.shape({
    columns: PropTypes.number.isRequired,
    gap: PropTypes.number.isRequired,
  }).isRequired,
}

// Styles for the layout of an individual item
//
// TODO - When the MFInset and MfBleed components are made, the MfInset can be used to handle the paddings
// since the MfInset should have the ability to control all 4 sides seperatly. This way you could bleed
// lines or arrows perfectly from one item to the next.
const SelectItemStyles = styled.div`
  flex: 100%;

  /* On mobile and phablet, the items are always in one column so we gap only top and bottom */
  padding: ${({ theme, $layout }) => `calc(${theme.spacing($layout.gap)} / 2) 0`};

  /* But remove the first and last paddings to align against the edge of the container */
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme, $layout: { columns, gap } }) => theme.media.tablet`
    flex: calc(100% / ${columns});
    max-width: calc(100% / ${columns});
    padding: calc(${theme.spacing(gap)} / 2);

    &:nth-child(-n+${columns}) {
      padding-top: 0;
    }
    &:nth-last-child(-n+${columns}) {
      padding-bottom: 0;
    }
    &:nth-child(${columns}n + 1) {
      padding-left: 0;
    }
    &:nth-child(${columns}n + ${columns}) {
      padding-right: 0;
    }
  `}
`

// Item wraps the option to handle the layout. It will act as the flex items inside
// the flex container which is defined by the SelectStyles. We pass the layout to
// the SelectItemStyles to handle the correct styles, and pass the rest to the child
// which should be an Option element.
export function Item({ children, layout, ...props }) {
  return <SelectItemStyles $layout={layout}>{React.cloneElement(children, props)}</SelectItemStyles>
}

Item.propTypes = {
  children: PropTypes.any.isRequired,
  layout: layoutConfig.propTypes,
}

export function SingleSelect({
  value,
  initialHighlighted = value,
  onChange,
  children,
  className,
  layout,
  ...props
}) {
  function stateReducer(state, actionAndChanges) {
    const { type, changes } = actionAndChanges

    // this prevents the menu from being closed when the user selects an item with 'Enter', 'Space' or mouse
    // this is necessary to make sure the focus can be handled correctly after focusing out of the menu
    switch (type) {
      case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
      case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ToggleButtonClick: {
        return {
          ...changes, // default Downshift new state changes on item selection.
          selectedItem: changes.selectedItem,
          isOpen: state.isOpen, // but keep menu open.
          highlightedIndex: state.highlightedIndex, // with the item highlighted.
        }
      }
      case useSelect.stateChangeTypes.MenuMouseLeave:
      case useSelect.stateChangeTypes.ToggleButtonBlur: {
        return { ...state, highlightedIndex: -1 }
      }
      default:
        return changes // otherwise business as usual.
    }
  }

  const { selectedItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } =
    useSelect({
      items: React.Children.map(children, (child) => child.props.value),
      onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
      // TODO: IMPLEMENT CXC DESIRED BEHAVIOR
      initialSelectedItem: value,
      initialHighlightedIndex: React.Children.toArray(children).findIndex(
        (child) => child.props.value === initialHighlighted
      ),
      initialIsOpen: false,
      stateReducer,
    })

  // Merge the default layout and the layout that is passed by the props. This way the whole layout
  // doesn't need to be defined, only the fields that are customized.
  const customLayout = {
    ...layoutConfig.default,
    ...layout,
  }

  return (
    <ToggleWrapper {...getToggleButtonProps({}, { suppressRefError: true })}>
      <SelectStyles className={className} {...props} {...getMenuProps()}>
        {React.Children.map(children, (child, index) => (
          <Item layout={customLayout} aria-selected={selectedItem === child.props.value}>
            {React.createElement(child.type, {
              ...getItemProps({
                ...child.props,
                item: child.props.value,
                index,
                highlighted: highlightedIndex === index,
                "data-highlighted": highlightedIndex === index,
                selected: selectedItem === child.props.value,
                "aria-label": child.props.value,
              }),
            })}
          </Item>
        ))}
      </SelectStyles>
    </ToggleWrapper>
  )
}

SingleSelect.propTypes = {
  value: PropTypes.any.isRequired,
  initialHighlighted: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  layout: layoutConfig.propTypes,
}

SingleSelect.defaultProps = {
  initialHighlighted: undefined,
  className: undefined,
  layout: layoutConfig.default,
}
