import PropTypes from "prop-types"
import React from "react"

import styled from "styled-components"
import { MfFontIcon } from "../FontIcon"

export const StyledItemContainer = styled.li`
  background: none;
  border: 4px solid transparent;
  border-radius: 0;
  color: ${({ theme }) => theme.color.text.darker};
  display: flex;
  min-height: ${({ theme }) => `calc(${theme.spacing(7)} + ${theme.spacing(2)})`};
  justify-content: space-between;
  line-height: unset;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 100%;

  button {
    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
  }

  ${({ theme }) => theme.media.tablet`
    border-left: 4px solid transparent;
    
    &:hover, &:active {
      background: ${theme.color.hover.light};
    }

    &:focus-within {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.05);
      background: ${theme.color.hover.light};
    }
  `}
`

export const StyledIconsContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.spacing(7)};

  :last-of-type {
    padding-right: ${({ theme }) => theme.spacing(3)};
  }

  :first-of-type {
    padding-left: ${({ theme }) => theme.spacing(2)};
  }

  :first-of-type:not(:last-of-type) {
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`

export const MenuItem = ({ selected, cta, children, checkSelected }) => {
  return (
    <StyledItemContainer
      $cta={cta}
      $selected={selected}
      onMouseOver={(e) => {
        e?.target?.focus?.()
      }}
    >
      {children}
      {selected && checkSelected && (
        <StyledIconsContainer>
          <MfFontIcon icon="fonticons-check" color="primary:default" />
        </StyledIconsContainer>
      )}
    </StyledItemContainer>
  )
}

MenuItem.propTypes = {
  selected: PropTypes.bool,
  cta: PropTypes.elementType,
  checkSelected: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

MenuItem.defaultProps = {
  selected: false,
  cta: undefined,
  checkSelected: true,
}
