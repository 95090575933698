import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { useTheme } from "styled-components"

import { MfButton } from "."
import { useMatchMedia } from "../../helpers/useMatchMedia"

export const MfCollapsableButton = forwardRef(({ expandFrom, ...props }, ref) => {
  const theme = useTheme()
  const showFullButtons = useMatchMedia(theme.screen.size[expandFrom] || expandFrom)

  return (
    <MfButton {...props} ref={ref} text={showFullButtons ? props.text : true}>
      {showFullButtons ? props.children : null}
    </MfButton>
  )
})

MfCollapsableButton.displayName = "MfCollapsableButton"

MfCollapsableButton.propTypes = {
  expandFrom: PropTypes.string,
  text: PropTypes.bool,
  children: PropTypes.any.isRequired,
}

MfCollapsableButton.defaultProps = {
  expandFrom: "tablet",
  text: false,
}
