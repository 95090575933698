import { useCallback, useEffect, useState, useMemo } from "react"

export default function useNodeDimensions(ref) {
  const [inputElement, setInputElement] = useState(null)
  const [boundingRect, setBoundingRect] = useState({})

  const measureElementRef = useCallback((node) => {
    if (ref) {
      ref.current = node
    }

    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect())
      setInputElement(node)
    }
  }, [])

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry?.target) {
            setBoundingRect(entry.target.getBoundingClientRect())
          }
        }
      }),
    []
  )

  useEffect(() => {
    if (inputElement) {
      resizeObserver.observe(inputElement)

      return () => {
        resizeObserver.unobserve(inputElement)
      }
    }
  }, [inputElement, resizeObserver])

  return [measureElementRef, boundingRect]
}
