import { useState, useMemo } from "react"

/**
 * useSticky React Hook
 * This hook handles the behaviour to make an element inside a container stick to the top when it gets scrolled
 * out of view. This hook contains only the logic and has no built in styling for it. It simply checks adds a
 * scrollHandler on the container via the getContainerProps function and sets a state for wether the element is
 * pinned to the top or not. This state can be used to style the element in a way that fits your needs.
 *
 * @param {HTMLElement | React.ref Object | function} element The element that needs to become sticky
 * @param {Object} param1 An options object to configure the behaviour
 */
export default function useSticky(
  // The element to stick
  element,
  // Some initial values that can be set. These will be overriden at runtime so don't worry to much about them
  { initialPinned = false, initialElementHeight = 48 } = {}
) {
  const [pinHeader, setPinHeader] = useState(initialPinned)
  const [headerHeight, setHeaderHeight] = useState(initialElementHeight)

  // The ReplaceDiv will get injected above the sticky element and takes it height so the flow of the
  // siblings stays the same. The element is added and removed with standard DOM methods because it
  // is easier to inject it in the correct place like that. For that reason the ReplaceDiv is a standard
  // DOM element.
  const ReplaceDiv = useMemo(() => {
    const el = document.createElement("div")
    el.style.height = "12px"
    el.dataset.placeholder = "mfpopup"
    return el
  }, [])

  function scrollHandler(event) {
    // Get a reference to the header inside the overlay
    // const header = event.target.querySelector('.mf--popup-header');

    // header can be defined in 3 different ways.
    // 1. By default we assume that header is an html element
    // 2. It can also be a callback function. So we check for that
    if (typeof element === "function") {
      element = element()
    }
    // 3. It can also be a react ref object. So we check for that
    //    The check for a ref object is done after the callback check to make sure
    //    that when the callback returns a ref object it is still handled correctly.
    if (element.current) {
      element = element.current
    }

    if (element) {
      // When the header is not yet pinned
      if (pinHeader === false) {
        // Check if the header needs to be pinned
        if (element.offsetTop <= event.target.scrollTop) {
          // And store the header offset for future use
          setPinHeader(element.offsetTop)
          // We do -16 to account for the padding. This ties directly to the way the popup is styled
          // on the other side. This kind of implicit binding should be avoided though.
          // An option would be to pass this value in via the arguments
          setHeaderHeight(element.clientHeight - 16)
          ReplaceDiv.style.height = `${element.clientHeight}px`

          // We add the placeholder div in before the header to take up the headers space. The header
          // is placed absolutely by the popup providing the correct styling based on the pinHeader variable
          element.parentElement.insertBefore(ReplaceDiv, element)
        }
        // But if the header is already pinned and we scroll back passed it
        // We do +1 so the header doesn't unpin when it's the first element
      } else if (pinHeader > event.target.scrollTop + 1) {
        // Unpin the header
        setPinHeader(false)

        // And remove the placeholder div from the content
        element.parentElement.removeChild(ReplaceDiv)
      }
    }
  }

  // The return value contains the state of the hook together with a function
  // that returns the props for the container that houses the element to become sticky
  return {
    pinHeader,
    headerHeight,
    getContainerProps: (extraProps) => ({
      ...extraProps,
      pinHeader,
      onScroll: scrollHandler,
    }),
  }
}
