import React, { useMemo } from "react"

export default function useDefaultStyle(content, DefaultStyles, { wrapArray = false } = {}) {
  const wrappedContent = useMemo(
    () =>
      content === undefined ? null : typeof content === "string" ? (
        <DefaultStyles>{content}</DefaultStyles>
      ) : Array.isArray(content) ? (
        wrapArray ? (
          content.map((x) => <DefaultStyles key={x}>{x}</DefaultStyles>)
        ) : (
          content.map((x, i) =>
            typeof x === "string" ? (
              <DefaultStyles key={x}>{x}</DefaultStyles>
            ) : (
              <React.Fragment key={i}>{x}</React.Fragment>
            )
          )
        )
      ) : (
        content
      ),
    [content, DefaultStyles, wrapArray]
  )

  return wrappedContent
}
