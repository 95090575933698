export default function createFormatter(
  formatText = "",
  { wildcard = "%", limitLength = true } = {}
) {
  return {
    format(text = "", { eager = false } = {}) {
      let formattedText = ""
      // The position variable represents the current position in the text variable
      let position = 0

      for (let i = 0; i < formatText.length; i++) {
        const currentFormatCharacter = formatText[i]

        if (position < text.length) {
          if (currentFormatCharacter === wildcard) {
            formattedText += text[position]
            position += 1
          } else {
            formattedText += currentFormatCharacter

            // Here the position could be increased if the formatCharacter matches the current text position
            // and you want to allow already formatted text to be formatted again with the same result
            if (currentFormatCharacter === text[position]) {
              position++
            }
          }
        } else if (eager && position === text.length) {
          if (currentFormatCharacter !== wildcard) {
            formattedText += currentFormatCharacter
          }

          position += 1
        }
      }

      if (limitLength) {
        return formattedText
      }

      return formattedText + text.slice(position)
    },
    unformat(text = "") {
      let unformatted = ""

      for (let i = 0; i < text.length; i++) {
        const currentTextCharacter = text[i]
        const currentFormatCharacter = formatText[i]

        if (currentFormatCharacter === undefined) {
          if (limitLength === false) {
            unformatted += currentTextCharacter
          }
        } else if (currentFormatCharacter !== wildcard) {
          if (currentTextCharacter !== formatText[i]) {
            unformatted += currentTextCharacter
          }
        } else {
          unformatted += currentTextCharacter
        }
      }

      return unformatted
    },
  }
}
