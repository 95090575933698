import media from "../helpers/media"

const themeValues = {
  color: {
    primary: {
      default: "#ff5a64",
      darker: "#d24852",
      lighter: "#FFB0B4",
    },
    secondary: {
      default: "#176d8c",
      darker: "#104e65",
      lighter: "#176d8c",
      lighter4: "#f6fcfe",
    },
    hover: {
      light: "#f6fcfe",
      dark: "#d9e5ef",
    },
    accent: {
      default: "#11bad5",
      darker: "#08a2bf",
      lighter: "#11bad5",
    },
    header: {
      start: "#0080a5",
      end: "#06d1e0",
    },
    highlight: {
      default: "#11bad5",
    },
    status: {
      warning: "#f2a122",
      success: "#729f08",
      error: "#e93e2e",
      info: "#3394cc",
    },
    text: {
      default: "#28444d",
      darker: "#28444d",
      lighter: "#777777",
      white: "#ffffff",
      disabled: "#cecece",
      disabled2: "#b7b7b7",
    },
    background: {
      light: "#ffffff",
      disabled: "#eeeeee",
      warning: "#fdf2e0",
      information: "#e2f0f7",
    },
    badge: {
      background: {
        default: "#eeeeee",
        information: "#e2f0f7",
        positive: "#ebf2dc",
        warning: "#fdf2e0",
        error: "#fce4e1",
        dark: "#333333",
      },
      highlight: {
        default: "#777777",
        warning: "#f2a122",
        positive: "#729f08",
        error: "#e93e2e",
        information: "#3394cc",
      },
      text: {
        default: "#000000",
        information: "#004A80",
        positive: "#547122",
        warning: "#89441B",
        error: "#8C251C",
        dark: "#ffffff",
      },
    },
    snackbar: {
      background: {
        default: "#333333",
      },
    },
    border: {
      default: "#b7b7b7",
      lighter: "#cecece",
      disabled: "#e5e5e5",
    },
    outline: {
      focus: "rgba(255,90,100, 0.2)",
    },
  },
  spaceValues: {
    1: "4px",
    2: "8px",
    3: "16px",
    4: "24px",
    5: "32px",
    6: "40px",
    7: "48px",
    8: "64px",
  },
  font: {
    family: {
      mobile: {
        normal: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
        bold: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      },
      desktop: {
        normal: "hello-regular",
        bold: "hello-bold",
      },
    },
    weight: {
      normal: "400",
      semiBold: "500",
      bold: "600",
      bolder: "700",
    },
    size: {
      title1: "2.5rem", // 40px
      title2: "2rem", // 32px
      title3: "1.5rem", // 24px
      title4: "1.25rem", // 20px
      title5: "1.125rem", // 18px
      body1: "1rem", // 16px
      body2: "0.875rem", // 14px
      body3: "0.8125rem", // 13px
    },
  },
  screen: {
    size: {
      lgscreen: 1728,
      desktop: 959,
      tablet: 759,
      phablet: 450,
      phone: 320,
    },
  },
}

export default {
  ...themeValues,
  media: media(themeValues.screen.size),
  spacing: (x) => {
    return themeValues.spaceValues[x]
  },
}
