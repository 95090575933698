import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfTypo } from "../../fundamentals/Typo"
import { BackButton } from "./BackButton"

const HeaderStyles = styled.header`
  ${({ theme, $hasProgress, $demo }) => css`
    position: ${$demo === true ? "static" : "fixed"};
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    top: 0;
    height: 68px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    background: ${theme.color.header.end};
    background: linear-gradient(to right, ${theme.color.header.start}, ${theme.color.header.end});
    ${theme.media.desktop`
      position: relative;
      background: ${theme.color.background.light};
      border-bottom: ${$hasProgress ? "0" : "1px solid #dfe1ea"};
      padding: 0;
    `}
  `}
`

const HeaderPlaceholder = styled.div`
  ${({ theme, $demo }) => css`
    display: ${$demo ? "none" : "block"};
    height: 68px;
    ${theme.media.desktop`
      display: none;
    `}
  `}
`

const HeaderContentStyles = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderTextStyle = styled(MfTypo)`
  text-align: center;
  color: ${({ theme }) => theme.color.text.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.desktop`
    color: ${theme.color.text.darker};
    flex: auto;
    max-width: 400px;
  `}
`

const ProgressStyles = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  bottom: 0;
  background-color: #dfe1ea;

  ${({ $amount }) => css`
    div {
      position: absolute;
      left: 0;
      top: 0;
      height: 4px;
      width: ${$amount}%;
      background-color: #afd33d;
    }
  `}
`

const ActionStyles = styled.div`
  display: flex;
  height: 64px;
  flex: 1;
  align-items: center;
  justify-content: ${({ $right }) => ($right ? "flex-end" : "flex-start")};
`

const Pagination = styled.div`
  margin-right: ${({ theme }) => theme.spacing(3)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.color.text.white};

  ${({ theme }) => theme.media.desktop`
    color: ${theme.color.text.lighter};
  `}
`
const Label = styled.span`
  display: none;
  text-transform: uppercase;

  ${({ theme }) => theme.media.desktop`
    display: inline
  `}
`

export function MfHeader({
  title,
  progress,
  actions: { left, right },
  pagination,
  demo,
  ...props
}) {
  return (
    <>
      <HeaderStyles
        {...props}
        $hasProgress={!!progress}
        $left={left}
        $right={right}
        $pagination={pagination}
        $demo={demo}
      >
        <HeaderContentStyles>
          <ActionStyles>{left}</ActionStyles>
          <HeaderTextStyle variant="bodytext1" weight="bold">
            {title}
          </HeaderTextStyle>
          <ActionStyles $right={true}>
            {right}
            {pagination && pagination.currentStep && pagination.totalSteps && (
              <Pagination>
                <MfTypo variant="bodytext3" weight="semibold">
                  {pagination.label && <Label>{pagination.label} </Label>}
                  {pagination.currentStep}/{pagination.totalSteps}
                </MfTypo>
              </Pagination>
            )}
          </ActionStyles>
        </HeaderContentStyles>
        {progress !== undefined && (
          <ProgressStyles $amount={progress}>
            <div />
          </ProgressStyles>
        )}
      </HeaderStyles>
      <HeaderPlaceholder demo={demo}>{/* Placeholder to avoid jump */}</HeaderPlaceholder>
    </>
  )
}

export const MfBackButton = BackButton

MfHeader.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.number,
  actions: PropTypes.shape({
    left: PropTypes.node,
    right: PropTypes.node,
  }),
  pagination: PropTypes.object,
  demo: PropTypes.bool,
}

MfHeader.defaultProps = {
  progress: undefined,
  actions: {},
  pagination: undefined,
  demo: false,
}
