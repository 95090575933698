import React, { useEffect, useCallback, useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
/* FocusLock traps the focus inside the overlay, making it impossible to focus out of the overlay */
import FocusLock from "react-focus-lock"
import Locky from "react-locky"

import { MultistepPage } from "./MultistepPage"
import { MultistepHeader } from "./MultistepHeader"
import { MultistepDetails } from "./MultistepDetails"
import { MultistepActions } from "./MultistepActions"

import { MfButton } from "../Button"

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  backdrop-filter: blur(4px);
  background-color: rgb(0 0 0 / 30%);
  outline: none;
`

const Slider = styled.div`
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
`

function MfMultistep({
  onClose,
  endFlowButtonLabel,
  endFlowAction,
  restartFlowButtonLabel,
  isMandatory = false,
  steps,
  ...props
}) {
  const [activeStep, setActiveStep] = useState(0)

  const [sliderWidth, setSliderWidth] = useState(0)
  const sliderRef = useRef(null)

  const pageRefCallback = useCallback((node) => {
    if (node !== null) {
      setSliderWidth(node.getBoundingClientRect().width)
    }
  }, [])

  // Close the Popup on the "ESC" keyboard key pressed
  const handleEscape = useCallback((event) => {
    if (event.keyCode === 27) {
      onClose()
    }
  }, [])

  //The multistep is using a global class defined in the GlobalProvider to toggle the no scroll
  //behavior in the document body. Refer to it for the class definition.
  useEffect(() => {
    if (!isMandatory) {
      window.document.body.classList.add("noScroll")
      window.addEventListener("keydown", handleEscape)

      return () => {
        window.document.body.classList.remove("noScroll")
        window.removeEventListener("keydown", handleEscape)
      }
    }
  }, [])

  const currentStepData = useMemo(() => steps[activeStep], [activeStep])

  const goNext = () => {
    if (sliderRef.current.scrollLeft + 1 > sliderWidth * activeStep) {
      setActiveStep((step) => step + 1)
      sliderRef.current.scrollLeft += sliderWidth
    }
  }

  const goBack = () => {
    if (sliderRef.current.scrollLeft <= sliderWidth * activeStep) {
      setActiveStep((step) => step - 1)
      sliderRef.current.scrollLeft -= sliderWidth
    }
  }

  const restart = () => {
    setActiveStep(0)
    sliderRef.current.scrollLeft -= sliderRef.current.scrollLeft
  }

  return currentStepData ? (
    <FocusLock>
      <Locky noDefault events={{ scroll: true, keydown: false }}>
        <Overlay {...props}>
          <MultistepPage ref={pageRefCallback}>
            <MultistepHeader
              showBackButton={activeStep > 0}
              onBackButtonClick={goBack}
              backButtonLabel={currentStepData.backButtonLabel}
              showCloseButton={isMandatory ? activeStep >= steps.length - 1 : true}
              onClosePopupClick={isMandatory && endFlowAction ? endFlowAction : onClose}
            >
              {currentStepData.title}
            </MultistepHeader>
            <Slider ref={sliderRef}>
              {steps.map((step, index) => (
                <MultistepDetails key={`step-${index}`}>{step.content}</MultistepDetails>
              ))}
            </Slider>
            <MultistepActions align={currentStepData.actionAlignment || "left"}>
              {currentStepData.actions ? currentStepData.actions : null}
              {activeStep < steps.length - 1 && (
                <MfButton onClick={goNext}>{currentStepData.nextButtonLabel}</MfButton>
              )}
              {activeStep >= steps.length - 1 && (
                <>
                  <MfButton onClick={endFlowAction ? endFlowAction : onClose}>
                    {endFlowButtonLabel}
                  </MfButton>
                  {restartFlowButtonLabel && (
                    <MfButton onClick={restart} secondary={true}>
                      {restartFlowButtonLabel}
                    </MfButton>
                  )}
                </>
              )}
            </MultistepActions>
          </MultistepPage>
        </Overlay>
      </Locky>
    </FocusLock>
  ) : null
}

MfMultistep.propTypes = {
  onClose: PropTypes.func.isRequired,
  endFlowButtonLabel: PropTypes.string.isRequired,
  endFlowAction: PropTypes.func,
  restartFlowButtonLabel: PropTypes.string,
  isMandatory: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      actions: PropTypes.element,
      actionAlignment: PropTypes.oneOf(["left", "center", "right"]),
      nextButtonLabel: PropTypes.string.isRequired,
      backButtonLabel: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export { MfMultistep, MultistepHeader }
