import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { bodyText1SemiBold, bodyText3 } from "../../style/css"

import { MfTypo } from "../../fundamentals/Typo"
import { MfStructuredInput } from "./StructuredInput"

import useInput, {
  createNumberFormatter,
  createNumberMask,
  createFormatter,
} from "../../helpers/useInput"

import useNumberInput from "../../helpers/useNumberInput"

import useNodeDimension from "../../helpers/useNodeDimension"

const InputStyles = styled.input`
  ${bodyText1SemiBold}

  border: 0;
  box-sizing: border-box;
  border-radius: inherit;
  outline: 0;
  width: 100%;
  transition: box-shadow 0.25s ease-out;
  height: 56px;
  appearance: none;
  background: ${({ theme, disabled }) =>
    disabled ? theme.color.background.disabled : theme.color.background.light};
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.text.disabled2 : theme.color.text.default};
  padding-left: ${({ $prefixWidth }) => `calc(16px + ${$prefixWidth}px)`};
  padding-right: ${({ $suffixWidth }) => `calc(16px + ${$suffixWidth}px)`};
  ${({ theme }) => css`
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(3)};
  `}
  text-align: ${({ $centerText }) => ($centerText ? "center" : "left")};

  /* stylelint-disable no-descending-specificity */
  /* stylelint-disable property-no-vendor-prefix */

  &:-ms-autofill,
  &:-ms-autofill:hover,
  &:-ms-autofill:focus,
  &:-ms-autofill:active {
    background-clip: text;
    -ms-background-clip: text;
  }

  &:-moz-autofill,
  &:-moz-autofill:hover,
  &:-moz-autofill:focus,
  &:-moz-autofill:active {
    background-clip: text;
    -moz-background-clip: text;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-clip: text;
    -webkit-background-clip: text;
  }
`

const InputBase = styled.div`
  position: relative;
  display: ${({ $inputType }) => ($inputType === "textArea" ? "inline-block" : "block")};
  min-width: 0;
  border-radius: 8px;
`

const InputContainerStyles = styled.div`
  position: relative;
  border-radius: inherit;
`

const LabelStyles = styled.label`
  ${bodyText3}

  cursor: text;
  color: ${({ theme, $isFocused, $hasError, disabled }) =>
    disabled
      ? theme.color.text.lighter
      : $hasError
      ? theme.color.status.error
      : $isFocused
      ? theme.color.secondary.default
      : theme.color.text.lighter};
  display: inline-block;
  font-size: 1rem;
  left: 0;
  line-height: 1.2;
  max-width: calc(133% - 32px);
  overflow: hidden;
  padding: 0 5px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  transition: transform 200ms ease-out, max-width 200ms ease-out;
  transform: translate(11px, -7px) scale(0.75);
  transform-origin: left top 0;
  width: auto;
  white-space: nowrap;
  z-index: 1;

  ${({
    $isEmpty,
    $isFocused,
    $suffixWidth,
    $prefixWidth,
    $shrinkLabel,
    $centerText,
    $inputWidth,
  }) =>
    $isEmpty &&
    !$isFocused &&
    !$shrinkLabel &&
    css`
      font-size: 1rem;
      line-height: 1.5;
      max-width: calc(
        100% -
          (
            16px + ${$suffixWidth && $prefixWidth ? "16px" : "0px"} + ${$suffixWidth}px +
              ${$prefixWidth}px
          )
      );
      ${$centerText
        ? css`
            transform: translate(calc((${$inputWidth}px / 2) - 50%), 16px) scale(1);
          `
        : css`
            transform: translate(calc(16px + ${$prefixWidth}px), 16px) scale(1);
          `}
    `}
`

const FieldsetStyles = styled.fieldset`
  text-align: left;
  position: absolute;
  inset: ${({ $hasLabel }) => ($hasLabel ? "-5px 0 0" : "0")};
  margin: 0;
  padding: 0 5px;
  pointer-events: none;
  border-radius: inherit;
  border: 1px solid ${({ theme }) => theme.color.border.lighter};
  overflow: hidden;
  min-width: 0%;

  ${({ theme, $hasError, $isFocused, disabled }) => css`
    ${$isFocused &&
    css`
      border-color: ${theme.color.accent.default};
      border-width: 2px;
    `}

    ${$hasError &&
    css`
      border-color: ${theme.color.status.error};
      border-width: 2px;
    `}

    ${disabled &&
    css`
      border-color: ${theme.color.border.default};
      border-width: 1px;
    `}
  `}
`

const LegendStyles = styled.legend`
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  white-space: nowrap;

  ${({ $isEmpty, $isFocused, $shrinkLabel }) =>
    (!$isEmpty || $isFocused || $shrinkLabel) &&
    css`
      max-width: 100%;
    `}

  > span {
    padding-left: 5px;
    padding-right: 8px;
    display: inline-block;
    opacity: 0;
    visibility: visible;
  }
`

const InfoContainerStyles = styled.div`
  display: flex;
  right: 0;
  width: 100%;
`
const AdditionalInfoStyles = styled(MfTypo)`
  color: ${({ theme }) => theme.color.text.lighter};
  padding: 6px 0 6px 16px;
  margin: 0 0 0 auto;
`

const ErrorMessage = styled(MfTypo)`
  color: ${({ theme }) => theme.color.status.error};
  padding: ${({ $hasError }) => ($hasError ? "6px 16px" : "0px")};
  visibility: ${({ $hasError }) => ($hasError ? "visible" : "hidden")};
  margin: 0;
`

const Message = styled(MfTypo)`
  color: ${({ theme }) => theme.color.text.lighter};
  padding: 6px 16px;
  margin: 0;
`

const adornmentStyles = css`
  align-items: center;
  bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 4px;
  z-index: 1;
`

const Prefix = styled.div`
  ${adornmentStyles}
  left: 16px;
`

const Suffix = styled.div`
  ${adornmentStyles}
  right: 16px;
`

const InputField = ({
  label,
  name,
  value,
  error,
  additionalInfo,
  infoMessage,
  icon,
  prefix,
  suffix = icon,
  innerRef,
  disabled,
  centerText,
  shrinkLabel,
  placeholder,
  inputType,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  // Measure the input and adornments to calculate the label transform
  const [measureInputRef, inputBoundingRect] = useNodeDimension(innerRef)
  const [measurePrefixRef, prefixRect] = useNodeDimension()
  const [measureSuffixRef, suffixRect] = useNodeDimension()

  function handleFocus(event) {
    if (typeof props.onFocus === "function") {
      props.onFocus(event)
    }

    setIsFocused(true)
  }

  function handleBlur(event) {
    if (typeof props.onBlur === "function") {
      props.onBlur(event)
    }

    setIsFocused(false)
  }

  return (
    <div>
      <InputBase $inputType={inputType}>
        <InputContainerStyles>
          <InputStyles
            ref={measureInputRef}
            value={value}
            name={name}
            id={name}
            disabled={disabled}
            $prefixWidth={prefixRect.width || 0}
            $suffixWidth={suffixRect.width || 0}
            $centerText={centerText}
            aria-invalid={!!error}
            aria-errormessage={`${name}-error`}
            placeholder={!isFocused && !value && label ? "" : placeholder}
            {...props}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {label && (
            <LabelStyles
              htmlFor={name}
              $isFocused={isFocused}
              $hasError={!!error}
              $isEmpty={!value}
              disabled={disabled}
              $prefixWidth={prefixRect.width || 0}
              $suffixWidth={suffixRect.width || 0}
              $inputWidth={inputBoundingRect.width || 0}
              $shrinkLabel={shrinkLabel}
              $centerText={centerText}
            >
              {label}
            </LabelStyles>
          )}
          <FieldsetStyles
            disabled={disabled}
            $isFocused={isFocused}
            $isEmpty={!value}
            $hasError={!!error}
            $hasLabel={!!label}
          >
            {label && (
              <LegendStyles $isFocused={isFocused} $isEmpty={!value} $shrinkLabel={shrinkLabel}>
                <span>{label}</span>
              </LegendStyles>
            )}
          </FieldsetStyles>
          {prefix && (
            <Prefix ref={measurePrefixRef} disabled={disabled}>
              {prefix}
            </Prefix>
          )}
          {suffix && (
            <Suffix ref={measureSuffixRef} disabled={disabled}>
              {suffix}
            </Suffix>
          )}
        </InputContainerStyles>
        <InfoContainerStyles>
          <ErrorMessage
            $hasError={!!error}
            variant="bodytext3"
            aria-live="assertive"
            id={`${name}-error`}
          >
            {error && <span>{error}</span>}
          </ErrorMessage>
          {!error && infoMessage && <Message variant="bodytext3">{infoMessage}</Message>}
          {additionalInfo && (
            <AdditionalInfoStyles variant="bodytext3">{additionalInfo}</AdditionalInfoStyles>
          )}
        </InfoContainerStyles>
      </InputBase>
    </div>
  )
}

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  infoMessage: PropTypes.string,
  additionalInfo: PropTypes.string,
  icon: PropTypes.any /** @deprecated  */,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  disabled: PropTypes.bool,
  innerRef: PropTypes.any,
  shrinkLabel: PropTypes.bool,
  centerText: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
}

InputField.defaultProps = {
  error: undefined,
  additionalInfo: undefined,
  icon: undefined,
  disabled: undefined,
  innerRef: undefined,
  prefix: undefined,
  suffix: undefined,
  shrinkLabel: false,
  centerText: false,
  onFocus: undefined,
  onBlur: undefined,
  placeholder: undefined,
}

const MfInputField = React.forwardRef((props, ref) => <InputField innerRef={ref} {...props} />)

MfInputField.displayName = "MfInputField"

export {
  MfInputField,
  MfStructuredInput,
  LabelStyles,
  InputStyles,
  useInput,
  useNumberInput,
  createNumberMask,
  createNumberFormatter,
  createFormatter,
}
