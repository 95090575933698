import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"

import { MfTypo } from "../../../../fundamentals/Typo"

import { createMonthDaysArray, createLocaleWeekDaysArray } from "../../../../helpers/dateHelper"

const WeekWrapper = styled.div`
  display: flex;
`

const DayTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  cursor: default;

  ${({ theme }) => theme.media.phablet`
      width: 35px;
      height: 35px;
  `}
`

const DayWrapper = styled(DayTemplate)`
  position: relative;
  border-radius: 50%;

  & > p {
    z-index: 2;
  }

  ${({ $disabled, $isToday, $selected, $inRange, $isStartRange, theme }) => {
    let css = ""

    if ($isToday) {
      css = `color: ${theme.color.primary.default};`
    } else if ($disabled) {
      css = `color: ${theme.color.text.lighter};`
    }

    if ($selected && $disabled) {
      css += `
        background: ${theme.color.text.disabled};
        border: 2px solid white;
      `
    } else if ($selected) {
      css = `color: white;`
      css += `
        background: ${theme.color.primary.default};
        border: 2px solid white;
      `
    }

    if (!$inRange) return css

    if ($selected) {
      css += `
        border: 0;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: ${theme.color.badge.background.default};
          border-radius: 0;
          z-index: -1;
          border-radius: ${$isStartRange ? "50% 0 0 50%" : "0 50% 50% 0"};
        }
      `
      return css
    }

    css += `
      border-radius: 0;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: ${theme.color.badge.background.default};
        border-radius: 0;
        z-index: -1;
      }
    `

    return css
  }}

  &:hover {
    ${({ $disabled, $selected, $inRange, $empty, theme }) => {
      if ($disabled || $empty || $selected) return ""

      if ($inRange) {
        return `
          cursor: pointer;
          &:before {
            background: ${theme.color.text.disabled};
          }
        `
      }

      return `
        cursor: pointer;
        border: 1px solid ${theme.color.text.darker};
      `
    }}
  }
`

const today = moment()

// eslint-disable-next-line no-unused-vars
function Week({
  days,
  displayDate,
  selectedDays,
  limits,
  limitsSelected,
  onDayClick,
  disabledWeekDays,
  disableDates,
}) {
  const displayedDays = useMemo(() => {
    const selectedDaysOrdered =
      selectedDays.filter(Boolean).length <= 1 ? selectedDays : selectedDays.sort()

    const selectedStart = selectedDaysOrdered[0]
    const selectedEnd = selectedDaysOrdered[selectedDaysOrdered.length - 1]

    return days.map((data) => {
      const key = data.format("YYYY-MM-DD")
      if (data.month() !== displayDate.month()) return <DayWrapper $empty={true} key={key} />

      const isSelectedIndex = selectedDaysOrdered.findIndex((s) => data.isSame(s, "day"))
      const isSelected = isSelectedIndex > -1 && selectedDays[isSelectedIndex]

      const { min, max } = limits
      const outsideOfMinMax =
        (min && data.isBefore(min, "day")) || (max && data.isAfter(max, "day"))

      const limitsSelectedLookup = [limitsSelected.min, limitsSelected.max]
      const enableSelected =
        (isSelected && limitsSelectedLookup[isSelectedIndex]) ||
        (selectedDays.length === 1 && isSelected && (limitsSelected.max || limitsSelected.min))

      const disabled =
        disabledWeekDays.some((d) => data.day() === parseInt(d)) ||
        (outsideOfMinMax && !enableSelected) ||
        disableDates.some((d) => data.isSame(d), "day")

      const inRange =
        selectedDays.length > 1 &&
        ((data.isAfter(selectedStart, "day") && data.isBefore(selectedEnd, "day")) || !!isSelected)

      return (
        <DayWrapper
          key={key}
          $selected={isSelected}
          $isStartRange={isSelectedIndex === 0}
          $inRange={inRange}
          $disabled={disabled}
          $isToday={data.isSame(today, "day")}
          onClick={() => !disabled && !isSelected && onDayClick(data)}
        >
          <MfTypo variant="bodytext1" weight="semibold">
            {data.date()}
          </MfTypo>
        </DayWrapper>
      )
    })
  }, [days, displayDate, selectedDays, onDayClick])

  return <WeekWrapper>{displayedDays}</WeekWrapper>
}

Week.propTypes = {
  days: PropTypes.array.isRequired,
  displayDate: PropTypes.any.isRequired,
  onDayClick: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  selectedDays: PropTypes.array,
  limits: PropTypes.objectOf(PropTypes.any),
  limitsSelected: PropTypes.objectOf(PropTypes.bool),
  disabledWeekDays: PropTypes.arrayOf(PropTypes.string),
  disableDates: PropTypes.arrayOf(PropTypes.number),
}

Week.defaultProps = {
  selectedDays: [],
  limits: { min: undefined, max: undefined },
  limitsSelected: { min: undefined, max: undefined },
  disabledWeekDays: [],
  disableDates: [],
}

const WeekHeader = styled.div`
  display: flex;
`

const HeaderDayWrapper = styled(DayTemplate)`
  color: ${({ theme }) => theme.color.text.lighter};
`

export function Days({
  selected,
  displayDate,
  weekStart,
  limits,
  limitsSelected,
  locale,
  onSelect,
  disabledWeekDays,
  disableDates,
  togglePicker,
}) {
  const handleDayClick = useCallback(
    (data) => {
      onSelect(data.format("YYYY-MM-DD"))
      togglePicker()
    },
    [onSelect]
  )

  const generateMonthData = useMemo(
    () => createMonthDaysArray(displayDate, weekStart),
    [displayDate, weekStart]
  )

  const displayedMonth = useMemo(
    () =>
      generateMonthData.map((weekData) => (
        <Week
          key={weekData[0].date() + "-" + weekData[0].month()}
          selectedDays={selected}
          days={weekData}
          displayDate={displayDate}
          limits={limits}
          limitsSelected={limitsSelected}
          onDayClick={handleDayClick}
          disabledWeekDays={disabledWeekDays}
          disableDates={disableDates}
        />
      )),
    [handleDayClick, generateMonthData, selected]
  )

  const weekHeaderDays = useMemo(
    () =>
      createLocaleWeekDaysArray(locale, weekStart).map((day) => (
        <HeaderDayWrapper key={day}>
          <MfTypo variant="bodytext1" weight="semibold" style={{ fontSize: ".75rem" }}>
            {day.toUpperCase()}
          </MfTypo>
        </HeaderDayWrapper>
      )),
    [weekStart, locale]
  )

  return (
    <div>
      <WeekHeader>{weekHeaderDays}</WeekHeader>
      {displayedMonth}
    </div>
  )
}

Days.propTypes = {
  locale: PropTypes.string.isRequired,
  weekStart: PropTypes.number,
  displayDate: PropTypes.any,
  limits: PropTypes.objectOf(PropTypes.any),
  limitsSelected: PropTypes.objectOf(PropTypes.bool),
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  onNavigate: PropTypes.func,
  disabledWeekDays: PropTypes.arrayOf(PropTypes.number),
  disableDates: PropTypes.arrayOf(PropTypes.string),
  togglePicker: PropTypes.func,
}

Days.defaultProps = {
  weekStart: 1,
  displayDate: undefined,
  disabledWeekDays: undefined,
  disableDates: undefined,
  limits: { min: undefined, max: undefined },
  limitsSelected: { min: undefined, max: undefined },
  selected: [],
  onSelect: () => {},
  onNavigate: () => {},
}
