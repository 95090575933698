import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import moment from "moment"

import { MfTypo } from "../../../../fundamentals/Typo"

import { Days } from "./Days"
import { Months } from "./Months"
import { Years } from "./Years"

export function ViewRouterSelector({ displayDate, locale, view, monthPicker, monthLabel }) {
  return useMemo(() => {
    switch (view) {
      case 0: {
        const monthName = moment(displayDate).locale(locale).format("MMMM")

        return (
          <MfTypo
            variant="bodytext1"
            weight="semibold"
            style={{ textTransform: "capitalize", userSelect: "none" }}
            data-testid="picker_title_date"
          >
            {`${monthName} ${displayDate.year()}`}
          </MfTypo>
        )
      }
      case 1: {
        return (
          <MfTypo
            variant="bodytext1"
            weight="semibold"
            style={{ textTransform: "capitalize", userSelect: "none" }}
            data-testid="picker_title_month"
          >
            {monthPicker ? monthLabel : displayDate.year()}
          </MfTypo>
        )
      }
      case 2: {
        const startYear = Math.floor(displayDate.year() / 12) * 12
        return (
          <MfTypo
            variant="bodytext1"
            weight="semibold"
            style={{ textTransform: "capitalize", userSelect: "none" }}
            data-testid="picker_title_year"
          >
            {startYear} - {startYear + 11}
          </MfTypo>
        )
      }
    }
  }, [displayDate, locale, view])
}

ViewRouterSelector.propTypes = {
  view: PropTypes.number.isRequired,
  displayDate: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
  monthsCount: PropTypes.number,
}

ViewRouterContent.defaultProps = {
  monthsCount: 1,
}

const DisplayView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  ${({ $show }) =>
    !$show &&
    `
    display: none;
  `}
`
export function ViewRouterContent({
  selected,
  displayDate,
  weekStart,
  limits,
  limitsSelected,
  locale,
  onSelect,
  onNavigate,
  view,
  fixedView,
  disabledWeekDays,
  disableDates,
  monthPicker,
  togglePicker,
}) {
  return (
    <>
      {(!fixedView || view === 2) && (
        <DisplayView $show={view === 2}>
          <Years
            selected={selected}
            displayDate={displayDate}
            limits={limits}
            locale={locale}
            onNavigate={onNavigate}
            monthPicker={monthPicker}
            onSelect={onSelect}
          />
        </DisplayView>
      )}
      {(!fixedView || view === 1) && (
        <DisplayView $show={view === 1}>
          <Months
            selected={selected}
            displayDate={displayDate}
            limits={limits}
            locale={locale}
            onNavigate={onNavigate}
            monthPicker={monthPicker}
          />
        </DisplayView>
      )}
      {(!fixedView || view === 0) && (
        <DisplayView $show={view === 0}>
          <Days
            selected={selected}
            displayDate={displayDate}
            weekStart={weekStart}
            limits={limits}
            limitsSelected={limitsSelected}
            locale={locale}
            onSelect={onSelect}
            onNavigate={onNavigate}
            disabledWeekDays={disabledWeekDays}
            disableDates={disableDates}
            togglePicker={togglePicker}
          />
        </DisplayView>
      )}
    </>
  )
}

ViewRouterContent.propTypes = {
  view: PropTypes.number.isRequired,
  displayDate: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
  selected: PropTypes.array,
  weekStart: PropTypes.number,
  limits: PropTypes.objectOf(PropTypes.any),
  limitsSelected: PropTypes.objectOf(PropTypes.bool),
  fixedView: PropTypes.bool,
  onSelect: PropTypes.func,
  onNavigate: PropTypes.func,
  disabledWeekDays: PropTypes.arrayOf(PropTypes.number),
  disableDates: PropTypes.arrayOf(PropTypes.string),
  monthPicker: PropTypes.bool,
  togglePicker: PropTypes.func,
}

ViewRouterContent.defaultProps = {
  selected: [],
  weekStart: 1,
  disabledWeekDays: undefined,
  disableDates: undefined,
  limits: { min: undefined, max: undefined },
  limitsSelected: { min: undefined, max: undefined },
  fixedView: false,
  onSelect: () => {},
  onNavigate: () => {},
}
