import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import { UploadInput } from "./UploadInput"
import { FileList } from "./FileList"

const Upload = ({
  label,
  subLabel,
  onChange,
  files,
  maxFiles,
  maxFilesText,
  fileSize,
  fileSizeText,
  error,
  additionalInfo,
  disabled,
  deletePopup,
  ...props
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([])

  const handleChange = useCallback(
    (_, fs) => {
      const result = files.concat(fs)
      onChange(result, fs)
    },
    [uploadedFiles]
  )

  const handleDelete = useCallback(
    (f) => {
      const fileToDelete = files.find(({ name }) => f.name === name)
      const result = files.filter(({ name }) => f.name !== name)
      onChange(result, null, [fileToDelete])
    },
    [uploadedFiles]
  )

  useEffect(() => {
    setUploadedFiles(files)
  }, [files])

  return (
    <>
      {(maxFiles === undefined || maxFiles > uploadedFiles.length) && (
        <UploadInput
          onChange={handleChange}
          currentFileCount={uploadedFiles.length}
          maxFiles={maxFiles}
          maxFilesText={maxFilesText}
          multiple={(maxFiles && maxFiles > 1) || !maxFiles}
          label={label}
          subLabel={subLabel}
          disabled={disabled}
          fileSizeText={fileSizeText}
          fileSize={fileSize}
          error={error}
          additionalInfo={additionalInfo}
          {...props}
        />
      )}

      <FileList files={uploadedFiles} onDelete={handleDelete} deletePopup={deletePopup} />
    </>
  )
}

Upload.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  files: PropTypes.array,
  maxFiles: PropTypes.number,
  maxFilesText: PropTypes.string,
  fileSize: PropTypes.number,
  fileSizeText: PropTypes.string,
  error: PropTypes.string,
  additionalInfo: PropTypes.string,
  disabled: PropTypes.bool,
  deletePopup: PropTypes.func,
}

Upload.defaultProps = {
  label: `Drag and drop file here`,
  subLabel: "Or browse your files",
  files: [],
  maxFiles: undefined,
  maxFilesText: undefined,
  fileSize: undefined,
  fileSizeText: `max. file size:`,
  error: undefined,
  additionalInfo: undefined,
  disabled: false,
  deletePopup: undefined,
}

export const MfUpload = React.forwardRef((props, ref) => <Upload innerRef={ref} {...props} />)
MfUpload.displayName = "MfUpload"
