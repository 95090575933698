import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

import {
  bodyText1,
  bodyText1SemiBold,
  bodyText1Bold,
  bodyText2,
  bodyText2SemiBold,
  bodyText2Bold,
  bodyText3,
  bodyText3SemiBold,
  bodyText3Bold,
  title1,
  title1Bold,
  title2,
  title2Bold,
  title3,
  title3Bold,
  title4,
  title4Bold,
  title5,
  title5Bold,
  buttonLabel,
  pagetitleheader,
} from "../../style/css"

const overflowStyles = css`
  ${({ $overflow, $maxLines }) =>
    $overflow === "ellipsis" &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      ${$maxLines !== undefined &&
      ($maxLines === 1
        ? css`
            text-wrap: nowrap;
          `
        : css`
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${$maxLines};
          `)}
    `}
`

const variants = {
  bodytext1: {
    normal: styled.p`
      ${overflowStyles}
      ${bodyText1}
    `,
    semibold: styled.p`
      ${overflowStyles}
      ${bodyText1SemiBold}
    `,
    bold: styled.p`
      ${overflowStyles}
      ${bodyText1Bold}
    `,
  },
  bodytext2: {
    normal: styled.p`
      ${overflowStyles}
      ${bodyText2}
    `,
    semibold: styled.p`
      ${overflowStyles}
      ${bodyText2SemiBold}
    `,
    bold: styled.p`
      ${overflowStyles}
      ${bodyText2Bold}
    `,
  },
  bodytext3: {
    normal: styled.p`
      ${overflowStyles}
      ${bodyText3}
    `,
    semibold: styled.p`
      ${overflowStyles}
      ${bodyText3SemiBold}
    `,
    bold: styled.p`
      ${overflowStyles}
      ${bodyText3Bold}
    `,
  },
  title1: {
    normal: styled.h1`
      ${overflowStyles}
      ${title1}
    `,
    // There is no semibold for titles. it uses the normal instead
    semibold: styled.h1`
      ${overflowStyles}
      ${title1}
    `,
    bold: styled.h1`
      ${overflowStyles}
      ${title1Bold}
    `,
  },
  title2: {
    normal: styled.h1`
      ${overflowStyles}
      ${title2}
    `,
    // There is no semibold for titles. it uses the normal instead
    semibold: styled.h1`
      ${overflowStyles}
      ${title2}
    `,
    bold: styled.h1`
      ${overflowStyles}
      ${title2Bold}
    `,
  },
  title3: {
    normal: styled.h1`
      ${overflowStyles}
      ${title3}
    `,
    // There is no semibold for titles. it uses the normal instead
    semibold: styled.h1`
      ${overflowStyles}
      ${title3}
    `,
    bold: styled.h1`
      ${overflowStyles}
      ${title3Bold}
    `,
  },
  title4: {
    normal: styled.h1`
      ${overflowStyles}
      ${title4}
    `,
    // There is no semibold for titles. it uses the normal instead
    semibold: styled.h1`
      ${overflowStyles}
      ${title4}
    `,
    bold: styled.h1`
      ${overflowStyles}
      ${title4Bold}
    `,
  },
  title5: {
    normal: styled.h1`
      ${overflowStyles}
      ${title5}
    `,
    // There is no semibold for titles. it uses the normal instead
    semibold: styled.h1`
      ${overflowStyles}
      ${title5}
    `,
    bold: styled.h1`
      ${overflowStyles}
      ${title5Bold}
    `,
  },
  buttonlabel: {
    normal: styled.span`
      ${overflowStyles}
      ${buttonLabel}
    `,
    // There is no semibold or bold for buttonlabel. it uses the normal instead
    semibold: styled.span`
      ${overflowStyles}
      ${buttonLabel}
    `,
    bold: styled.span`
      ${overflowStyles}
      ${buttonLabel}
    `,
  },
  pageheadertitle: {
    normal: styled.h1`
      ${title1}
      ${pagetitleheader}
    `,
    // There is no semibold for titles. it uses the normal instead
    semibold: styled.h1`
      ${overflowStyles}
      ${title1}
      ${pagetitleheader}
    `,
    bold: styled.h1`
      ${overflowStyles}
      ${title1Bold}
      ${pagetitleheader}
    `,
  },
}

export function MfTypo({ variant, weight, children, overflow, maxLines, ...props }) {
  const Variant = variants[variant][weight]

  return (
    <Variant $overflow={overflow} $maxLines={maxLines} {...props}>
      {children}
    </Variant>
  )
}

MfTypo.propTypes = {
  variant: PropTypes.oneOf([
    "bodytext1",
    "bodytext2",
    "bodytext3",
    "title1",
    "title2",
    "title3",
    "title4",
    "title5",
    "buttonlabel",
    "pageheadertitle",
  ]).isRequired,
  weight: PropTypes.oneOf(["normal", "semibold", "bold"]),
  overflow: PropTypes.oneOf(["ellipsis"]),
  maxLines: PropTypes.number,
  children: PropTypes.any,
}

MfTypo.defaultProps = {
  weight: "normal",
  overflow: undefined,
  maxLines: undefined,
  children: null,
}
