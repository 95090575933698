import React, { forwardRef, useMemo } from "react"
import PropTypes from "prop-types"

import {
  MfWidget,
  WidgetAnchor,
  WidgetContent,
  Alignments,
  Placements,
} from "../../fundamentals/Widget"

import { SelectableItem } from "./item/SelectableItem"
import { LinkItem } from "./item/LinkItem"
import { ActionsItem } from "./item/ActionsItem"
import { Menu } from "./Menu"
import { MenuAction } from "./MenuAction"
import { OverflowStrategies } from "../../fundamentals/Widget/useFloatingWidget"

const MfContextualMenu = forwardRef(
  ({ children, placement, toggle, open, setOpen, targetNodeId }, ref) => {
    const alignment = useMemo(() => {
      if (placement === "left") return Alignments.Start
      if (placement === "right") return Alignments.End

      return Alignments.Center
    }, [placement])

    return (
      <MfWidget
        ref={ref}
        offset={4}
        open={open}
        onOpenChange={setOpen}
        placement={Placements.Bottom}
        alignment={alignment}
        overflowStrategy={OverflowStrategies.All}
        trapFocus
      >
        <WidgetAnchor>{toggle}</WidgetAnchor>
        <WidgetContent targetNodeId={targetNodeId}>{children}</WidgetContent>
      </MfWidget>
    )
  }
)

MfContextualMenu.displayName = "MfContextualMenu"

MfContextualMenu.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  placement: PropTypes.oneOf(["left", "center", "right"]),
  toggle: PropTypes.node.isRequired,
  targetNodeId: PropTypes.string,
}

MfContextualMenu.defaultProps = {
  placement: "left",
}

export { MfContextualMenu, Menu, SelectableItem, LinkItem, ActionsItem, MenuAction }
