import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const DetailsPane = styled.div`
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  flex-shrink: 0;
  scroll-snap-align: start;
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(3)}`};

  ${({ theme }) => theme.media.tablet`
    padding: ${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(7)};
  `}

  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    border: 6px solid rgb(0 0 0 / 0%);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #d8d8d8;

    &:hover {
      background-clip: padding-box;
      background-color: #b7b7b7;
    }
  }
`

export function MultistepDetails({ children, ...props }) {
  return <DetailsPane {...props}>{children}</DetailsPane>
}

MultistepDetails.propTypes = {
  children: PropTypes.node,
}
