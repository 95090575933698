import { css } from "styled-components"

const fonts = css`
  @font-face {
    font-family: light;
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/bnpp_sans_light-webfont.woff")}) format("woff");
  }

  @font-face {
    font-family: regular;
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/bnpp_sans-webfont.woff")}) format("woff");
  }

  @font-face {
    font-family: "cond_light";
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/bnpp_sans_cond_light-webfont.woff")}) format("woff");
  }

  @font-face {
    font-family: "cond_regular";
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/bnpp_sans_cond_regular-webfont.woff")}) format("woff");
  }

  @font-face {
    font-family: hello-bold;
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/HelloType-b.woff")}) format("woff");
  }

  @font-face {
    font-family: hello-light;
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/HelloType-l.woff")}) format("woff");
  }

  @font-face {
    font-family: hello-regular;
    font-style: normal;
    font-weight: normal;
    src: url(${require("./fonts/HelloType-r.woff")}) format("woff");
  }
`

export default fonts
