import { css } from "styled-components"

export const bodyText1 = css`
  line-height: 1.31;
`

export const bodyText1SemiBold = css`
  ${bodyText1}

  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`

export const bodyText1Bold = css`
  ${bodyText1}

  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
