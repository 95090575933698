import { css } from "styled-components"

export const buttonLabel = css`
  font-size: ${({ theme }) => theme.font.size.body1};
  line-height: 1;

  font-family: ${({ theme }) => theme.font.family.mobile.bold};
  font-weight: ${({ theme }) => theme.font.weight.bolder};

  ${({ theme }) => theme.media.tablet`
    font-family: ${({ theme }) => theme.font.family.desktop.bold};
    font-weight: normal;
  `}
`
