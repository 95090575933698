import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { bodyText1, bodyText2 } from "../../../style/css"

import { BaseOption } from "../BaseOption"
import { MfFontIcon } from "../../FontIcon"

export const NameStyles = styled.div`
  text-align: left;
  font-weight: 500;
  text-transform: uppercase;

  ${bodyText1}

  ${({ theme }) => theme.media.tablet`
    font-family: regular;
    font-weight: 300;
  `}
`

const SubLabel = styled.div`
  ${bodyText2}

  font-weight: 300;
`

const AmountLabel = styled.div`
  ${bodyText1}

  line-height: 1.2;

  ${({ theme }) => theme.media.tablet`
    font-family: regular;
  `}
`

const SubAmountLabel = styled(AmountLabel)`
  color: ${({ theme }) => theme.color.text.lighter};

  ${({ theme }) => theme.media.tablet`
    font-family: light;
  `}
`

const Split = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  margin-right: 4px;
  position: relative;
  top: 2px;
  display: inline-block;
`

function WrappedAccountOption({
  name,
  type,
  typeIcon,
  iban,
  amount,
  currency = "EUR",
  extraAmount,
  extraCurrency = "EUR",
  innerRef,
  ...props
}) {
  return (
    <BaseOption ref={innerRef} {...props} label={<NameStyles>{name}</NameStyles>}>
      <Split>
        <SubLabel>
          {typeIcon && (
            <IconWrapper>
              {typeof typeIcon === "string" ? <MfFontIcon icon={typeIcon} size="16px" /> : typeIcon}
            </IconWrapper>
          )}
          {type}
        </SubLabel>
        {extraAmount && (
          <SubAmountLabel>
            {extraAmount} {extraCurrency}
          </SubAmountLabel>
        )}
      </Split>
      <Split>
        <SubLabel>{iban}</SubLabel>
        <AmountLabel>
          {amount} {currency}
        </AmountLabel>
      </Split>
    </BaseOption>
  )
}

// We need to wrap the Option in a forwardRef call to make sure the BaseOption can put it on the Container
export const AccountOption = React.forwardRef((props, ref) => (
  <WrappedAccountOption innerRef={ref} {...props} />
))
AccountOption.displayName = "AccountOption"

WrappedAccountOption.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  iban: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string,
  extraAmount: PropTypes.string,
  extraCurrency: PropTypes.string,
  typeIcon: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  innerRef: PropTypes.any,
}

WrappedAccountOption.defaultProps = {
  extraAmount: undefined,
  typeIcon: undefined,
  innerRef: undefined,
}
