import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTheme } from "styled-components"

import { useMatchMedia } from "../../helpers/useMatchMedia"
import { WidgetContext } from "./context"
import { useFloatingWidget, OverflowStrategies, Placements, Alignments } from "./useFloatingWidget"

export function MfWidget({ children, bottomSheet, ...widgetOptions }) {
  const arrowRef = useRef()
  const theme = useTheme()

  const isLargeScreen = useMatchMedia(theme.screen.size.tablet)

  const showBottomSheet = bottomSheet && !isLargeScreen

  const widget = useFloatingWidget({
    ...widgetOptions,
    arrowRef,
    showBottomSheet,
  })

  return (
    <WidgetContext.Provider value={{ ...widget, arrowRef, showBottomSheet }}>
      {children}
    </WidgetContext.Provider>
  )
}

MfWidget.propTypes = {
  children: PropTypes.node.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  bottomSheet: PropTypes.bool,
  overflowStrategy: PropTypes.oneOf([
    OverflowStrategies.All,
    OverflowStrategies.Flip,
    OverflowStrategies.Move,
    OverflowStrategies.None,
  ]),
  placement: PropTypes.oneOf([
    Placements.Top,
    Placements.Right,
    Placements.Bottom,
    Placements.Left,
  ]),
  alignment: PropTypes.oneOf([Alignments.Center, Alignments.Start, Alignments.End]),
  offset: PropTypes.number,
  weakClose: PropTypes.bool,
  role: PropTypes.string,
  interact: PropTypes.exact({
    canFocus: PropTypes.bool,
    canHover: PropTypes.bool,
    canClick: PropTypes.bool,
  }),
}

MfWidget.defaultProps = {
  bottomSheet: true,
  overflowStrategy: OverflowStrategies.All,
  placement: undefined,
  alignment: Alignments.Center,
  offset: 0,
  weakClose: true,
  role: "",
  interact: {
    canFocus: false,
    canHover: false,
    canClick: true,
  },
}
