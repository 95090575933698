import { css } from "styled-components"

export const pagetitleheader = css`
  font-size: 24px;
  line-height: 30px;
  margin: 0;

  ${({ theme }) => theme.media.tablet`
    font-size: 32px;
    line-height: 40px;
  `}

  ${({ theme }) => theme.media.desktop`
    font-size: 40px;
    line-height: 48px;
  `}
`
