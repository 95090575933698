import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { MfButton } from "../../atoms/Button"
import { MfTypo } from "../../fundamentals/Typo"
import { MfFontIcon } from "../FontIcon"
import { MfContextualMenu, Menu } from "../ContextualMenu"
import useDefaultStyle from "../../helpers/useDefaultStyle"

const CellContainerStyles = styled.div`
  display: flex;
  flex-direction: column;

  &:hover,
  &:target {
    cursor: pointer;

    .mfaccountcell-name {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 4px solid rgba(0 150 94 / 20%);
  }
`

const CellRowStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const IconContainerStyles = styled.div`
  width: 16px;
  margin-right: 4px;
`

const RowContentWrapperStyles = styled.div`
  min-width: 0;
  display: flex;
  align-items: center;
`

const Ellipsis = styled(MfTypo)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const NameLink = styled.a`
  text-decoration: ${({ $highlight }) => ($highlight ? "underline" : "none")};

  &:hover,
  &:target {
    text-decoration: underline;
  }
`

const FullCaps = styled(Ellipsis)`
  text-transform: uppercase;
  align-self: center;
`

const LightEllipsis = styled(Ellipsis)`
  color: ${({ theme }) => theme.color.text.lighter};
`

const LightTypo = styled(MfTypo)`
  color: ${({ theme }) => theme.color.text.lighter};
  white-space: nowrap;
`

const NormalTypo = styled(MfTypo)`
  white-space: nowrap;
`

const OPACITY_12 = "1F"

export const IconButton = styled(MfButton)`
  ${({ theme }) => css`
    background: none;
    border-radius: ${({ theme }) => theme.spacing(2)};
    color: ${({ theme }) => theme.color.primary.default};
    height: 32px;
    padding: ${theme.spacing(1)};
    text-align: center;
    width: 32px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.color.secondary.default + OPACITY_12};
    }
  `}
`

const NameStyles = (props) => <FullCaps variant="bodytext1" weight="semibold" {...props} />
const AccountDetailStyles = (props) => <LightEllipsis variant="bodytext2" {...props} />
const AmountStyles = (props) => <LightTypo variant="bodytext2" {...props} />
const AmountBoldStyles = (props) => <NormalTypo variant="bodytext2" weight="semibold" {...props} />

const ContextualMenuSetup = ({ open, children, setOpen }) => {
  return (
    <MfContextualMenu
      placement="right"
      toggle={
        <IconButton text>
          <MfFontIcon icon="fonticons-dots-horizontal" color="primary:default" size="24px" />
        </IconButton>
      }
      open={open}
      setOpen={setOpen}
    >
      <Menu>{children}</Menu>
    </MfContextualMenu>
  )
}

ContextualMenuSetup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.any,
}

export default function MfAccountCell({
  name,
  type,
  number,
  amount,
  subAmount,
  icon,
  menu,
  action,
  onClick,
  ...props
}) {
  const nameContent = useDefaultStyle(name, NameStyles)
  const typeContent = useDefaultStyle(type, AccountDetailStyles)
  const numberContent = useDefaultStyle(number, AccountDetailStyles)
  const amountContent = useDefaultStyle(amount, AmountBoldStyles)
  const subAmountContent = subAmount && useDefaultStyle(subAmount, AmountStyles)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleCellClick = useCallback(() => {
    onClick?.()
  }, [onClick])

  return (
    <CellContainerStyles tabIndex={0} {...props} onClick={handleCellClick}>
      <CellRowStyles>
        <NameLink className="mfaccountcell-name" $highlight={menuIsOpen}>
          {nameContent}
        </NameLink>
        {action}
        {Array.isArray(menu) ? (
          <ContextualMenuSetup open={menuIsOpen} setOpen={setMenuIsOpen}>
            {menu}
          </ContextualMenuSetup>
        ) : (
          menu
        )}
      </CellRowStyles>
      <CellRowStyles>
        <RowContentWrapperStyles>
          {icon && <IconContainerStyles>{icon}</IconContainerStyles>}
          {typeContent}
        </RowContentWrapperStyles>
        {subAmountContent}
      </CellRowStyles>
      <CellRowStyles>
        {numberContent}
        {amountContent}
      </CellRowStyles>
    </CellContainerStyles>
  )
}

MfAccountCell.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.node,
  menu: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]), // DEPRECATED
  action: PropTypes.node,
  onClick: PropTypes.func,
}

MfAccountCell.defaultProps = {
  menu: null,
  action: null,
}
