import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { useWidget } from "../../../fundamentals/Widget"
import { MfButton } from "../../Button"
import { MenuItem } from "../MenuItem"

export const StyledButton = styled(MfButton)`
  background: none;
  border-radius: 0;
  color: ${({ theme }) => theme.color.text.darker};
  justify-content: flex-start;
  line-height: unset;
  padding: 0;
  text-decoration: none;
  text-align: left;
  width: 100%;
  flex: 1;

  &:hover,
  &:active {
    outline: 0;
    background: none;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    background: none;
  }

  ${({ theme }) => theme.media.tablet`
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
  `}
`

export const SelectableItem = ({ selected, onSelect, ...props }) => {
  const { setIsOpen } = useWidget()

  function handleSelect(event) {
    onSelect(event, setIsOpen)
  }

  return (
    <MenuItem selected={selected}>
      <StyledButton {...props} onClick={handleSelect} />
    </MenuItem>
  )
}

SelectableItem.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
}

SelectableItem.defaultProps = {
  selected: false,
}
