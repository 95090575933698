import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { MfFontIcon } from "../FontIcon"
import { MfTypo } from "../../fundamentals/Typo"

import MyImage from "./noimage.svg"

const EmptyCellStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid #dfe1ea;
  cursor: pointer;

  @media screen and (width <= 319px) {
    flex-direction: column;

    .desc {
      flex-wrap: wrap;
    }

    text-align: center;
  }

  .desc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (width >= 320px) {
      flex-direction: row;

      > img {
        margin-right: ${({ theme }) => theme.spacing(3)};
      }
    }
  }

  .text-zone {
    display: flex;
    flex-direction: column;
  }

  .action-icon {
    padding-left: ${({ theme }) => theme.spacing(2)};

    @media screen and (width <= 319px) {
      flex-direction: column;
      padding-left: 0;
      padding-top: ${({ theme }) => theme.spacing(2)};

      .desc {
        flex-wrap: wrap;
      }
    }

    > i {
      color: ${({ theme }) => theme.color.primary.default};
    }
  }
`

const ActionText = styled(MfTypo)`
  font-size: 14px;
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.color.primary.default};
`

export default function EmptyCell({ description, action, onClick, image, ...props }) {
  return (
    <EmptyCellStyles {...props} onClick={onClick}>
      <div className="desc">
        <img src={image} alt="boosted-icons-home" width="64px" height="64px" />
        <div className="text-zone">
          <MfTypo variant="bodytext2">{description}</MfTypo>
          <ActionText variant="bodytext1" weight="semibold">
            {action}
          </ActionText>
        </div>
      </div>
      <div className="action-icon">
        <MfFontIcon icon="fonticons-plus-1" />
      </div>
    </EmptyCellStyles>
  )
}

EmptyCell.propTypes = {
  description: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string,
}

EmptyCell.defaultProps = {
  image: MyImage,
}
