import { createContext, useContext } from "react"

export const WidgetContext = createContext(null)

export const useWidget = () => {
  const context = useContext(WidgetContext)

  if (context == null) {
    throw new Error("Widget components must be wrapped in <WidgetRoot />")
  }

  return context
}
